<template>
  <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2">
    <div class="px-4 py-6 sm:p-8">
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
        <!-- First Name Field -->
        <InputField
            class="sm:col-span-3"
            label="First Name"
            placeholder="First Name"
            :error-message="showErrors ? userFormFields.errors.value['firstName'] : undefined"
            v-model="userForm.firstName"
            required
        />
        <!-- Last Name Field -->
        <InputField
            class="sm:col-span-3"
            label="Last Name"
            placeholder="Last Name"
            :error-message="showErrors ? userFormFields.errors.value['lastName'] : undefined"
            v-model="userForm.lastName"
            required
        />
        <!-- Email Field -->
        <InputField
            class="sm:col-span-full"
            label="Email Address"
            placeholder="Email Address"
            :error-message="showErrors ? userFormFields.errors.value['email'] : undefined"
            v-model="userForm.email"
            required
        />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import {useForm} from 'vee-validate';
import {PropType, reactive, watch} from 'vue';
import * as yup from 'yup';
import {emailRegex} from "@/helpers/utilities/regex";

import InputField from "@/components/Atomic/AQuarks/InputField.vue";
import {UserDto} from "@/areas/users/dtos/userDto";

const props = defineProps({
  user: {
    type: Object as PropType<UserDto>,
    required: true,
  },
  showErrors: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['update', 'has-error'])

const userForm = reactive({
  firstName: '',
  lastName: '',
  email: '',
})

watch(() => props.user, (newValue) => {
  userForm.firstName = newValue?.firstName ?? ''
  userForm.lastName = newValue?.lastName ?? ''
  userForm.email = newValue?.email ?? ''
}, { immediate: true })

// Form validation
const validationSchema = yup.object({
  firstName: yup.string().required('First name is required').min(2, 'First name must be at least 2 characters'),
  lastName: yup.string().required('Last name is required').min(2, 'Last name must be at least 2 characters'),
  email: yup.string().required('Email is required').email('Must be a valid email').matches(emailRegex, {message: 'Invalid email address'}),
})

const userFormFields = useForm({
  validationSchema: validationSchema,
  initialValues: userForm
})

watch(() => userForm, async (newVal) => {
  // Reset the form with new values
  userFormFields.resetForm({
    values: newVal,
    errors: {}, // Optionally reset errors
    touched: {} // Optionally reset touched fields
  });

  emit('update', userForm)
  const validationResult = await userFormFields.validate();
  emit('has-error', !validationResult.valid)
  
}, { deep: true });
</script>