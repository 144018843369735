<template>
  <!-- Social Media Links -->
  <div v-if="isVisible" class="fixed inset-0 z-20 flex items-center justify-center p-4">
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <div class="relative mx-auto z-10 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-2/5 sm:p-6">
      <button @click="toggleVisibility" class="absolute top-0 right-0 m-4">
        <img src="/img/Icons/Close_Campaign.svg" alt="Close">
      </button>
      <h1 class="text-dark-blue text-2xl font-roboto text-center mb-3">
        Share Campaign
      </h1>
      <div class="mx-auto flex items-center justify-center space-x-6 rounded-full">
        <a :href="createWhatsAppLink()" target="_blank">
          <img src="/img/Icons/Whatsapp_Campaign.svg" alt="WhatsApp">
        </a>
        <a :href="emailShare()" target="_blank">
          <img src="/img/Icons/Email_Campaign.svg" alt="Email">
        </a>
        <a href="https://www.instagram.com/" target="_blank">
          <img src="/img/Icons/Instagram_Campaign.svg" alt="Instagram">
        </a>
        <a :href="createFacebookLink()" target="_blank">
          <img src="/img/Icons/FaceBook_Campaign.svg" alt="Facebook">
        </a>
        <a :href="createXShareLink()" target="_blank">
          <img src="/img/Icons/X_Campaign.svg" alt="X">
        </a>
      </div>
      <div v-if="!showEmailInput" class="mt-3 text-center sm:mt-5 relative">
        <input type="text" v-model="sharePath" class="border border-gray-300 rounded-full py-2 pl-4 pr-10 outline-none shadow-sm w-full" />
        <div v-if="showTooltip" class="absolute -top-20 right-0 transform translate-y-full bg-dark-blue text-white py-1.5 px-3 rounded-full">
          Copied!
        </div>
        <Button 
          buttonText="Copy" 
          @click="copyToClipboard"
          class="absolute inset-y-0 right-0 flex items-center px-5 rounded-r-full bg-dark-blue text-white"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '../AQuarks/Button.vue';
import { watch, ref } from 'vue';

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['click:isVisible'])

const sharePath = ref<string>('');
const showTooltip = ref<boolean>(false);
const showEmailInput = ref<boolean>(false);
const emailError = ref<boolean>(false);
const recipientEmail = ref<string>('');

watch(() => props.isVisible, (newVal) => {
  if (newVal) {
    const path = localStorage.getItem('sharePath');
    if (path) {
      sharePath.value = path;
    }
  }
});

const toggleVisibility = () => {
  emit('click:isVisible', !props.isVisible);
  showEmailInput.value = false;
  emailError.value = false;
}

const copyToClipboard = () => {
  navigator.clipboard.writeText(sharePath.value).then(() => {
    showTooltip.value = true;
    setTimeout(() => {
      showTooltip.value = false;
    }, 2000);
  })
}

// FaceBook
const createFacebookLink = () => {
  const urlToShare = encodeURIComponent(sharePath.value);
  const text = encodeURIComponent("Support our campaign and help us reach our goal!");
  
  return `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}&quote=${text}`;
}

// Twitter(X)
const createXShareLink = () => {
  const urlToShare = encodeURIComponent(sharePath.value);
  const text = encodeURIComponent("Support our campaign and help us reach our goal!");
  
  return `https://twitter.com/intent/tweet?url=${urlToShare}&text=${text}`;
}

// WhatsApp
const createWhatsAppLink = () => {
  const urlToShare = encodeURIComponent(sharePath.value);
  const message = `Hi,%0APlease support our campaign and help us reach our goal!:%0A${urlToShare}`;
  
  return `https://wa.me/?text=${message}`;
}

// Email
const emailShare = () => {
  const subject = encodeURIComponent('Support our campaign and help us reach our goal!');
  const body = encodeURIComponent(`Hi,\n\nPlease support our campaign by visiting this link: ${sharePath.value}\n\nThank you!`);

  return `mailto:${recipientEmail.value}?subject=${subject}&body=${body}`;
}
</script>