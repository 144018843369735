<template>
  <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-2 mx-auto pb-12">
    <div class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2 w-[620px] mx-auto">
      <div class="px-4 py-6 sm:p-8 mx-auto">
        <div v-if="userUpdate" class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          
          <!-- User Information -->
          <div class="sm:col-span-3">
            <label class="block text-sm font-medium leading-6 text-dark-blue">First name</label>
            <div class="mt-2">
              <input v-model="userUpdate.firstName" type="text" disabled placeholder="First Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
            </div>
          </div>
          <div class="sm:col-span-3">
            <label class="block text-sm font-medium leading-6 text-dark-blue">Last Name</label>
            <div class="mt-2">
              <input v-model="userUpdate.lastName" type="text" disabled placeholder="Last Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
            </div>
          </div>
          <div class="sm:col-span-full">
            <label class="block text-sm font-medium leading-6 text-dark-blue">Email</label>
            <div class="mt-2">
              <input v-model="userUpdate.email" type="email" disabled placeholder="Email" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
            </div>
          </div>

          <!-- Role Dropdown -->
          <div class="sm:col-span-3">
            <label class="block text-sm font-medium leading-6 text-dark-blue">Role</label>
            <div class="mt-2 relative">
              <div @click.stop="toggleDropdown" class="flex items-center justify-between w-full rounded-md border-0 py-1.5 px-2 text-dark-blue cursor-pointer shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6">
                <span>{{ userUpdate.roles[0] }}</span>
                <svg class="h-4 w-4 transition-transform" :class="{ 'rotate-180': isRoleDropdownOpen }" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </div>
              <div v-show="isRoleDropdownOpen" class="absolute text-sm right-0 z-10 mt-1 w-full rounded-md bg-white border border-gray-300 shadow-md">
                <div v-for="role in roles">
                  <div @click="selectRole(role)" class="py-2 px-4 hover:bg-gray-100 cursor-pointer">{{role}}</div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- SchoolAdmin, School Search -->
          <div v-if="userUpdate.roles[0] === 'SchoolAdmin' && schools.length > 0" class="sm:text-sm sm:col-span-full">
            <label class="sm:col-span-3 block font-medium leading-6 text-dark-blue">Search School</label>
            <SchoolSearch
              :style="''"
              :school="user.school"
              :filtered-schools="filteredSchools"
              @school-change="(selectedSchool) => selectSchool(selectedSchool)"
              @input="filterSchools"/>
          </div>
          
          <!-- Action buttons -->
          <div class="col-span-full sm:w-96 sm:mx-auto mt-0 sm:mt-4 flex gap-x-10">
            <button @click="$emit('back')" class="bg-dark-blue text-white py-2.5 w-full rounded-md shadow-md">
              Back
            </button>
            <button @click="updateUserRole(userUpdate)" :disabled="!isUpdated" :class="!isUpdated ? 'bg-gray-500' : 'bg-green'" class="text-white py-2.5 w-full rounded-md shadow-md">
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Button from "../AQuarks/Button.vue"
import {defineComponent, onBeforeUnmount, onMounted, PropType, ref} from "vue";
import {UserDto} from "@/areas/users/dtos/userDto";
import {RoleEnum} from "@/areas/users/enums/roleEnum";
import {useUserStore} from "@/areas/users/stores/userStore";
import Toast from "@/helpers/Toast";
import SchoolSearch from "@/components/Atomic/BAtoms/SchoolSearch.vue";
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";

export default defineComponent({
  name: 'UserDetailsForm',
  components: {
    SchoolSearch,
    Button
  },
  props: {
    schools: {
      type: Object as PropType<SchoolDto[]>,
      required: true
    },
    user: {
      type: Object as PropType<UserDto>,
      required: true,
    },
    userSchool: {
      type: Object as PropType<SchoolDto>,
    },
    searchTerm:{
      type: String,
      default: '',
    },
    filteredSchools: {
      type: Array as PropType<SchoolDto[]>,
      default: [],
    }
  },
  emits: ['role-updated', 'back'],
  setup(props){
    const userStore = useUserStore()
    const user = ref(props.user)
    const schools = ref(props.schools)
    const filteredSchools = ref<SchoolDto[]>(props.filteredSchools || user.value.school)

    const isUpdated = ref<boolean>(false)

    const userSchool = ref<SchoolDto>()
    const userUpdate = ref<UserDto>()
    const searchTerm = ref<string>('')
    const resultSize = ref<number>(5)

    const init = async () => {
      // Create deepcopy of user
      userUpdate.value = Object.assign({}, props.user)
      userUpdate.value.roles = Object.assign([], props.user.roles)
      userUpdate.value.school = Object.assign({}, props.user.school)

      const userKeycloakAttribute = await userStore.getKeycloakAttribute(props.user.email)
      
      if (props.user.roles![0] === RoleEnum.SchoolAdmin){
        userSchool.value = schools.value.find(school => school.guid === userKeycloakAttribute.content!.value)
        
        if (!userSchool.value){
          Toast.error("School not found, please try again later")
        }
        
        if (!user.value.school){
          user.value.school = userSchool.value!
        }
      }
    }
    
    const filterSchools = async (newInput: string) => {
      searchTerm.value = newInput
      filteredSchools.value = schools.value!.filter(school => school.name?.toLowerCase().includes(searchTerm.value.toLowerCase())).splice(0, resultSize.value)
    }
    
    const selectRole = (role: RoleEnum) => {
      userUpdate.value!.roles![0] = role
      if (props.user.roles![0] === userUpdate.value!.roles![0]){
        // No operation
        isUpdated.value = false
        return
      }
      
      isUpdated.value = true
      return
    }
        
    const selectSchool = (school: SchoolDto) => {
      if (userUpdate.value!.roles![0] !== RoleEnum.SchoolAdmin){
        Toast.error("Invalid user role")
        return;
      }

      if (userSchool.value && userSchool.value.guid === school.guid){
        // No operation
        isUpdated.value = false
        return
      }
      
      userSchool.value = school
      isUpdated.value = true
    }
    
    onMounted(async () => {
      await init()
    })
    
    return {
      userSchool,
      userUpdate,
      isUpdated,
      userStore,
      filteredSchools,
      searchTerm,
      
      selectSchool,
      selectRole,
      filterSchools,
    }
  },
  async mounted() {
    window.addEventListener('click', this.closeDropdowns)
  },
  async beforeUnmount(){
    document.removeEventListener('click', this.closeDropdowns);
  },
  methods: {
    toggleDropdown(){
      this.isRoleDropdownOpen = !this.isRoleDropdownOpen
    },

    closeDropdowns(){
      this.isRoleDropdownOpen = false
    },
    async updateUserRole(userDto: UserDto){
      if (userDto.roles![0] === RoleEnum.SchoolAdmin){
        if (!this.userSchool?.guid){
          Toast.error("School not found. This role requires a school to be selected")
          return
        }
      }

      const result = await this.userStore.updateRole(userDto, this.userSchool?.guid)
      
      if (!result.isSuccessful){
        Toast.error("User update failed")
        return;
      }
      
      userDto.school = this.userSchool
      
      Toast.success("Updated successfully")
      this.$emit('role-updated', userDto)
    }
  },
  data(){ 
    return {
      isRoleDropdownOpen: false,
      roles: Object.keys(RoleEnum),
    }
  }
})
</script>