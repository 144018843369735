import axiosInstance from '@/axiosConfig';
import {Operations} from '@/helpers/responses/operations';
import {ProblemDetails} from '@/helpers/responses/models/problemDetails';
import {OperationResult} from '@/helpers/responses/models/operationResult';
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";
import {CreateSessionDto} from "@/areas/sessions/dtos/createSessionDto";
import {SessionDto} from "@/areas/sessions/dtos/sessionDto";

export class SessionProxy {

    public async createSession(createSessionDto: CreateSessionDto): Promise<OperationResult<SessionDto>> {
        const uri = `/sessions/`;
    
        try {
            const response = await axiosInstance.post(uri, createSessionDto);
            
            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status !== 500) {
                const response = error.response;
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                };
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
            return Operations.ToErrorResponse("RequestFailure");
        }
    }
}