<template>
  <div>
    <!-- Sidebar -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col border-r border-gray-900/10 font-roboto">
      <div class="flex grow flex-col gap-y-4 overflow-y-auto px-6">
        <div class="flex h-16 shrink-0 items-center">
          <a href="/landing">
            <img class="h-8 w-auto" src="/img/GetGo_Logo_Small.svg" alt="GetGo Logo">
          </a>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul>
            <li v-for="(item, index) in filteredNavigation" :key="index" class="mb-3 group relative">
              <a :href="item.href" class="flex items-center py-1.5 rounded hover:bg-hover-blue"
                 :class="{'bg-hover-blue': isActive(item.href)}"
              >
                <img :src="`/img/Icons/${item.icon}.svg`" class="h-5 mr-2" alt="Icon" />
                <span class="text-dark-blue font-bold">{{ item.name }}</span>
              </a>
              <!-- Submenu -->
              <ul v-if="item.submenu" class="ml-4">
                <li v-for="(subItem, subIndex) in item.submenu" :key="subIndex" class="mb-2">
                  <a :href="subItem.href" class="flex items-center py-1 rounded hover:bg-hover-blue"
                     :class="{'bg-hover-blue': isActive(subItem.href)}"
                     @click="handleSubItemClick(subItem)">
                    <span class="text-dark-blue font-medium ml-5">{{ subItem.name }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-72">
      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <div class="flex flex-1 gap-x-4 justify-end lg:gap-x-6">
          <div class="relative flex flex-1 justify-center">
            <h1 class="text-2xl font-bold text-dark-blue">{{ pageTitle }}</h1>
          </div>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"></div>
            <!-- Profile dropdown -->
            <div class="relative" @click="toggleDropdown">
              <button type="button" class="-m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="hidden lg:flex lg:items-center">
                  <div class="mt-1 ml-1">
                  <p class="text-dark-blue font-bold font-roboto text-sm">
                    {{ userInfo ? userInfo.given_name : ''}}
                    {{ userInfo ? userInfo.family_name: ''}}
                  </p>
                  <p v-if="userRoles.getgoAdmin" class="text-gray-500 font-roboto text-xs text-left mt-1">GetGo Admin</p>
                  <p v-else-if="userRoles.schoolAdmin" class="text-gray-500 font-roboto text-xs text-left mt-1">School Admin</p>
                </div>
                  <img class="ml-2 transition-transform" :class="{ 'rotate-180': dropdownOpen }" src="/img/Icons/Arrow_Down.svg" alt="Down Arrow">
                </span>
              </button>
              <div v-if="dropdownOpen" class="absolute right-0 z-10 mt-2.5 w-48 px-2 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <a href="/profile" class="flex items-center py-1.5 rounded hover:bg-hover-blue px-0.5 text-sm leading-6 text-dark-blue cursor-pointer">
                  <img class="mr-2" src="/img/Icons/My_Profile.svg" alt="My Profile">
                  My Profile
                </a>
                <a @click="logout" class="flex items-center py-1.5 rounded hover:bg-hover-blue px-0.5 text-sm leading-6 text-dark-blue cursor-pointer">
                  <img class="mr-2" src="/img/Icons/Log_Out.svg" alt="Log Out">
                  Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <TaxCertificateModal v-if="isTaxCertificateModalOpen"
          :is-tax-certificate-modal-open="isTaxCertificateModalOpen"
          @close="isTaxCertificateModalOpen = false"
          @continue="handleTaxCertificate($event)"
      />

      <!-- From and To Date -->
      <FromToDateModal v-if="isDateModalOpen"
        :isDateModalOpen="isDateModalOpen" 
        :reportRequestDto="reportRequestDto"
        @download="handleDownload"
        @close="isDateModalOpen = false"/>

      <FileInputModal v-if="isFileInputModalOpen"
          v-model="file"
          @close="isFileInputModalOpen = false"/>
    </div>

  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, onBeforeUnmount, ref} from 'vue'
import { useRouter } from 'vue-router';
import keycloak from '@/keycloak'
import FromToDateModal from '../Atomic/BAtoms/FromToDateModal.vue'
import {ReportRequestDto} from "@/areas/reports/dtos/reportRequestDto";
import {ReportType} from "@/areas/reports/enums/reportType";
import {useReportStore} from "@/areas/reports/stores/reportStore";
import Toast from "@/helpers/Toast";
import FileInputModal from "@/components/Atomic/BAtoms/FileInputModal.vue";
import TaxCertificateModal from "@/components/Atomic/BAtoms/TaxCertificateModal.vue";
import {useUserStore} from "@/areas/users/stores/userStore";
import {TaxYearLogDto} from "@/areas/taxCertificates/dtos/taxYearLogDto";
import {useTaxCertificateStore} from "@/areas/taxCertificates/stores/taxCertificateStore";
import {formatDate, formatTime} from "@/helpers/utilities/dataFormats";

export default defineComponent({
  components: {
    TaxCertificateModal,
    FromToDateModal,
    FileInputModal,
  },
  props: {
    showSearchField: Boolean,
    pageTitle: String,
  },

  setup() {
    const router = useRouter();
    const dropdownOpen = ref<boolean>(false);
    const showTooltip = ref<boolean>(false);
    const userInfo = ref<any>();
    
    // modalState
    const isDateModalOpen = ref<boolean>(false);
    const isFileInputModalOpen = ref<boolean>(false);
    const isTaxCertificateModalOpen = ref<boolean>(false);
    
    const reportStore = useReportStore()
    const taxCertificateStore = useTaxCertificateStore()
    const file = reportStore.$state.file;

    const isActive = (itemHref: string) => {
      return router.currentRoute.value.path === itemHref;
    };

    const reportRequestDto = ref<ReportRequestDto>({
      to: {},
      from: {},
      reportType: {} 
    } as ReportRequestDto)

    const userRoles = computed(() => {
      const tokenParsed = keycloak.tokenParsed;
      if (tokenParsed && tokenParsed.realm_access && tokenParsed.realm_access.roles) {
        return {
          getgoAdmin: tokenParsed.realm_access.roles.includes("GetGoAdmin"),
          schoolAdmin: tokenParsed.realm_access.roles.includes("SchoolAdmin"),
        };
      } else {
        return {
          getgoAdmin: false,
          schoolAdmin: false,
        };
      }
    });
    
    const handleTaxCertificate = async (taxYear: string) => {
      isTaxCertificateModalOpen.value = false
      
      const taxYearLog = { year: Number(taxYear) } as TaxYearLogDto
      // generate tax certificates
      await taxCertificateStore.createTaxLog(taxYearLog)
    }
    
    const handleDownload = async () => {
      const result = await reportStore.getReport(reportRequestDto.value);
      
      if (!result.isSuccessful){
        Toast.error("Download Failed, please try again later")
        isDateModalOpen.value = false
        return;
      }
      
      const url = window.URL.createObjectURL(result.content!);
      const link = document.createElement('a');
      
      link.href = url;
      link.download = `${reportRequestDto.value.reportType.toLowerCase()}${formatDate()}:${formatTime()}.csv`;
      
      document.body.appendChild(link);
      link.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      isDateModalOpen.value = false
    }

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value
    }
    const closeDropdownOnClickOutside = (event: MouseEvent) => {
      const dropdownElement = document.getElementById('user-menu-button');
      if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
        dropdownOpen.value = false;
      }
    };

    const openModal = (itemName: string) => {
      if (itemName === 'Tax Certificate'){
        isTaxCertificateModalOpen.value = true
      }
      if (itemName === 'Balance Extract'){
        isDateModalOpen.value = true
        reportRequestDto.value!.reportType = ReportType.Balances
      }
      if (itemName === 'Transaction Extract') {
        isDateModalOpen.value = true
        reportRequestDto.value!.reportType = ReportType.Transactions
      }
      if (itemName === 'Donor Extract') {
        isDateModalOpen.value = true
        reportRequestDto.value!.reportType = ReportType.PaymentFunnel
      }
      if (itemName === 'Balance Upload'){
        isFileInputModalOpen.value = true
      }
    };

    const isLoading = computed(() => {return reportStore.$state.isLoading});
    
    const closeModal = (modalState: boolean) => {
      modalState = false
    };

    const logout = () => {
      keycloak.logout();
    }

    const navigation = ref([
        {
          name: 'Finance',
          href: '#',
          current: false,
          icon: 'Finance',
          submenu: [
            { name: 'Tax Certificate', href: '#', current: false, roles: ['getgoAdmin']},
            { name: 'Balance Extract', href: '#', current: false, roles: ['getgoAdmin']},
            { name: 'Balance Upload', href: '#', current: false, roles: ['getgoAdmin']},
            { name: 'Transaction Extract', href: '#', current: false, roles: ['getgoAdmin']},
            { name: 'Donor Extract', href: '#', current: false, roles: ['getgoAdmin']}
          ]
        },
        {
          name: 'Admin Dashboard',
          href: '#',
          current: false,
          icon: 'School_Dashboard',
          submenu: [
            { name: 'Manage Campaigns', href: '/manage/campaigns', current: false, roles: ['schoolAdmin']},
            { name: 'Create Campaign', href: '/add-campaign', current: false, roles: ['schoolAdmin']},
            { name: 'Manage Users', href: '/manage/users', current: false, roles: ['getgoAdmin']},
            { name: 'Manage Schools', href: '/manage/schools', current: false, roles: ['getgoAdmin']}
          ]
        },
    ]);

    const filteredNavigation = computed(() => {
      return navigation.value.map(item => {
        const newItem = {...item};

        if (newItem.submenu) {
          newItem.submenu = newItem.submenu.filter(subItem => {
            if (subItem.roles) {
              return subItem.roles.some(role => (userRoles.value as any)[role]);
            }
            return true; // Keep the submenu item if no roles are specified
          });
        }

        return newItem;
      }).filter(item => item.submenu ? item.submenu.length > 0 : true);
    });

    onMounted(async () => {
      if (keycloak.authenticated) {
        userInfo.value = await keycloak.loadUserInfo();
      }
      document.addEventListener('click', closeDropdownOnClickOutside);
    })

    onBeforeUnmount(() => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
    });

    return { 
      userRoles,
      userInfo,
      dropdownOpen, 
      showTooltip,
      filteredNavigation,
      reportRequestDto,
      isLoading,
      file,

      // Modal flags
      isDateModalOpen,
      isFileInputModalOpen,
      isTaxCertificateModalOpen,
      
      isActive,
      handleTaxCertificate,
      handleDownload,
      toggleDropdown,
      logout,
      openModal,
      closeModal,
    }
  },

  methods: {
  handleSubItemClick(subItem: any) {
    if (subItem.name === 'Balance Extract' || 
        subItem.name === 'Transaction Extract' || 
        subItem.name === 'Balance Upload' || 
        subItem.name === 'Donor Extract' ||
        subItem.name === 'Tax Certificate') {
      this.openModal(subItem.name);
    }
  }
}

})
</script>