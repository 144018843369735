import {defineStore} from 'pinia';
import { CampaignProxy } from '../proxies/campaignProxy';
import { CreateCampaignDto } from '../dtos/createCampaignDto';
import { CampaignDto } from '../dtos/campaignDto';
import {OperationResult, Operations} from '@/helpers/responses/models/operationResult';
import { CampaignAssetDto } from '../dtos/campaignAssetDto';
import { CampaignState } from '../enums/campaignState';

export interface CampaignStoreState{
    updated_at: Date
    proxy: CampaignProxy
    campaigns: CampaignDto[] | []
    campaign: CampaignDto | null
    result: OperationResult<any> | null
    states: CampaignState[] | null
    isLoading: boolean
}

export const useCampaignStore = defineStore({
    id: "campaigns",
    state: (): CampaignStoreState => ({
        updated_at: new Date(),
        proxy: new CampaignProxy(),
        campaigns: [] as CampaignDto[] | [],
        campaign: null as CampaignDto | null,
        result: null as OperationResult<any> | null,
        states: null as CampaignState[] | null,
        isLoading: false
    }),
    actions: {
        async init(schoolGuid?: string, campaignState?: CampaignState){
            this.campaigns = []
            this.campaign = null
            this.result = null
            this.states = campaignState === undefined ? null : [campaignState]
            this.isLoading = false
            
            if (schoolGuid){
                await this.listCampaignsBySchool(schoolGuid, campaignState)
            }
        },
        async clearCampaign(){
            this.campaign = null
        },
        async selectCampaign(campaignGuid: string): Promise<OperationResult<CampaignDto>> {
            if (this.campaigns === null){
                this.result = Operations.ToErrorResponse("No campaigns found to search")
                
                return this.result
            }
            this.campaign = this.campaigns.find(campaign => campaign.guid === campaignGuid) ?? null
            
            if (this.campaign === null){
                this.result = Operations.ToErrorResponse("Campaign does not exist")
                
                return this.result
            }
            
            this.result = Operations.ToSuccessResponse(this.campaign)
            
            return this.result
        },
        async createCampaignRequest(schoolGuid: string, createCampaignDto: CreateCampaignDto): Promise<OperationResult<CampaignDto>> {
            return await this.proxy.createCampaignRequest(schoolGuid, createCampaignDto);
        },

        async fetchCampaign(schoolGuid: string, campaignGuid: string): Promise<OperationResult<CampaignDto>> {
            this.result = await this.loadingWrapper(this.proxy.fetchCampaign(schoolGuid, campaignGuid))
            
            if (this.result.isSuccessful){
                this.campaign = this.result.content! as CampaignDto
            }
            
            return this.result
        },
        
        async fetchCampaignsByName(campaignName: string): Promise<OperationResult<CampaignDto[]>> {
            this.result = await this.loadingWrapper(this.proxy.fetchCampaignsByName(campaignName));

            if (this.result.isSuccessful){
                this.campaigns = this.result.content! as CampaignDto[]
            }

            return this.result
        },

        async listCampaignsBySchool(schoolGuid: string, state?: CampaignState): Promise<OperationResult<CampaignDto[]>> {
            this.result = await this.loadingWrapper(this.proxy.listCampaignsBySchool(schoolGuid, state))

            if (this.result.isSuccessful){
                this.campaigns = this.result.content! as CampaignDto[]
            }
            
            return this.result
        },

        async uploadCoverPhoto(blob: Blob, schoolGuid: string, campaignGuid: string, campaignAssetDto: CampaignAssetDto): Promise<OperationResult<CampaignAssetDto>> {
            return await this.proxy.uploadCoverPhoto(blob, schoolGuid, campaignGuid, campaignAssetDto);
        },

        async listSingleCampaignFromSchool(schoolGuid: string, campaignGuid: string): Promise<OperationResult<CampaignState>> {
            return await this.proxy.listSingleCampaignFromSchool(schoolGuid, campaignGuid);
        },

        async updateCampaignState(schoolGuid: string, campaignGuid: string, campaignState: string) : Promise<OperationResult<CampaignDto>> {
            return await this.proxy.updateCampaignState(schoolGuid, campaignGuid, campaignState);
        },
        
        async loadingWrapper<T>(func: Promise<OperationResult<T>>): Promise<OperationResult<T>> {
            this.isLoading = true
            this.result = await func
            this.isLoading = false
            return this.result
        },
    }
})