<template>
  <div class="sticky top-0 bg-white shadow-md z-50" >
    <!-- Dark Overlay -->
    <div v-if="sidebarOpen" @click="toggleSidebar" class="fixed inset-0 bg-black opacity-30 z-30"></div>

    <!-- Navbar for Desktop -->
    <nav class="bg-white shadow-md font-roboto relative z-40">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 sticky">
        <a href="/landing" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="/img/logos/GetGo_Home_Logo.png" class="h-16 hidden lg:block" alt="GetGo Logo" />
          <img src="/img/logos/GetGo_Home_Logo.png" class="h-9 block lg:hidden" alt="GetGo Logo">
        </a>
        <button @click="toggleSidebar" data-collapse-toggle="navbar-solid-bg" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-grey-blue rounded-lg lg:hidden" aria-controls="navbar-solid-bg" aria-expanded="false">
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
        </button>
        <div class="hidden w-full lg:block md:w-auto" id="navbar-solid-bg">
          <ul class="flex flex-col items-baseline font-medium mt-4 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li v-if="user && !getgoAdmin && !schoolAdmin">
              <a href="/profile" class="block py-2 px-3 md:p-0 rounded text-grey-blue group relative">
                My Profile
              </a>
            </li>

            <li v-if="user && !getgoAdmin && !schoolAdmin">
              <a href="/my-donations" class="block py-2 px-3 md:p-0 rounded text-grey-blue group relative">
                My Donations
              </a>
            </li>

            <li v-if="getgoAdmin || schoolAdmin">
              <a :href="dashboardRedirectUrl" class="block py-2 px-3 md:p-0 rounded text-grey-blue group relative">
                Dashboard
              </a>
            </li>

            <li v-show="!$keycloak.authenticated || ($keycloak.authenticated && (!schoolAdmin && !getgoAdmin))">
              <div class="relative" @click="toggleDropdown">
                <a class="py-2 px-3 md:p-0 rounded flex items-baseline text-grey-blue cursor-pointer">
                  Learn More
                  <img class="ml-2 transition-transform" :class="{ 'rotate-180': dropdownOpen }" src="/img/Down_Arrow_Navbar.svg" alt="Down Arrow">
                </a>
                <ul v-if="dropdownOpen" class="absolute top-full left-0 bg-white shadow-md mt-2 py-2 w-40 z-10 rounded-lg">
                  <li v-for="(item, index) in dropdownItems" :key="index">
                    <a :href="item.href" class="block px-4 py-2 text-grey-blue hover:bg-[#DBEAFE]">{{ item.name }}</a>
                  </li>
                </ul>
              </div>
            </li>
            <li v-show="!$keycloak.authenticated || ($keycloak.authenticated && (!schoolAdmin && !getgoAdmin))">
              <a href="/register/school" class="block py-2 px-3 md:p-0 rounded text-grey-blue">
                Register My School
              </a>
            </li>
            <li v-show="!$keycloak.authenticated || ($keycloak.authenticated && (!getgoAdmin))">
              <a href="/add-campaign" class="font-roboto text-white text-sm uppercase bg-pink py-2 px-4 rounded-md block">
                Create Campaign
              </a>
            </li>
            <li v-show="!$keycloak.authenticated || ($keycloak.authenticated && (!schoolAdmin && !getgoAdmin))">
              <vue-tippy>
                
              </vue-tippy>
              <button @click="donate" class="font-roboto font-bold text-dark-blue text-sm uppercase bg-bright-green py-2 px-4 rounded-md group relative">
                Donate Now
              </button>
            </li>
            <li>
              <button @click="login" v-if="!$keycloak.authenticated" class="font-roboto font-normal text-white uppercase text-sm bg-dark-blue py-2 px-4 rounded-md group relative">
                Login
              </button>
              <button @click="logout" v-else class="font-roboto font-normal text-white uppercase text-sm bg-dark-blue py-2 px-4 rounded-md group relative">
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Sidebar (Tablet & Mobile) -->
    <div :class="{ 'translate-x-0': sidebarOpen, 'translate-x-full': !sidebarOpen}"
        class="fixed inset-y-0 right-0 z-40 mt-16 bg-white w-64 shadow-lg transform transition-transform duration-300 ease-in-out lg:hidden overflow-y-auto">
      <div class="p-4">
        <div class="mb-5 pl-3">
          <div v-if="$keycloak.authenticated">
            <p class="text-dark-blue text-base font-semibold">{{ $keycloak.tokenParsed?.given_name }} {{ $keycloak.tokenParsed?.family_name }}</p>
            <p v-if="getgoAdmin" class="text-gray-500 mt-1 text-sm">GetGo Admin</p>
            <p v-if="schoolAdmin" class="text-gray-500 mt-1 text-sm">School Admin</p>
            <p v-if="user && !getgoAdmin && !schoolAdmin" class="text-gray-500 mt-1 text-sm">User</p>
            <hr class="bg-gray-500 w-full mt-3">
          </div>
          <div v-else>
            <img src="/img/GetGo_Logo_Small.svg" alt="GetGo Logo">
          </div>
        </div>

        <ul>
          <li v-for="(item, index) in navigation" :key="index" class="mb-3">
            <hr v-if="item.name === 'My Profile'" class="bg-gray-500 w-full my-3">
            <a :href="item.href" 
              class="flex items-center py-2 px-3 rounded hover:bg-[#DBEAFE]"
              @click="item.name === 'Log In' ? login() : (item.name === 'Log Out' ? logout() : null)">
              <img :src="`/img/Icons/${item.icon}.svg`" class="h-5 mr-2" alt="Icon" />
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {ref, computed, onMounted, onBeforeUnmount} from 'vue';
import keycloak from '@/keycloak';
import router from "@/router";
import {Tippy} from "vue-tippy";

export default {
  setup() {
    const sidebarOpen = ref(false);
    const dropdownOpen = ref(false);
    const dropdownDashboardsOpen = ref(false);
    const showTooltip = ref(null);
    const dropdownClicked = ref(false);
    const dashboardsDropdownClicked = ref(false);

    let getgoAdmin = keycloak.tokenParsed?.realm_access?.roles.includes("GetGoAdmin");
    let schoolAdmin = keycloak.tokenParsed?.realm_access?.roles.includes("SchoolAdmin");
    let user = keycloak.tokenParsed?.realm_access?.roles.includes("User");

    const dashboardRedirectUrl = computed(() => {
      if (getgoAdmin) return "/manage/users";
      if (schoolAdmin) return "/manage/campaigns";
      return "/landing";
    });

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
      dropdownClicked.value = true;
    };

    const toggleDashboardsDropdown = () => {
      dropdownDashboardsOpen.value = !dropdownDashboardsOpen.value;
      dashboardsDropdownClicked.value = true;
    };

    const closeDropdownsOnClickOutside = (event: MouseEvent) => {
      if (!dropdownClicked.value) {
        dropdownOpen.value = false;
      }
      if (!dashboardsDropdownClicked.value) {
        dropdownDashboardsOpen.value = false;
      }
      dropdownClicked.value = false;
      dashboardsDropdownClicked.value = false;
    };

    onMounted(() => {
      window.addEventListener('click', closeDropdownsOnClickOutside);
    });
    
    onBeforeUnmount(() => {
      window.removeEventListener('click', closeDropdownsOnClickOutside);
    });

    const navigation = computed(() => {
      let baseNavigation = []
      if (!keycloak.authenticated) {
        baseNavigation.unshift({ name: 'Log In', href: '#', current: false, icon: 'Log_In' });
        baseNavigation.push(
          { name: 'Donate Now', href: '/donate', current: false, icon: 'Donate_Now'},
          { name: 'Create Campaign', href: '/add-campaign', current: false, icon: 'Add_Campaign' },
          { name: 'Register My School', href: '/register/school', current: false, icon: 'Register_My_School' },
          { name: 'About Us', href: '/about', current: false, icon: 'About_Us' },
          { name: 'How It Works', href: '/how-it-works', current: false, icon: 'How_It_Works' },
          { name: 'Contact Us', href: '/contact-us', current: false, icon: 'Contact_Us' },
          { name: 'FAQs', href: '/faq', current: false, icon: 'FAQ' },
          { name: 'Home', href: '/landing', current: false, icon: 'Home' },
        )
      } else {
        if (!schoolAdmin && !getgoAdmin){
          baseNavigation.push(
              { name: 'My Donations', href: '/my-donations', current: false, icon: 'My_Donations' },
              { name: 'Donate Now', href: '/donate', current: false, icon: 'Donate_Now'},
              { name: 'Create Campaign', href: '/add-campaign', current: false, icon: 'Add_Campaign' },
              { name: 'Register My School', href: '/register/school', current: false, icon: 'Register_My_School' },
              { name: 'About Us', href: '/about', current: false, icon: 'About_Us' },
              { name: 'How It Works', href: '/how-it-works', current: false, icon: 'How_It_Works' },
              { name: 'Contact Us', href: '/contact-us', current: false, icon: 'Contact_Us' },
              { name: 'FAQs', href: '/faq', current: false, icon: 'FAQ' },
              { name: 'Home', href: '/landing', current: false, icon: 'Home' },
          )
        } else {
          if (schoolAdmin){
            baseNavigation.push(
                { name: 'Create Campaign', href: '/add-campaign', current: false, icon: 'Add_Campaign' },
                { name: 'Home', href: '/landing', current: false, icon: 'Home' },
            )
          } else if (getgoAdmin){
            baseNavigation.push(
                { name: 'Home', href: '/landing', current: false, icon: 'Home' },
            )
          }
        }
        baseNavigation.push({ name: 'My Profile', href: '/profile', current: false, icon: 'My_Profile' });

        baseNavigation = [
          ...baseNavigation,
          { name: 'Log Out', href: '#', current: false, icon: 'Log_Out' }
        ];
      }

      return baseNavigation;
    });

    return {
      sidebarOpen,
      dropdownOpen,
      dropdownDashboardsOpen,
      showTooltip,
      getgoAdmin,
      schoolAdmin,
      user,
      navigation,
      dashboardRedirectUrl,
      
      toggleDropdown,
      toggleDashboardsDropdown,

      dropdownItems: [
        { name: 'About Us', href: '/about' },
        { name: 'How It Works', href: '/how-it-works' },
        { name: 'Contact Us', href: '/contact-us' },
        { name: "FAQs", href: '/faq' },
      ]
    };
  },

  methods: {
    toggleSidebar(this: { sidebarOpen: boolean }) {
      this.sidebarOpen = !this.sidebarOpen;

      if (this.sidebarOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    donate(this: { $keycloak: any }) {
      router.push({
        name: "DonateNow",
      })
    },
    login(this: { $keycloak: any }) {
      this.$keycloak.login({ redirectUri: window.location.origin + '/landing' });
    },
    logout(this: { $keycloak: any }) {
      this.$keycloak.logout({ redirectUri: window.location.origin + '/landing' });
    }
  },
};
</script>
