<template>
  <div class="">
    <label class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div class="mt-2 relative">
      <div class="relative dropdown" @click.stop="toggleDropdown" ref="dropdown">
        <div class="block w-full rounded-md border-0 py-1.5 pl-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w sm:text-sm sm:leading-6 outline-none cursor-pointer">
          {{ modelValue || placeholder }}
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <img class="transition-transform" :class="{ 'rotate-180': showDropdown }" src="/img/Icons/Arrow_Down.svg" alt="Arrow Down">
        </div>
        <div v-if="showDropdown" class="absolute mt-1 z-30 w-full rounded-md bg-white shadow-lg border border-gray-300 overflow-auto max-h-48">
          <div v-for="option in options" :key="option" @click.stop="selectOption(option)" class="py-2 px-4 cursor-pointer hover:bg-gray-100">
            {{ option }}
          </div>
        </div>
      </div>
      <span v-if="errorMessage" class="text-red-500 text-sm mt-1">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  options: {
    type: Array as () => string[],
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Select Option',
  },
  label: {
    type: String,
  },
  errorMessage: String
})

const showDropdown = ref<boolean>(false);
const dropdown = ref<HTMLElement | null>(null);
  
const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
}

const selectOption = (option: string) => {
  emit('update:modelValue', option)
  showDropdown.value = false;
}

const handleClickOutside = (event: MouseEvent) => {
  if (dropdown.value && !dropdown.value.contains(event.target as HTMLElement)) {
    showDropdown.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
})
</script>
