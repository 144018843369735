<template>
  <div class="max-w-7xl mx-auto px-4 space-y-10 divide-y divide-gray-900/10 pb-10 font-roboto">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
      <div class="px-4 sm:px-0 text-center">
        <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Complete Registration</h2>
      </div>
      <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2">
        <div class="px-4 py-6 sm:p-8">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">First Name*</label>
              <div class="mt-2">
                <input v-model="firstName" type="text" placeholder="First Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">Last Name*</label>
              <div class="mt-2">
                <input v-model="lastName" type="text" placeholder="Last Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="sm:col-span-full">
              <label class="block text-sm font-medium leading-6 text-dark-blue">ID Number*</label>
              <div class="mt-2">
                <input v-model="idNumber" type="text" placeholder="ID Number" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">Date of Birth*</label>
              <div class="mt-2">
                <input v-model="dateOfBirth" type="text" placeholder="Date of Birth" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">Contact Number*</label>
              <div class="mt-2">
                <input v-model="phoneNumber" type="text" placeholder="Contact Number" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
                <span v-if="invalidPhoneNumber" class="text-red-500 text-sm mt-1">Invalid Phone Number</span>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">Street Address*</label>
              <div class="mt-2">
                <input v-model="streetAddress" type="text" placeholder="Street Address" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">City*</label>
              <div class="mt-2">
                <input v-model="city" type="text" placeholder="City" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">Province*</label>
              <div class="mt-2">
                <input v-model="province" type="text" placeholder="Province" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label class="block text-sm font-medium leading-6 text-dark-blue">Postal Code*</label>
              <div class="mt-2">
                <input v-model="postalCode" type="text" placeholder="Postal Code" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" required/>
              </div>
            </div>

            <div class="col-span-full sm:w-96 sm:mx-auto mt-5 sm:mt-4">
              <button class="bg-light-blue text-white py-2.5 w-full rounded-md hover:opacity-90">
                Register
              </button>
              <div v-if="errorMessage" class="text-red-500 text-sm mt-1">{{ errorMessage }}</div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserRegistration',
};
</script>