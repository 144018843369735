import {defineStore} from 'pinia';
import {ReportProxy} from '@/areas/reports/proxies/reportProxy';
import { OperationResult } from '@/helpers/responses/models/operationResult';
import {ReportRequestDto} from "@/areas/reports/dtos/reportRequestDto";

interface ReportStoreState {
    updated_at: Date;
    proxy: ReportProxy;
    reportRequest: ReportRequestDto | null;
    isLoading: boolean;
    file: File | null;
}

export const useReportStore = defineStore({
    id: "report",
    state: (): ReportStoreState => ({
        updated_at: new Date(),
        proxy: new ReportProxy(),
        reportRequest: null as ReportRequestDto | null,
        isLoading: false,
        file: null as File | null,
    }),

    actions: {
        setFile(file: File){
            this.file = file
        },
        
        async getReport(reportRequestDto: ReportRequestDto): Promise<OperationResult<Blob>> {
            return await this.loadingWrapper(this.proxy.getReport(reportRequestDto))
        },
        
        async uploadBalanceReport(): Promise<OperationResult<undefined>> {
            // check input element
            if (!this.$state.file) {
                return {
                    isSuccessful: false,
                    error: 'file input not found',
                } as OperationResult<undefined>
            }
            
            // Make the upload request using the proxy
            return await this.loadingWrapper(this.$state.proxy.upload(this.$state.file))
        },
        
        async loadingWrapper<T>(func: Promise<OperationResult<T>>): Promise<OperationResult<T>> {
            this.$state.isLoading = true
            const result = await func
            this.$state.isLoading = false
            return result
        },
    }
})