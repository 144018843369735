<template>
  <AdminSidebar :showSearchField="showSearchField" :pageTitle="pageTitle"/>

  <div class="font-roboto lg:pl-72 pb-16">
    <!-- Users Table -->
    <div v-if="!showCampaignDetails" class="px-4 sm:px-6 lg:px-8 mt-6 mx-8 border border-gray-900/5 rounded-md shadow-sm"> 
      <div class="flex justify-between gap-x-4 mt-8">
        <div class="flex justify-start">
          <form class="relative" action="#" method="GET">
            <label for="search-field" class="sr-only">Search</label>
            <img class="w-5 py-3 h-full absolute left-1" src="/img/Icons/Magnifying_glass.svg" alt="Search Icon">
            <input v-model="searchTerm" id="search-field" class="h-full w-80 border border-dark-blue rounded-md py-3 pl-8 pr-0 text-dark-blue placeholder:text-gray-400 sm:text-sm outline-none" placeholder="Search Campaign Name...">
          </form>
        </div>
        <CampaignStateFilterButtons
            @update="currentFilter = $event"
            :style-class="'flex justify-end gap-x-4'"
            :type-options="campaignStateOptions"
            :model-value="currentFilter"/>
      </div>

      <div class="-mx-4 mt-8 sm:-mx-0">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-dark-blue sm:pl-0 flex items-center">
                Campaign Name
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-dark-blue sm:table-cell">
                <div class="flex items-center">
                  Campaign Type
                </div>
              </th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark-blue lg:table-cell">Status</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-dark-blue">Created By</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-dark-blue">Created At</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(campaign, index) in paginatedCampaigns" :key="index">
              
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ campaign.displayName }}</td>
              <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{campaign.campaignType}}</td>
              <td class="hidden whitespace-nowrap px-3 py-4 text-sm lg:table-cell">
                <span class="px-2.5 py-1.5 rounded-md" :class="getStatusClass(campaign.entityState)">{{campaign.entityState}}</span>
              </td>
              <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{campaign.creator?.firstName ?? ''}} {{campaign.creator?.lastName ?? ''}}</td>
              <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{formatDate(campaign.createdAt)}}</td>

              <td class="whitespace-nowrap py-4 text-sm font-medium sm:pr-0">
                <a :href="`#/edit/${campaign.guid}`" class="relative inline-block">
                  <img src="/img/Icons/kebab_menu.svg" id="kebab" alt="Menu" @click="toggleKebabMenu(campaign)" class="cursor-pointer">
                  <span class="sr-only">, {{ campaign.displayName }}</span>
                  <div v-if="campaign.showKebabMenu" class="absolute right-0 top-full w-40 z-10 mt-2 bg-white border border-gray-300 rounded-md shadow-md">
                    <div @click="showCampaignDetailsPage(campaign)" class="cursor-pointer px-3 py-2 hover:bg-[#DBEAFE]">
                      <a href="#">Campaign Details</a>
                    </div>
                  </div>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Campaign Details Page -->
    <div v-if="showCampaignDetails" class="mt-6 mx-8">
      <div class="border border-gray-900/5 rounded-md shadow-sm max-w-7xl px-4 sm:px-6 sm:py-12 lg:px-8">
        <div class="flex justify-between">
          <a @click="goBackFromCampaignDetails" class="flex items-center cursor-pointer">
            <img class="mr-1" src="/img/Icons/Back_arrow.svg" alt="Back">
            <h1 class="text-sm text-dark-blue">Back</h1>
          </a>
          <div class="flex">
            <Button buttonText="Approve" v-if="selectedCampaign.entityState === 'Pending' && !showApproveModalTriggered" @click="showApproveModal = true;" class="ml-2 px-8 py-1.5 bg-green text-white rounded-md shadow-md"/>
            <Button buttonText="Decline" v-if="selectedCampaign.entityState === 'Pending' && !showApproveModalTriggered && !declineButtonClicked" @click="showDeclineModal = true" class="ml-2 px-8 py-1.5 bg-orange-red text-white rounded-md shadow-md"/>
            <Button buttonText="Pause" v-if="!isPaused && selectedCampaign.entityState === 'Active'" @click="showPauseModal = true" class="ml-2 px-8 py-1.5 bg-light-blue text-white rounded-md shadow-md"/>
            <Button buttonText="Reinstate" v-if="isPaused || selectedCampaign.entityState === 'Paused'" @click="showReinstateModal= true" class="ml-2 px-8 py-1.5 bg-light-blue text-white rounded-md shadow-md"/>
          </div>
        </div>

        <div class="mt-8">
          <div class="space-y-24">
            <div class="grid grid-cols-1 text-sm sm:grid-cols-12 sm:grid-rows-1 sm:gap-x-6 md:gap-x-8 lg:gap-x-8">
              <div class="sm:col-span-4 md:col-span-5 md:row-span-2 md:row-end-2">
                <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-50">
                  <img :src="getCoverPhotoLink(selectedCampaign!)" alt="Campaign Cover Photo" class="object-cover object-center w-full">
                </div>
              </div>
              <div class="mt-6 sm:col-span-7 sm:mt-0 md:row-end-1">
                <h3 class="text-2xl font-bold text-dark-blue">{{ selectedCampaign!.displayName }}</h3>
                <p class="mt-2 font-medium text-gray-500">{{ school?.name }}</p>
                <p class="mt-1 text-gray-500">{{ school?.address.city }}, {{ school?.address.province}}</p>
              </div>
              <div class="sm:col-span-12 md:col-span-7">
                <div class="flex my-4">
                  <div>
                    <p class="text-dark-blue text-base">Start Date</p>
                    <p class="text-dark-blue text-sm mt-1.5">{{ formatDate(selectedCampaign.startDate!) }}</p>
                  </div>
                  <div class="ml-14">
                    <p class="text-dark-blue text-base">End Date</p>
                    <p class="text-dark-blue text-sm mt-1.5">{{ formatDate(selectedCampaign.endDate!) }}</p>
                  </div>
                </div>
                <div class="flex mt-1">
                  <p class="mr-2 text-lg font-medium text-dark-blue">Total Funding Needed:</p>
                  <span class="text-dark-blue text-lg">R{{ selectedCampaign?.targetAmount || 0 }}</span>
                </div>
                <CampaignProgressBar
                    :target-amount="selectedCampaign.targetAmount" 
                    :cumulative-donations="selectedCampaign.cumulativeDonations"/>
                <div class="mt-4">
                  <p class="text-lg font-bold text-dark-blue">About This Campaign</p>
                  <p class="mt-1.5">
                    {{ selectedCampaign.story}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Campaign State Transition Modals -->
    <CampaignApproveModal 
      v-if="showApproveModal" 
      :name="selectedCampaign ? selectedCampaign.displayName : ''"
      @toggle-approve-modal="showApproveModal = false" 
      @approve-campaign="approveCampaign"
    /> 
    <CampaignDeclineModal 
      :name="selectedCampaign ? selectedCampaign.displayName : ''"
      v-if="showDeclineModal" 
      @toggle-decline-modal="showDeclineModal = false" 
      @decline-campaign="declineCampaign"
    />
    <CampaignPauseModal 
      v-if="showPauseModal" 
      :name="selectedCampaign ? selectedCampaign.displayName : ''"
      @toggle-pause-modal="showPauseModal = false" 
      @pause-campaign="pauseCampaign"
    />
    <CampaignReinstateModal
        v-if="showReinstateModal"
        :name="selectedCampaign ? selectedCampaign.displayName : ''"
        @toggle-approve-modal="showReinstateModal = false"
        @reinstate-campaign="approveCampaign"
    />

    <!-- Not Found Image -->
    <NotFound notFoundText="No campaigns could be found" v-if="!showCampaignDetails && filteredCampaigns.length === 0"/>

    <!-- Pagination -->
    <PaginationV2
      v-if="!showCampaignDetails && searchDto.totalEntities"
      @change="handlePageChange"
      :search-dto="searchDto"/>
  </div>
</template>

<script setup lang="ts">
import AdminSidebar from '@/components/Layouts/AdminSidebar.vue'
import Toast from '@/helpers/Toast'
import Button from '@/components/Atomic/AQuarks/Button.vue'
import NotFound from '@/components/Atomic/BAtoms/NotFound.vue'
import {useCampaignStore} from '@/areas/campaigns/stores/campaignStore'
import {useSchoolStore} from '@/areas/schools/stores/schoolStore'
import {computed, onBeforeUnmount, onMounted, PropType, ref, watch} from 'vue';
import {CampaignDto} from '@/areas/campaigns/dtos/campaignDto';
import {SchoolDto} from '@/areas/schools/dtos/schoolDto'
import keycloak from '@/keycloak';
import {CampaignState} from '@/areas/campaigns/enums/campaignState'
import {formatDate} from "@/helpers/utilities/dataFormats";
import CampaignProgressBar from "@/components/Atomic/BAtoms/CampaignProgressBar.vue";
import {compareCampaignEntityState, getStatusClass} from "@/components/Atomic/BAtoms/campaigns/CampaignStateHelpers"
import CampaignStateFilterButtons from "@/components/Atomic/BAtoms/campaigns/CampaignStateFilterButtons.vue";
import PaginationV2 from "@/components/Atomic/AQuarks/PaginationV2.vue";
import {UserSearchDto} from "@/areas/users/dtos/userSearchDto";
import CampaignReinstateModal from "@/components/Atomic/BAtoms/campaigns/modals/CampaignReinstateModal.vue";
import CampaignPauseModal from "@/components/Atomic/BAtoms/campaigns/modals/CampaignPauseModal.vue";
import CampaignDeclineModal from "@/components/Atomic/BAtoms/campaigns/modals/CampaignDeclineModal.vue";
import CampaignApproveModal from "@/components/Atomic/BAtoms/campaigns/modals/CampaignApproveModal.vue";

const props = defineProps({
  searchDto: {
    type: Object as PropType<UserSearchDto>,
    default: {
      pageSize: 7,
      pageNumber: 1,
    }
  }
})

const searchDto = ref(props.searchDto)
const campaigns = ref<CampaignDto[]>()
const school = ref<SchoolDto>()
const campaignStore = useCampaignStore()
const schoolStore = useSchoolStore()

// TODO: Update hte ordering of this list to be more intuitive
const campaignStateOptions = ref<string[]>([CampaignState.Active, CampaignState.Pending, CampaignState.Paused, CampaignState.Declined, CampaignState.Completed])

let searchTerm = ref<string>('');
let currentFilter = ref<string>('');
let isPaused = ref<boolean>(false);
let declineButtonClicked = ref<boolean>(false);
let showApproveModal = ref<boolean>(false);
let showApproveModalTriggered = ref<boolean>(false);
let showDeclineModal = ref<boolean>(false)
let showPauseModal = ref<boolean>(false)
let showReinstateModal = ref<boolean>(false)
let showCampaignDetails = ref<boolean>(false)
let showSearchField = ref<boolean>(true)
let pageTitle = ref<string>('Campaign Management')
let selectedCampaign = ref<CampaignDto | null>(null);

let schoolGuid = keycloak.tokenParsed!.SchoolAdmin

const fetchCampaigns = async () => {
  let schoolFetchResult = await schoolStore.schoolByGuid(schoolGuid)
  
  if(schoolFetchResult.isSuccessful) 
  {
    school.value = schoolFetchResult.content
  }
  
  let campaignFetchResult = await campaignStore.listCampaignsBySchool(schoolGuid);
  
  if(campaignFetchResult.isSuccessful) 
  {
    searchDto.value.totalPages = Math.ceil(campaignFetchResult.content!.length / searchDto.value.pageSize)
    searchDto.value.totalEntities = campaignFetchResult.content!.length
    
    // This orders based on state and then alphabetically if the states are the same
    campaigns.value = campaignFetchResult.content!.sort((a,b) => {
      if (a.entityState === b.entityState){
        return a.displayName.localeCompare(b.displayName)
      }
      return compareCampaignEntityState(a.entityState, b.entityState)
    })
  }
}

const getCoverPhotoLink = (campaign: CampaignDto) => {
  const coverPhotoAsset = campaign.campaignAssets.find(asset => asset.assetType === 'CoverPhoto');
  return coverPhotoAsset ? coverPhotoAsset.preSignedLink : '/img/image.svg';
}

const filteredCampaigns = computed<CampaignDto[]>(() => {
  let filtered: CampaignDto[] = [];
  if (campaigns.value) {
    filtered = campaigns.value;
    if (currentFilter.value) {
      filtered = filtered.filter(campaign => campaign.entityState === currentFilter.value);
    }
    if (searchTerm.value) {
      filtered = filtered.filter(campaign => campaign.displayName.toLowerCase().includes(searchTerm.value.toLowerCase()));
      searchDto.value.pageNumber = 1;
    }
  }

  searchDto.value.totalPages = Math.ceil(filtered.length / searchDto.value.pageSize)
  
  return filtered;
});

const paginatedCampaigns = computed(() => {
  let start = (searchDto.value.pageNumber - 1) * searchDto.value.pageSize
  let end = start + searchDto.value.pageSize
  
  return filteredCampaigns.value.slice(start, end)
})

// Modal functions
const approveCampaign = async () => {
  let approveCampaignResult = await campaignStore.updateCampaignState(schoolGuid, selectedCampaign.value!.guid, CampaignState.Active)
  
  if(approveCampaignResult.isSuccessful){
    Toast.success("Campaign Successfully Approved")
    selectedCampaign.value = approveCampaignResult.content!
  }
  
  showApproveModal.value = false
  showReinstateModal.value = false
  location.reload()
}

const declineCampaign = async () => {
  let approveCampaignResult = await campaignStore.updateCampaignState(schoolGuid, selectedCampaign.value!.guid, CampaignState.Declined)
  
  if (approveCampaignResult.isSuccessful){
    Toast.success("Campaign Successfully Declined")
    selectedCampaign.value = approveCampaignResult.content!
  }
  
  showDeclineModal.value = false
  location.reload()
}

const pauseCampaign = async () => {
  let approveCampaignResult = await campaignStore.updateCampaignState(schoolGuid, selectedCampaign.value!.guid, CampaignState.Paused)
  
  if (approveCampaignResult.isSuccessful){
    Toast.success("Campaign Successfully Paused")
    selectedCampaign.value = approveCampaignResult.content!
  }
  
  showPauseModal.value = false
  location.reload()
}

const handleClickOutside = (event: MouseEvent) => {
  // Cast the target of the event to an Element to access DOM properties
  const targetElement = event.target as Element;
  
  if(targetElement.id !== 'kebab'){
    closeAllKebabMenus();
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  fetchCampaigns();
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
})

// Pagination
watch(() => currentFilter.value, (newValue) => {
  if (searchDto.value){
    searchDto.value!.pageNumber = 1
  }
})

const totalPages = computed(() => {
  if (campaigns.value && campaigns.value?.length > 0){
    return filteredCampaigns.value ? Math.ceil(filteredCampaigns.value!.length / searchDto.value!.pageSize) : 0
  }
})

const paginatedSchools = computed(() => {
  let start = (searchDto.value.pageNumber - 1) * searchDto.value.pageSize
  let end = start + searchDto.value.pageSize
  return filteredCampaigns.value.slice(start, end)
})

const handlePageChange = async (newPage: number) => {
  if (searchDto.value?.totalPages && newPage <= searchDto.value.totalPages && newPage > 0){
    searchDto.value!.pageNumber = newPage
  }
}

// --- end pagination
const filterCampaigns = (status: string) => {
  currentFilter.value = status;
  searchDto.value.pageNumber = 1;
}

const showCampaignDetailsPage = (campaign: CampaignDto) => {
  pageTitle.value = 'Campaign Details'
  campaign.showKebabMenu = false
  showCampaignDetails.value = true;
  showSearchField.value = false;
  selectedCampaign.value = campaign;
}

const goBackFromCampaignDetails = () => {
  pageTitle.value = 'Campaign Management'
  showCampaignDetails.value = false;
  showSearchField.value = true;

  campaigns.value!.forEach((campaign) => {
    campaign.showKebabMenu = false;
    campaign.showDropdown = false;
  });
}

const closeAllKebabMenus = () => {
  const openMenus = campaigns.value!.filter(campaign => campaign.showKebabMenu)
  openMenus.forEach(campaign => campaign.showKebabMenu = false)
}

const toggleKebabMenu = (campaign: CampaignDto) => {
  closeAllKebabMenus()
  campaign.showKebabMenu = true
}
</script>