import axiosInstance from '@/axiosConfig';
import { Operations } from '@/helpers/responses/operations';
import { ProblemDetails } from '@/helpers/responses/models/problemDetails';
import { OperationResult } from '@/helpers/responses/models/operationResult';
import { UserSearchDto } from '../dtos/userSearchDto';
import {UserDto} from "@/areas/users/dtos/userDto";
import { DonationSearchDto } from '../dtos/donationSearchDto';
import {AxiosPromise} from "axios";

export class UserProxy {
    public async customerContact(firstName: string, lastName: string, email: string, phoneNumber: string, message: string): Promise<OperationResult<any>> {
        const uri = `/contactus?firstName=${firstName}&lastName=${lastName}&email=${email}&phoneNumber=${phoneNumber}&message=${message}`;

        const headersContentType = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        
        return this.wrappedRequest<any>(axiosInstance.post(uri, {}, headersContentType))
    }

    public async getOrCreate(): Promise<OperationResult<UserDto>> {
        const uri = `users/auth`

        return this.wrappedRequest<UserDto>(axiosInstance.get(uri))
    };
    
    public async getUser(userGuid: string) : Promise<OperationResult<UserDto>> {
        const uri = `users/${userGuid}`

        return this.wrappedRequest<UserDto>(axiosInstance.get(uri))
    }

    public async updateUserDetails(userAuthGuid: string, userDto: UserDto) : Promise<OperationResult<UserDto>> {
        const uri = `users/${userAuthGuid}`
        
        return this.wrappedRequest(axiosInstance.put(uri, userDto))
    }

    public async fetchUsers(userSearchDto: UserSearchDto) : Promise<OperationResult<UserSearchDto>> {
        const uri = `/users`

        return this.wrappedRequest<UserSearchDto>(axiosInstance.get(uri, { params: userSearchDto }))
    }

    public async updateRole(user: UserDto, schoolGuid?: string) : Promise<OperationResult<UserDto>> {
        if (!schoolGuid){
            schoolGuid = '00000000-0000-0000-0000-000000000000'
        }
        
        let uri = `/users/roles/${schoolGuid}`
        const headers = { 
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        return this.wrappedRequest<UserDto>(axiosInstance.put(uri, user, headers))
    }
    
    public async getKeycloakAttribute(email: string) : Promise<OperationResult<any>> {
        const encodedEmail = encodeURIComponent(email)
        let uri = `/users/roles?email=${encodedEmail}`

        return await this.wrappedRequest<any>(axiosInstance.get(uri))
    }

    public async getMyDonations(donationSearchDto?: DonationSearchDto): Promise<OperationResult<DonationSearchDto>> {
        let uri = `users/donations`;

        if (donationSearchDto) {
            const queryParamsArray = Object.entries(donationSearchDto).map(([key, value]) => {
                const formattedValue = value instanceof Date ? value.toISOString().split('T')[0] : String(value);
                return [key, formattedValue];
            });
        
            const queryParams = new URLSearchParams(queryParamsArray).toString();
            uri += `?${queryParams}`;
        }
        
    
        return await this.wrappedRequest<any>(axiosInstance.get(uri))
    }

    private async wrappedRequest<T>(func: AxiosPromise): Promise<OperationResult<T>> {
        try {
            const response = await func

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status !== 500) {
                const response = error.response;
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                };
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
            return Operations.ToErrorResponse("RequestFailure");
        }
    }
    
}