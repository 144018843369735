import { CampaignState } from "@/areas/campaigns/enums/campaignState";
import {RoleEnum} from "@/areas/users/enums/roleEnum";

export const getRoleClass = (status: RoleEnum): string => {
  switch (status) {
    case 'User':
      return 'bg-green text-white border-none';
    case 'SchoolAdmin':
      return 'bg-light-blue text-white border-none';
    case 'GetGoAdmin':
      return 'bg-purple text-white border-none';
    default:
      return 'bg-dark-blue text-white border border-dark-blue';
  }
}