import axiosInstance from '@/axiosConfig';
import { Operations } from '@/helpers/responses/operations';
import { ProblemDetails } from '@/helpers/responses/models/problemDetails';
import { OperationResult } from '@/helpers/responses/models/operationResult';
import {AxiosPromise} from "axios";
import {UserTaxDetailDto} from "@/areas/taxCertificates/dtos/userTaxDetailDto";
import {TaxYearLogDto} from "@/areas/taxCertificates/dtos/taxYearLogDto";
import {UserTaxCertificateDto} from "@/areas/taxCertificates/dtos/userTaxCertificateDto";

export class TaxCertificateProxy {
    public async upsertTaxDetail(userTaxDetailDto: UserTaxDetailDto) : Promise<OperationResult<UserTaxDetailDto>> {
        let uri = `/users/tax/certificates/details`;
    
        return await this.wrappedRequest(axiosInstance.put(uri, userTaxDetailDto))
    }

    public async fetchTaxDetails() : Promise<OperationResult<any>> {
        // Function returns tax details for authed user
        let uri = `/users/tax/certificates/details`;

        return await this.wrappedRequest(axiosInstance.get(uri))
    }
    
    public async createTaxLog(taxYearLog: TaxYearLogDto) : Promise<OperationResult<any>> {
        let uri = `users/tax/certificates`

        return await this.wrappedRequest<any>(axiosInstance.post(uri, taxYearLog))
    }
    
    public async getMyTaxCertificates() : Promise<OperationResult<UserTaxCertificateDto[]>> {
        let uri = `users/tax/certificates`
        
        return await this.wrappedRequest(axiosInstance.get(uri))
    }
    
    public async getTaxNotificationHistory() : Promise<OperationResult<TaxYearLogDto[]>> {
        let uri = `users/tax/certificates/notifications`
        
        return await this.wrappedRequest(axiosInstance.get(uri))
    }
    
    public async getTaxCertificate(year: number) : Promise<OperationResult<UserTaxCertificateDto[]>> {
        let uri = `users/tax/certificates?year=${year}`
        
        return await this.wrappedRequest(axiosInstance.get(uri))
    }

    private async wrappedRequest<T>(func: AxiosPromise): Promise<OperationResult<T>> {
        try {
            const response = await func

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status !== 500) {
                const response = error.response;
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                };
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
            return Operations.ToErrorResponse("RequestFailure");
        }
    }
    
}