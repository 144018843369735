import axiosInstance from '@/axiosConfig';
import {Operations} from '@/helpers/responses/operations';
import {ProblemDetails} from '@/helpers/responses/models/problemDetails';
import {OperationResult} from '@/helpers/responses/models/operationResult';
import { CreateCampaignDto } from '../dtos/createCampaignDto';
import { CampaignAssetDto } from '../dtos/campaignAssetDto';
import { CampaignDto } from '../dtos/campaignDto';
import { CampaignState } from '../enums/campaignState';

export class CampaignProxy {

    public async createCampaignRequest(schoolGuid: string, createCampaignDto: CreateCampaignDto): Promise<OperationResult<CampaignDto>> {
        const uri = `/schools/${schoolGuid}/campaigns`;
    
        try {
            const headersContentType = {
                headers: {
                    'Content-Type': 'application/json'
            }}
            const response = await axiosInstance.post(uri, createCampaignDto, headersContentType)
    
            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response
    
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }
    
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
    
            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async fetchCampaign(schoolGuid: string, campaignGuid: string): Promise<OperationResult<CampaignDto>> {
        const uri = `/schools/${schoolGuid}/campaigns/${campaignGuid}`;
    
        try {
            const response = await axiosInstance.get(uri)
    
            return Operations.ToSuccessResponse(response.data as CampaignDto);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response
    
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }
    
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
    
            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async uploadCoverPhoto(blob: Blob, schoolGuid: string, campaignGuid: string, campaignAssetDto: CampaignAssetDto): Promise<OperationResult<CampaignAssetDto>> {
        const uri = `/schools/${schoolGuid}/campaigns/${campaignGuid}/assets`;
    
        try { 
    
            const response = await axiosInstance.post(uri, campaignAssetDto, {});
            
            const createImgDto = response.data as CampaignAssetDto;
            
            await axiosInstance.put(createImgDto.preSignedLink!, blob, {
                headers: {
                    'Content-Type': `${campaignAssetDto.mimeType}`,
                    skipAuthHeader: true
            }})
            
            return Operations.ToSuccessResponse(campaignAssetDto);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response
    
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }
    
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
    
            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    // Fetches all campaigns for a specific school
    public async listCampaignsBySchool(schoolGuid: string, state?: CampaignState): Promise<OperationResult<CampaignDto[]>> {
        let uri = `/schools/${schoolGuid}/campaigns`
        
        if (state){
            uri += `?state=${state}`
        }

        try {
            const response = await axiosInstance.get<CampaignDto[]>(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    // Fetch a specific campaign from a specific school
    public async listSingleCampaignFromSchool(schoolGuid: string, campaignGuid: string): Promise<OperationResult<CampaignState>> {
        const uri = `/schools/${schoolGuid}/campaigns/${campaignGuid}`

        try {
            const response = await axiosInstance.get<CampaignState>(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async fetchCampaignsByName(campaignName: string): Promise<OperationResult<CampaignDto[]>> {
        const uri = `/campaigns/searchCampaignsByName?campaignName=${campaignName}`;
    
        try {
            const response = await axiosInstance.get(uri);
    
            return Operations.ToSuccessResponse(response.data as CampaignDto[]);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response;
    
                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                };
    
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
    
            return Operations.ToErrorResponse("RequestFailure");
        }
    }
    

    public async updateCampaignState(schoolGuid: string, campaignGuid: string, campaignState: string) : Promise<OperationResult<CampaignDto>> {
        const uri = `/schools/${schoolGuid}/campaigns/${campaignGuid}/states/${campaignState}`

        try {
            const response = await axiosInstance.post(uri)
            
            return Operations.ToSuccessResponse(response.data as CampaignDto);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }
    
}