<template>
  <button type="button" class="rounded-md font-roboto">
    {{ buttonText }}
  </button>
</template>

<script lang="ts">

export default ({
  name: 'Button',
  props: {
    buttonText: {
      type: String,
    },
    customStyle: {
      type: String
    }
  }
})
</script>