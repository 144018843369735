import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex mx-8 sm:px-0" }
const _hoisted_2 = { class: "-mt-px flex w-0 flex-1" }
const _hoisted_3 = { class: "hidden md:-mt-px md:flex" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "-mt-px flex w-0 flex-1 justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousPage && _ctx.goToPreviousPage(...args))),
        class: "inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700"
      }, " Previous ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedPages, (pageNumber) => {
        return (_openBlock(), _createElementBlock("a", {
          key: pageNumber,
          onClick: ($event: any) => (_ctx.goToPage(pageNumber)),
          class: _normalizeClass([{ 'border-b-2 border-dark-blue': _ctx.currentPage === pageNumber }, "inline-flex items-center px-4 pt-4 text-sm font-medium text-dark-blue cursor-pointer"]),
          "aria-current": "page"
        }, _toDisplayString(pageNumber), 11, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToNextPage && _ctx.goToNextPage(...args))),
        class: "inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-800"
      }, " Next ")
    ])
  ]))
}