<template>
  <UserSidebar :pageTitle="pageTitle"/>

  <div class="font-roboto lg:pl-72 pb-16">
    <div class="px-4 sm:px-6 lg:px-8 mt-6 mx-8 border border-gray-900/5 rounded-md shadow-sm"> 
      <div class="mt-5 flex justify-between">
        <div class="sm:col-span-full flex gap-x-4 items-center">
          <label class="block text-lg font-semibold leading-6 text-dark-blue">Tax Year</label>
          <div class="mt-2 w-36 relative">
            <Dropdown 
                @update:model-value="taxCertificateStore.setTaxYear(year = $event)"
                :placeholder="'Select Option'" 
                :model-value="taxCertificateStore.taxYear.toString()" 
                :options="years"/>
          </div>
        </div>
        <div class="flex justify-center items-center">
          <p class="text-dark-blue text-sm text-center">You will be notified when the Section 18A tax certificate is available at tax year end.</p>
        </div>
          <div class="flex justify-end items-center opacity-50">
            <img class="mr-2" src="/img/Icons/Tax_Cert.svg" alt="Tax Certificate Icon">
            <!-- TODO: Update if there is a s3key to get the download url instead -->
            <a v-show="taxNotification && taxCertificate" @click="handleGetCertificate(taxCertificate)"
               class="underline text-blue-700 cursor-pointer">Section 18A tax certificate</a>
            <a v-show="!taxNotification || !taxCertificate"
               :class="!taxCertificate ? 'cursor-not-allowed' : 'cursor-default'"
               class="underline text-dark-blue cursor-not-allowed opacity-50">Section 18A tax certificate</a>
          </div>
      </div>

      <div class="-mx-4 mt-6 sm:-mx-0">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-dark-blue sm:pl-0 flex items-center">
                Date Donated
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-dark-blue sm:table-cell">
                Campaign Name
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-dark-blue">
                Amount Donated
              </th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark-blue lg:table-cell">
                Receipt Reference
              </th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark-blue lg:table-cell">
                Bona fide Donation
              </th>
            </tr>
          </thead>
          <tbody v-if="donationSearchDto && donations.length > 0" class="divide-y divide-gray-200 bg-white">
            <tr v-for="(donation, index) in donations" :key="donation.guid" :class="{'bg-[#F9FDFF]': index % 2 === 0}">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-dark-blue sm:pl-0">
                <div>{{ formatDate(donation.createdAt) }}</div>
                <div class="text-xs text-gray-500">{{ formatTime(donation.createdAt) }}</div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ donation.campaign.displayName }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ donation.amount }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <!-- Displaying guid in the meantime until we find a more user readable reference. -->
                {{ donation.session.guid }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ donation.isBonafideDonation ? 'Yes' : 'No' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <TaxInfoConfirmationModal  
      :show="showTaxModal" 
      @continue="handleProfileNavigation"
      @close="showTaxModal = false"
    />

    <!-- Not Found Image -->
    <NotFound 
      notFoundText="No Donations Made" 
      v-if="donations?.length === 0"
    />

    <!-- Pagination -->
    <PaginationV2 v-if="donations && donationSearchDto?.totalPages"
        :search-dto="donationSearchDto"
        @change="handlePageChange"
    />
  </div>
</template>

<script setup lang="ts">

import {ref, onMounted, PropType, watch} from 'vue';
import { useUserStore } from '@/areas/users/stores/userStore'
import { DonationDto } from '@/areas/sessions/dtos/donationDto'
import { DonationSearchDto } from '@/areas/users/dtos/donationSearchDto'
import { UserDto } from '@/areas/users/dtos/userDto'
import {TaxYearLogDto} from "../areas/taxCertificates/dtos/taxYearLogDto";
import {useTaxCertificateStore} from "../areas/taxCertificates/stores/taxCertificateStore";
import {UserTaxCertificateDto} from "../areas/taxCertificates/dtos/userTaxCertificateDto";
import {getTaxYearEnd, getTaxYearStart, taxYears} from "@/helpers/utilities/utility";
import UserSidebar from '@/components/Layouts/UserSidebar.vue'
import keycloak from '@/keycloak'
import TaxInfoConfirmationModal from '@/components/Atomic/BAtoms/TaxInfoConfirmationModal.vue';
import Dropdown from "@/components/Atomic/AQuarks/Dropdown.vue";
import Toast from "@/helpers/Toast";
import PaginationV2 from "@/components/Atomic/AQuarks/PaginationV2.vue";
import NotFound from "@/components/Atomic/BAtoms/NotFound.vue";
import { useRoute, useRouter} from "vue-router";
import MyProfile from "@/views/MyProfile.vue";
import {formatDate, formatTime} from "../helpers/utilities/dataFormats";

const props = defineProps({
  years: {
    type: Array as PropType<number[]>,
    default: taxYears()
  },
  year: {
    type: String,
    required: true,
  },
  donationSearchDto: {
    type: Object as PropType<DonationSearchDto>,
    default: () => ({
      startDate: new Date(),
      endDate: new Date(),
      donations: [] as DonationDto[],
      pageSize: 10,
      pageNumber: 1, // Consider changing this to 1 if your pagination starts at 1
    }) as DonationSearchDto,
  },
  user: {
    type: Object as PropType<UserDto>,
  },
  myCertificates:{
    type: Object as PropType<UserTaxCertificateDto[]>,
  },
  taxNotifications: {
    type: Object as PropType<TaxYearLogDto[]>,
  }
})

const userStore = useUserStore()
const taxCertificateStore = useTaxCertificateStore()
const router = useRouter()
const route = useRoute()
const userAuthGuid = keycloak.tokenParsed?.sub

const years = ref(props.years)
const taxNotifications = ref(props.taxNotifications)
const myCertificates = ref(props.myCertificates)
const donationSearchDto = ref(props.donationSearchDto)

const taxNotification = ref<TaxYearLogDto>()
const taxCertificate = ref<UserTaxCertificateDto>()
const showTaxModal = ref<boolean>(false)

const user = ref<UserDto | null>(userStore.$state.user)

const showDropdown = ref<boolean>(false);
const showPagination = ref<boolean>(false);
// Hardcoded for now to get the dropdown working

const donations = ref<DonationDto[]>([])

let pageTitle = ref<string>('My Donations')

// Watchers
watch(() => taxCertificateStore.taxYear, async (newValue) => {
  showDropdown.value = false
  taxNotification.value = await taxCertificateStore.getNotification(newValue)
  taxCertificate.value = await taxCertificateStore.getCertificate(newValue)
  await fetchMyDonations()
}, )

watch(() => taxCertificateStore.myCertificates, async (newValue) => {
  if (taxCertificateStore.myCertificates){
    taxCertificate.value = taxCertificateStore.myCertificates!.find(certificate => certificate.financialYear == taxCertificateStore.taxYear) as UserTaxCertificateDto
  }
})

watch(() => taxCertificateStore.taxNotifications, async (newValue) => {
  if (taxCertificateStore.taxNotifications){
    taxNotification.value = taxCertificateStore.taxNotifications!.find(notification => notification.year == taxCertificateStore.taxYear) as TaxYearLogDto
  }
})

watch(() => donationSearchDto.value.pageNumber, (newValue) => {
  paginateDonations()
})

onMounted(async () => {
  // initialise store
  if (route.params.year || props.year){
    await taxCertificateStore.init(Number(route.params.year))
  } else {
    await taxCertificateStore.init(new Date().getFullYear()+1)
  }
  
  if (taxCertificateStore.errorStack?.length !== 0){
    // Not sure why this would failed
    Toast.error("Unable to load tax certificates. Some functionality may not work")
  }
  
  await fetchMyDonations()
  
  // download if profile navigation success
  if (route.params.year){
    Toast.info("Creating Certificate")
    var result = await taxCertificateStore.getTaxCertificate(Number(route.params.year))

    if (!result.isSuccessful){
      Toast.error("Failed to create certificate")
    }

    window.open(result.content![0]!.downloadUrl, '_blank')
  }
})

const handleProfileNavigation = async () => {
  showTaxModal.value = false
  
  //localStorage.setItem('navigatedToProfile', 'true')
  await router.push({
    name : 'MyProfile',
    params: {
      "year": taxCertificateStore.taxYear
    }
  })
}

const handleGetCertificate = async (certificate: UserTaxCertificateDto) => {
  if (certificate.s3Key !== undefined){
    const result = await taxCertificateStore.getTaxCertificate(Number(taxCertificateStore.taxYear))
    
    if (!result.isSuccessful){
      Toast.error("Could not retrieve tax certificate")
      return
    }
    
    certificate.downloadUrl = result.content!.find(certificate => certificate.financialYear === Number(taxCertificateStore.taxYear) && certificate.downloadUrl !== undefined)!.downloadUrl!
    
    window.open(certificate.downloadUrl!, '_blank')
    return
  }
  
  showTaxModal.value = true
  return
}
  
const fetchMyDonations = async () => {
  donationSearchDto.value.startDate = getTaxYearStart(taxCertificateStore.taxYear)
  donationSearchDto.value.endDate = getTaxYearEnd(taxCertificateStore.taxYear)
  
  const result = await userStore.getMyDonations(donationSearchDto.value)

  if (!result.isSuccessful){
    Toast.error("Could not get a list of donations for this year")
    return;
  }
  
  donationSearchDto.value = result.content! as DonationSearchDto
  
  await paginateDonations()
  showPagination.value = true
};

const handlePageChange = (newNumber: number) => {
  donationSearchDto.value!.pageNumber = newNumber
}

const paginateDonations = () => {
  const start = (donationSearchDto.value!.pageNumber-1) * donationSearchDto.value!.pageSize
  const end = start + donationSearchDto.value!.pageSize
  donations.value = donationSearchDto.value!.donations.slice(start, end)
}

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};
</script>