export function taxYears(stopYear: number=2022): number[] {
    const currentYear = new Date().getFullYear()+1

    let years = []

    for (let year = currentYear; year >= 2022; year--){
        years.push(year)
    }

    return years
}

export function getTaxYearStart(taxYear: number): Date {
    // months use a 0-index; month-2 is March
    return new Date(Date.UTC(taxYear-1, 2, 1));
}

export function getTaxYearEnd(taxYear: number): Date {
    return new Date(Date.UTC(taxYear, 1, 28)); // Sets the date to March 1st of the next year
}

export function toTitleCase(text: string): string {
    const words = text.split(" ")
    
    const titleWords = words.map(word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    
    return titleWords.join(" ")
}