<template>
  <nav class="flex mx-8 sm:px-0">
    <div class="-mt-px flex w-0 flex-1">
      <a href="#" @click="goToPreviousPage" class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700">
        Previous
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <a v-for="pageNumber in displayedPages" :key="pageNumber" @click="goToPage(pageNumber)" :class="{ 'border-b-2 border-dark-blue': currentPage === pageNumber }" class="inline-flex items-center px-4 pt-4 text-sm font-medium text-dark-blue cursor-pointer" aria-current="page">
        {{ pageNumber }}
      </a>
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <a href="#" @click="goToNextPage" class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-800">
        Next
      </a>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    currentPage: {
      type: Number as PropType<number>,
      required: true,
    },
    totalPages: {
      type: Number as PropType<number>,
      required: true,
    },
    goToPreviousPage: {
      type: Function as PropType<() => void>,
      required: true,
    },
    goToNextPage: {
      type: Function as PropType<() => void>,
      required: true,
    },
    goToPage: {
      type: Function as PropType<(page: number) => void>,
      required: true,
    },
  },
  computed: {
    displayedPages(): number[] {
      const range = 3;
      let start = Math.max(1, this.currentPage - range);
      let end = Math.min(this.totalPages, this.currentPage + range);

      // Adjust start and end if current page is near the start or end
      if (this.currentPage - start < range) {
        end = Math.min(this.totalPages, end + range - (this.currentPage - start));
      }
      if (end - this.currentPage < range) {
        start = Math.max(1, start - range + (end - this.currentPage));
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
});
</script>
