import axiosInstance from '@/axiosConfig';
import {Operations} from '@/helpers/responses/operations';
import {ProblemDetails} from '@/helpers/responses/models/problemDetails';
import {OperationResult} from '@/helpers/responses/models/operationResult';
import {SchoolRequestDto} from '../dtos/schoolRequestDto';
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";

import { SchoolSearchResultDto } from '../dtos/schoolSearchResultDto';
import { SchoolStateEnum } from '../enums/schoolStateEnum';

export class SchoolProxy {
    public async fetchAllSchools(states?: SchoolStateEnum[], searchTerm?: string): Promise<OperationResult<SchoolDto[]>> {
        let uri = '/schools?';
        
        if (states && states.length > 0){
            uri += states!.map(state => `states=${state}`).join('&')
        }
        if (searchTerm){
            uri += `&searchTerm=${searchTerm}`
        }
    
        try {
            const response = await axiosInstance.get(uri);
            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status !== 500) {
                const problemDetails: ProblemDetails = {
                    detail: error.response.data.detail || "Unknown error",
                    status: error.response.status,
                    type: error.response.data.type || ""
                };
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
            return Operations.ToErrorResponse("RequestFailure");
        }
    }
   
    public async searchSchool(searchTerm: string): Promise<OperationResult<SchoolSearchResultDto[]>> {
        const uri = `/schools/searches?searchTerm=${searchTerm}`;

        try {
            const response = await axiosInstance.get(uri);
            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }
                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }
            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async schoolByGuid(schoolGuid: string): Promise<OperationResult<SchoolDto>> {
        const uri = `/schools/${schoolGuid}`

        try {
            const response = await axiosInstance.get<SchoolDto>(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async registerMySchoolRequest(schoolRequestDto: SchoolRequestDto): Promise<OperationResult<SchoolRequestDto>> {
        const uri = '/schools/requests'

        try {
            const headersContentType = {
                headers: {
                    'Content-Type': 'application/json'
            }}
            const response = await axiosInstance.post(uri, schoolRequestDto, headersContentType)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async updateState(schoolGuid: string, newState: SchoolStateEnum): Promise<OperationResult<SchoolDto>> {
        const uri = `/schools/${schoolGuid}/states/${newState}`

        try {
            const response = await axiosInstance.post(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async schoolByRequestor(schoolGuid: string): Promise<OperationResult<SchoolRequestDto>> {
        const uri = `/schools/${schoolGuid}/requestor`

        try {
            const response = await axiosInstance.get(uri)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }

    public async updateSchoolDetails(schoolGuid: string, schoolDto: SchoolDto): Promise<OperationResult<SchoolDto>> {
        const uri = `/schools/${schoolGuid}`
        
        if (!schoolDto.bankDetail?.bankAccountType){
            schoolDto.bankDetail = undefined
        }

        try {
            const response = await axiosInstance.put(uri, schoolDto)

            return Operations.ToSuccessResponse(response.data);
        } catch (error: any) {
            if (error.response && error.response.status != 500) {
                const response = error.response

                const problemDetails: ProblemDetails = {
                    detail: response.data.details || "Unknown error",
                    status: response.status,
                    type: response.data.type
                }

                return Operations.ToErrorResponseFromProblemDetails(problemDetails)
            }

            return Operations.ToErrorResponse("RequestFailure")
        }
    }
    
    public async searchSchoolWithStates(states?: SchoolStateEnum[], searchTerm?: string): Promise<OperationResult<SchoolDto[]>> {
        const stateQueryParam = states?.join('&')
        const uri = `/schools?states=${stateQueryParam}&searchTerm=${searchTerm}`;

        try {
            const response = await axiosInstance.get(uri);
            
            return Operations.ToSuccessResponse(response.data as SchoolDto[]);
        } catch (error: any) {
            if (error.response && error.response.status !== 500) {
                const problemDetails: ProblemDetails = {
                    detail: error.response.data.detail || "Unknown error",
                    status: error.response.status,
                    type: error.response.data.type || ""
                };
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
            return Operations.ToErrorResponse("RequestFailure");
        }
    }
}