<template>
  <div class="relative w-full">
    <input
        @focus="showResults"
        @blur="hideSearchResults"
        type="text"
        :placeholder="placeholder"
        :class="style"
        class="mt-2 border border-gray-300 rounded-md py-1.5 px-2 outline-none shadow-sm w-80 sm:w-full"
        @input="emit('input', searchTerm)"
        v-model="searchTerm"
    /> 
    <div v-show="showSearchResults" :class="'absolute z-50 w-full'">
      <div class="mt-1 rounded-md bg-white shadow-lg" style="top: calc(100% + 5px);">
        <ul class="py-1">
          <li v-for="campaign in filteredCampaigns" :key="campaign.guid" class="text-dark-blue px-3 py-2 hover:bg-gray-100 cursor-pointer text-left" @click="select(campaign)">
            {{ campaign.displayName }}, {{campaign.school.name}}, {{campaign.school.address.city}}, {{campaign.school.address.province}}
          </li>
        </ul>
        <ul v-show="searchTerm.length > 0 && filteredCampaigns.length === 0" @click="createCampaign" class="text-dark-blue px-3 py-2 hover:bg-gray-100 cursor-pointer text-left">
          {{ 'Campaign not found, click to Create New Campaign' }}
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {PropType, ref, watch} from "vue";
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";
import {CampaignDto} from "@/areas/campaigns/dtos/campaignDto";
import router from "@/router";

const props = defineProps({
  // List of objects to search
  campaign: {
    type: Object as PropType<CampaignDto | undefined>,
    default: undefined
  },
  // Custom label styling
  style: {
    type: String,
  },
  filteredCampaigns: {
    type: Array as PropType<CampaignDto[]>,
    default: [],
  },
  showSearchResults: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: "Search..."
  }
})

const emit = defineEmits(['campaign-change', 'input'])

const searchTerm = ref(props.campaign?.name || '')
const filteredCampaigns = ref<CampaignDto[]>(props.filteredCampaigns)
const showSearchResults = ref(props.showSearchResults)
const placeholder = ref(props.placeholder)

watch(() => props.campaign, (newValue) => {
  searchTerm.value = newValue?.name || ''
}, {
  immediate: true
});

watch(() => props.filteredCampaigns, (newValue) => {
  filteredCampaigns.value = props.filteredCampaigns
}, {
  immediate: true
});

const showResults = () => {
  showSearchResults.value = true
}
const hideSearchResults = () => {
  setTimeout(() => {
    showSearchResults.value = false;
  }, 200); // adjust delay as necessary
}

const select = (campaign: CampaignDto) => {
  searchTerm.value = campaign.name
  filteredCampaigns.value = []
  
  emit('campaign-change', campaign)
}

const createCampaign = async () => {
  await router.push({
    name: 'AddCampaign'
  })
}
</script>
