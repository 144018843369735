<template>
  <div class="min-h-screen">
    <Sidebar />
    <div class="bg-dark-blue">
      <main class="isolate font-roboto">
        <div class="relative">
          <div class="mx-auto max-w-7xl text-left sm:text-center px-6 pb-14 sm:pb-28 pt-12 sm:pt-16 lg:px-8 lg:pt-32"> 
            <div class="mx-auto max-w-5xl">
              <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:-mt-20">ABOUT US</h1>
              <div class="mt-7 sm:mb-2.5 sm:leading-7 text-white lg:max-w-none">
                <p class="text-xl sm:text-2xl font-bold mb-4 lg:mb-0">
                  We are funding quality education from the get go
                </p>
                <div class="mb-4 lg:mb-0">
                  <p class="mt-4 sm:mt-10 paragraph">
                    GetGo is an education crowdfunding platform dedicated to making quality education more accessible to children in South Africa&nbsp;
                  </p>
                  <p class="mt-2 sm:mt-1 paragraph">
                    by connecting schools, learners, and parents with donors. The platform is centered around community support, enabling&nbsp;
                  </p>
                  <p class="mt-2 sm:mt-1 paragraph">
                    everyone to contribute to a brighter future for South Africa&#39;s youth.
                  </p>
                </div>
                <div class="mb-4 lg:mb-0">
                  <p class="mt-4 sm:mt-8 paragraph">
                    GetGo facilitates fundraising for scholarships, tuition fees, and essential resources. With each campaign, we not only offer&nbsp;
                  </p>
                  <p class="mt-2 sm:mt-1 paragraph">
                    donors the ability of making a difference, we also provide them with a valuable tax benefit, Section 18A tax certificates.
                  </p>
                </div>
                <div>
                  <p class="mt-4 sm:mt-8 paragraph">
                    Founded by the Maharishi Invincibility Institute and the Feenix Group, GetGo aims to unite communities in supporting the&nbsp;
                  </p>
                  <p class="mt-2 sm:mt-1 paragraph">
                    educational dreams of South African children.
                  </p>
                </div>
                <div class="mt-5 sm:mt-10">
                  <span class="block w-7 h-7 bg-white absolute left-1/2 -translate-x-1/2 mt-2 rotate-45" style="transform: translate(-50%, 20px) rotate(45deg);"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-left sm:text-center mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto mt-11 sm:mt-1">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-5xl font-roboto">WHY GETGO?</h2>
          </div>
          <dl class="mx-auto mt-7 max-w-5xl leading-7">
            <div class="text-white">
              <dt class="text-xl sm:text-2xl font-bold mb-8 lg:mb-0">
                We exist to connect schools with donors to make quality education possible for all
              </dt>
              <dd class="mt-8 paragraph">GetGo is an education crowdfunding platform that connects schools and their learners with&nbsp;</dd>
              <dd class="mt-0 sm:mt-1 paragraph">opportunities for financial support.</dd>
            </div>
            <div class="text-white mt-8">
              <dt class="font-semibold text-xl mb-2 lg:mb-0">
                Enabling quality education
              </dt>
              <dd class="mt-2 paragraph">GetGo is designed for schools, learners, parents and donors. Based on the success of the student-centric crowdfunding&nbsp;</dd>
              <dd class="mt-1 paragraph">platform Feenix, GetGo is tailored to enable quality educational opportunities for school learners by connecting donor&nbsp;</dd>
              <dd class="mt-1 paragraph">communities with school communities.</dd>
            </div>
            <div class="text-white mt-8">
              <dt class="font-semibold text-xl mb-2 lg:mb-0">
                Educational donations made simple
              </dt>
              <dd class="mt-2 paragraph">GetGo provides an effortless, transparent and supportive platform for everyday South Africans to contribute towards a&nbsp;</dd>
              <dd class="mt-1 paragraph">learner&#39;s school fees and other meaningful educational-related causes. Donors receive system-generated Section 18A tax&nbsp;</dd>
              <dd class="mt-1 paragraph">certificates at the end of each tax year.</dd>
            </div>
            <div class="text-white mt-8">
              <dt class="font-semibold text-xl mb-2 lg:mb-0">
                Unlocking more scholarships
              </dt>
              <dd class="mt-2 paragraph">Schools and learners can use the GetGo platform to launch scholarship campaigns, supporting broader access for learners in&nbsp;</dd>
              <dd class="mt-1 paragraph">pursuing their academic goals and unlocking true potential.</dd>
            </div>
            <div class="text-white mt-8">
              <dt class="font-semibold text-xl mb-2 lg:mb-0">
                Campaign for school needs
              </dt>
              <dd class="mt-2 paragraph">Whether fundraising for upgrading facilities, extracurricular programmes, sports events or other essential resources, GetGo&nbsp;</dd>
              <dd class="mt-1 paragraph">enables schools to rally their community around specific needs for enhancing the learner's overall educational experience.</dd>
            </div>
            <div class="text-white mt-8 pb-5">
              <dt class="font-semibold text-xl mb-2 lg:mb-0">
                Community-driven impact
              </dt>
              <dd class="mt-2 paragraph">GetGo provides a platform for creating school networks by connecting schools with their local community members, alumni,&nbsp;</dd>
              <dd class="mt-1 paragraph">local businesses and other potential donors. Empowering everyone to play a role in shaping the future of South African</dd>
              <dd class="mt-1 paragraph">children.</dd>
            </div>
          </dl>
        </div>
      </main>
    </div>

    <Footer />
  </div>
</template>

<script lang="ts">
import Footer from '@/components/Atomic/AQuarks/Footer.vue'
import Sidebar from '@/components/Layouts/Sidebar.vue'

  export default {
    name: 'AboutUs',
    components: {
      Footer,
      Sidebar
    },    
  }
</script>
