import axiosInstance from '@/axiosConfig';
import {Operations} from '@/helpers/responses/operations';
import {ProblemDetails} from '@/helpers/responses/models/problemDetails';
import {OperationResult} from '@/helpers/responses/models/operationResult';
import {ReportRequestDto} from "@/areas/reports/dtos/reportRequestDto";
import {ReportType} from "@/areas/reports/enums/reportType";


export class ReportProxy {
    public async getReport(reportRequestDto: ReportRequestDto): Promise<OperationResult<Blob>> {
        const uri = `/reports`

        if (reportRequestDto.reportType == ReportType.Balances){
            reportRequestDto.from = new Date(2020, 1,1)
        }
        
        const queryParams = {
            "reportType": reportRequestDto.reportType.toString(),
            "from": reportRequestDto.reportType === ReportType.Balances ? "2020-01-01" : reportRequestDto.from.toString(),
            "to": reportRequestDto.to.toString(),
        } as Record<string, string>

        
        try {
            const response = await axiosInstance.get(uri, {
                responseType: 'blob',
                params: new URLSearchParams(queryParams),
            });

            const blob = new Blob([response.data], { type: 'text/csv' });
            
            return Operations.ToSuccessResponse(blob);
        } catch (error: any) {
            if (error.response && error.response.status !== 500) {
                const problemDetails: ProblemDetails = {
                    detail: error.response.data.detail || "Unknown error",
                    status: error.response.status,
                    type: error.response.data.type || ""
                };
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
            return Operations.ToErrorResponse("RequestFailure");
        }
    }
    public async upload(file: File): Promise<OperationResult<undefined>> {
        const uri = `/reports/balances`
        
        const formData = new FormData();
        formData.append('file', file)

        try {
            await axiosInstance.post(uri, formData)

            return Operations.ToSuccessResponse(undefined);
        } catch (error: any) {
            if (error.response && error.response.status !== 500) {
                const problemDetails: ProblemDetails = {
                    detail: error.response.data.detail || "Unknown error",
                    status: error.response.status,
                    type: error.response.data.type || ""
                };
                return Operations.ToErrorResponseFromProblemDetails(problemDetails);
            }
            return Operations.ToErrorResponse("RequestFailure");
        } 
    }
}