import {SchoolStateEnum} from "@/areas/schools/enums/schoolStateEnum";

export const getEntityStateColour = (entityState: SchoolStateEnum): string => {
  switch (entityState) {
  case 'Approved':
    return '#22B863';
  case 'Requested':
    return '#35A6D8';
  case 'Rejected':
    return '#FB4B1E';
  default:
    return '#172850';
  }
}

export const getStatusClass = (entityState: SchoolStateEnum): string => {
  switch (entityState) {
    case 'Requested':
      return 'bg-light-blue text-white';
    case 'Approved':
      return 'bg-green text-white';
    case 'Rejected':
      return 'bg-orange-red text-white';
    default:
      return 'bg-dark-blue text-white border border-dark-blue';
  }
};