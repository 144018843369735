<template>
  <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2">
    <div class="px-4 py-6 sm:p-8">
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
        <InputField
            class="sm:col-span-3"
            label="Campaign Name*"
            placeholder="Campaign Name"
            :error-message="showErrors ? campaignFields.errors.value['displayName'] : undefined"
            v-model="campaignForm.displayName"
            required
        />
  
        <div class="sm:col-span-3">
          <div class="sm:col-span-3 block font-medium leading-6 text-dark-blue">Search School
            <tooltip content="In order to create a campaign your school needs to be a registered GetGo school."/>
          </div>
          <SchoolSearch
              :style="'mt-2 block rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:leading-6'"
              :school="campaignForm.school"
              :filtered-schools="filteredSchools"
              @school-change="(selectedSchool) => selectSchool(selectedSchool)"
              @input="filterSchools"/>

          <span v-if="showErrors && campaignFields.errors.value['school.guid']" class="text-red-500 text-sm mt-1">{{ campaignFields.errors.value['school.guid'] }}</span>
        </div>
  
        <CurrencyInputField
          class="sm:col-span-3"
          label="Fundraising Target*"
          placeholder="Fundraising Target"
          :error-message="showErrors ? campaignFields.errors.value['targetAmount'] : undefined"
          v-model="campaignForm.targetAmount"
          required
        />
        <Dropdown
          class="sm:col-span-3"
          :model-value="campaignForm.campaignType"
          :error-message="showErrors ? campaignFields.errors.value['campaignType'] : undefined"
          @update:model-value="campaignForm.campaignType = $event"
          placeholder="Select Option"
          label="Campaign Type"
          :options="campaignTypes"
        />
        <div v-if="campaignForm.individuals?.length === 2 && campaignForm.campaignType && campaignForm.campaignType === 'Individual'" class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 sm:col-span-full">
          <InputField
              class="sm:col-span-3"
              label="Learner First Name*"
              placeholder="Learner First Name"
              :error-message="showErrors ? campaignFields.errors.value['individuals[0].firstName'] : undefined"
              v-model="campaignForm.individuals[0].firstName"
              :required="campaignForm.campaignType === CampaignType.Individual"
          />
          <InputField
            class="sm:col-span-3"
            label="Learner Last Name*"
            placeholder="Learner Surname"
            :error-message="showErrors ? campaignFields.errors.value['individuals[0].lastName'] : undefined"
            v-model="campaignForm.individuals[0].lastName"
            :required="campaignForm.campaignType === CampaignType.Individual"
          />
          <Dropdown
            class="sm:col-span-3"
            :model-value="campaignForm.individuals[0].schoolGrade"
            :error-message="showErrors ? campaignFields.errors.value['individuals[0].schoolGrade'] : undefined"
            @update:model-value="campaignForm.individuals[0].schoolGrade = $event"
            placeholder="Select Grade"
            label="Learner Grade*"
            :options="gradeOptions"
          />
          <InputField
            class="sm:col-span-3"
            label="Learner ID/Passport*"
            placeholder="Learner ID/Passport"
            :error-message="showErrors ? campaignFields.errors.value['individuals[0].identificationNumber'] : undefined"
            v-model="campaignForm.individuals[0].identificationNumber"
            :required="campaignForm.campaignType === CampaignType.Individual"
          />
          <InputField
            class="sm:col-span-3"
            label="Parent/Guardian Name*"
            placeholder="Parent/Guardian Name"
            :error-message="showErrors ? campaignFields.errors.value['individuals[1].firstName'] : undefined"
            v-model="campaignForm.individuals[1].firstName"
            :required="campaignForm.campaignType === CampaignType.Individual"
          />
          <InputField
            class="sm:col-span-3"
            label="Parent/Guardian Last Name*"
            placeholder="Parent/Guardian Last Name"
            :error-message="showErrors ? campaignFields.errors.value['individuals[1].lastName'] : undefined"
            v-model="campaignForm.individuals[1].lastName"
            :required="campaignForm.campaignType === CampaignType.Individual"
          />
          <InputField
            class="sm:col-span-3"
            label="Parent/Guardian Email*"
            placeholder="Parent/Guardian Email"
            :error-message="showErrors ? campaignFields.errors.value['individuals[1].email'] : undefined"
            :required="campaignForm.campaignType === CampaignType.Individual"
            v-model="campaignForm.individuals[1].email"
          />
          <InputField
            class="sm:col-span-3"
            label="Parent/Guardian Phone Number*"
            placeholder="Parent/Guardian Phone Number"
            :error-message="showErrors ? campaignFields.errors.value['individuals[1].phoneNumber'] : undefined"
            v-model="campaignForm.individuals[1].phoneNumber"
            :required="campaignForm.campaignType === CampaignType.Individual"
          />
        </div>

        <div class="col-span-full">
          <label class="block text-sm font-medium leading-6 text-dark-blue">Your Cover photo*</label>
          <div class="mt-2 flex flex-col items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
  
            <div v-if="campaignForm.campaignAssets.length > 0" class="mt-4">
              <h3 class="text-dark-blue text-lg font-semibold mb-2">Uploaded Image Preview:</h3>
              <img :src="coverPhotoUrl()" alt="Uploaded Cover Photo" class="max-w-xs mx-auto mb-4" />
            </div>
  
            <div class="text-center">
              <div v-if="!campaignForm.campaignAssets.length" class="mt-4 flex justify-center items-center flex-col">
                <img src="/img/upload_icon.svg" alt="Upload Icon" class="mb-2">
                <label class="relative cursor-pointer rounded-md bg-white">
                  <span class="text-purple">Upload a file</span>
                  <input type="file" class="sr-only" @change="handleFileChange" accept="image/*" />
                </label>
                <p class="text-dark-blue text-sm mt-1">JPEG / PNG</p>
              </div>
              <span v-if="showErrors && campaignFields.errors.value['campaignAssets']" ref="coverPhotoTypeInput" class="text-red-500 text-sm mt-1.5">{{ campaignFields.errors.value['campaignAssets'] }}</span>
              <!--span v-if="!campaignForm.hasCoverPhoto" class="text-red-500 text-sm mt-1.5">{{ campaignFields.errors['coverPhoto'] }}</span-->
            </div>
          </div>
        </div>
  
        <div class="col-span-full">
          <label class="block text-sm font-medium leading-6 text-dark-blue">About This Campaign*</label>
          <div class="mt-2">
            <textarea v-model="campaignForm.story" required rows="4" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 outline-none" />
          </div>
          <span v-if="showErrors && campaignFields.errors.value['story']" class="text-red-500 text-sm mt-1">{{ campaignFields.errors.value['story'] }}</span>
        </div>
  
        <CalendarInput
          class="sm:col-span-3"
          :model-value="campaignForm.startDate"
          @update:model-value="campaignForm.startDate = $event"
          :error-message="showErrors ? campaignFields.errors.value['startDate'] : undefined"
          style-class="sm:col-span-3"
          label="Start Date"
          placeholder="YYYY/MM/dd"
        />
        <CalendarInput
          class="sm:col-span-3"
          :model-value="campaignForm.endDate"
          @update:model-value="campaignForm.endDate = $event"
          :error-message="showErrors ? campaignFields.errors.value['endDate'] : undefined"
          style-class="sm:col-span-3"
          label="End Date"
          placeholder="YYYY/MM/dd"
        />
  
        <fieldset class="col-span-full">
          <div class="space-y-3">
            
            <Checkbox
              :model-value="campaignForm.termsAndConditionsAccepted"
              @update:model-value="campaignForm.termsAndConditionsAccepted= !campaignForm.termsAndConditionsAccepted"
              style-class="relative flex gap-x-3"
              :error-message="showErrors ? campaignFields.errors.value['termsAndConditionsAccepted'] : undefined"
              required>
              
              <template v-slot:statement>
                <label class="font-medium text-dark-blue">
                  <p href="#">
                    I have received or provided the necessary consent to use the photos uploaded.
                  </p>
                </label>
              </template>
              
            </Checkbox>
            
            <Checkbox
              :model-value="campaignForm.privacyPolicy"
              @update:model-value="campaignForm.privacyPolicy = !campaignForm.privacyPolicy"
              style-class="relative flex gap-x-3"
              :error-message="showErrors ? campaignFields.errors.value['privacyPolicy'] : undefined"
              required>
              
              <template v-slot:statement>
                <label class="font-medium text-dark-blue">
                  <p href="#">
                    I accept the GetGo
                    <a class="text-light-blue underline" href="/docs/Privacy_Policy.pdf">Privacy Policy</a>
                    and
                    <a class="text-light-blue underline" href="/docs/T&C.pdf">Terms and Conditions</a>
                  </p>
                </label>
              </template>
              
            </Checkbox>
            
          </div>
        </fieldset>
        <slot name="actions"></slot>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import {useForm} from 'vee-validate';
import {PropType, reactive, ref, watch} from 'vue';
import {Tippy} from "vue-tippy";
import * as yup from 'yup';

import InputField from "@/components/Atomic/AQuarks/InputField.vue";

import {SchoolDto} from "@/areas/schools/dtos/schoolDto";
import {CampaignType} from "@/areas/campaigns/enums/campaignType";
import Dropdown from "@/components/Atomic/AQuarks/Dropdown.vue";
import {useSchoolStore} from "@/areas/schools/stores/schoolStore";
import SchoolSearch from "@/components/Atomic/BAtoms/SchoolSearch.vue";
import Checkbox from "@/components/Atomic/AQuarks/Checkbox.vue";
import CalendarInput from "@/components/Atomic/AQuarks/CalendarInput.vue";
import {CampaignDto} from "@/areas/campaigns/dtos/campaignDto";
import CurrencyInputField from "@/components/Atomic/AQuarks/CurrencyInputField.vue";
import {AssetType} from "@/areas/campaigns/enums/assetType";
import {CampaignAssetDto} from "@/areas/campaigns/dtos/campaignAssetDto";
import {IndividualType} from "@/areas/campaigns/enums/individualType";
import {IndividualDto} from "@/areas/campaigns/dtos/individualDto";
import {emailRegex, idRegex, phoneRegex} from "@/helpers/utilities/regex";
import Tooltip from "@/components/Atomic/AQuarks/Tooltip.vue";

const props = defineProps({
  showErrors: {
    type: Boolean,
    default: false,
  },
  campaign: {
    type: Object as PropType<CampaignDto>,
    required: true
  },
  schools: {
    type: Object as PropType<SchoolDto[]>,
    required: true,
  },
})

const filteredSchools = ref<SchoolDto[]>([])
const today = new Date()
today.setHours(0,0,0,0)
const resultSize = ref<number>(5)
const file = ref<File>()
let schoolSelected = false

let searchTerm = ''

const emit = defineEmits(['update', 'has-error', 'file-change'])

// Enums & dropdown values
const campaignTypes = Object.values(CampaignType) as CampaignType[]
const campaignAssetTypes = Object.values(AssetType)
const individualTypes = Object.values(IndividualType)
const gradeOptions = Array.from({ length: 12 }, (_, i) => 1 + i).reverse()

// Dropdown

// Props
const campaignForm = reactive(props.campaign)
const schools = ref(props.schools)

watch(() => campaignForm?.campaignType, (newValue) => {
  if (newValue === CampaignType.Individual && campaignForm?.individuals?.length === 0){
    campaignForm?.individuals.push({
      individualType: IndividualType.Student,
      firstName: '',
      lastName: '',
      email: '',
    } as IndividualDto)
    campaignForm?.individuals.push({
      individualType: IndividualType.Guardian,
      firstName: '',
      lastName: '',
      email: '',
    } as IndividualDto)
  } else {
    if (campaignForm){
      campaignForm.individuals = []
    }
  }
})

watch(() => props.campaign, (newValue) => {
  campaignForm.displayName = newValue?.displayName ?? ''
  if (newValue.targetAmount){
    campaignForm.targetAmount = newValue?.targetAmount
  }
  campaignForm.targetAmount = newValue?.targetAmount ?? undefined
  campaignForm.story = newValue?.story ?? ''
  campaignForm.campaignType = (newValue?.campaignType ?? '') as CampaignType
  campaignForm.startDate = newValue?.startDate ?? today.getDay()-1
  campaignForm.endDate = newValue?.endDate ?? today.getDay()-2
  campaignForm.termsAndConditionsAccepted = newValue?.termsAndConditionsAccepted ?? false
  campaignForm.privacyPolicy = newValue?.privacyPolicy ?? false
  
}, { immediate: true , deep: true })

const validationSchema = yup.object({
  displayName: yup.string()
    .required('Campaign Name is required'),
  school: yup.object({
    name: yup.string()
      .required('School is required'),
    guid: yup.string()
      .required('School is required')
  }),
  targetAmount: yup.number()
    .moreThan(0, 'Invalid Target amount')
    .required('Campaign Target Amount is required'),
  campaignType: yup.mixed<CampaignType>()
    .oneOf(campaignTypes, 'Campaign Type is required')
    .required('Campaign Type is required'),
  story: yup.string()
    .required('Campaign Story is required')
    .min(10, 'Story must be at least 10 character'),
  campaignAssets: yup.array()
    .default([])
    .of(yup.object({
      mimeType: yup.string(),
      assetType: yup.mixed().oneOf(campaignAssetTypes),
    }))
    .length(1, 'Cover Photo is required')
    .required('Cover Photo is required'),
  startDate: yup.date()
    .default(undefined)
    .typeError('Not Required type')
    .required('Start Date is required')
    .min(today, 'Start Date must be either today or later'),
  endDate: yup.date()
    .default(undefined)
    .required('End Date is required')
    .when('startDate', (startDate: Date | unknown, schema) => {
        return startDate ? schema.test({
          name: 'endDate',
          exclusive: false,
          params: {},
          message: 'End Date must be the same as or after Start Date',
          test: function (value, startDate) {
            return startDate.parent.startDate <= startDate.parent.endDate
          },
        }) : schema;
   }),
  individuals: yup.array()
      .of(
        yup.object({
          individualType: yup.mixed()
            .oneOf(individualTypes),
          firstName: yup.string().required("First Name is required"),
          lastName: yup.string().required("Last Name is required"),
          identificationNumber: yup.string()
            .when('individualType', {
              is: IndividualType.Student,
              then: schema => schema.required("Student Identification Number is required").matches(idRegex, 'Invalid ID Number'),
              otherwise: schema => schema.notRequired()
            }),
          email: yup.string()
              .when('individualType', {
                is: IndividualType.Student,
                then: schema => schema.notRequired(),
                otherwise: schema => schema.required("Parent/Guardian Email is required").matches(emailRegex, 'Invalid Email Address')
              }),
          phoneNumber: yup.string()
              .when('individualType', {
                is: IndividualType.Student,
                then: schema => schema.notRequired(),
                otherwise: schema => schema.required("Parent/Guardian Phone Number is required").matches(phoneRegex, 'South African Phone Number is required')
              }),
          schoolGrade: yup.number()
              .when('individualType', {
                is: IndividualType.Student,
                then: schema => schema.required("Student Grade is required"),
                otherwise: schema => schema.notRequired(),
              }),
        })
      ),
  termsAndConditionsAccepted: yup.boolean().isTrue('This is a required field'),
  privacyPolicy: yup.boolean().isTrue('Accept our terms to continue'),
})

const campaignFields = useForm({
  validationSchema: validationSchema,
  initialValues: campaignForm,
})

const coverPhotoUrl = () => {
  return campaignForm?.campaignAssets ? URL.createObjectURL(file.value!) : null
}

watch(() => campaignForm, async (newValue) => {
  campaignFields.resetForm({
    values: newValue,
    errors: {},
    touched: {}
  })
  
  emit('update', campaignForm)
  
  const validationResult = await campaignFields.validate()
  emit('has-error', !validationResult.valid)
}, {deep:true})

// Method to select a school from dropdown
const selectSchool = (school: SchoolDto) => {
  Object.assign(campaignForm?.school, school)
  filteredSchools.value = [];
  schoolSelected = true;
}

watch(() => props.schools, (newValue) => {
  schools.value = newValue
})

const handleFileChange = (event: Event) => {
  campaignForm?.campaignAssets.pop()
  const fileElement = event.target! as HTMLInputElement

  if (!fileElement.files || fileElement.files.length > 0){
    // TODO: Handle error
  }
  
  if (!['image/png', 'image/jpeg'].includes(fileElement.files![0].type)) {
    //formErrors.coverPhotoTypeError = 'Please select a PNG or JPEG file for the cover photo';
    return;
  }

  // TODO: This needs to be handles on upload instead
  file.value = fileElement.files![0]
  let asset = {
    mimeType: file.value!.type,
    assetType: AssetType.CoverPhoto,
  } as CampaignAssetDto

  campaignForm.campaignAssets.push(asset as CampaignAssetDto)
  
  emit('file-change', file.value)
}

const filterSchools = async (newInput: string) => {
  searchTerm = newInput
  
  // Reset school select
  if (campaignForm?.school){
    campaignForm.school.guid = ''
    
  }
  
  if (!schools.value){
    return
  }
  filteredSchools.value = schools.value!.filter(school => school.name?.toLowerCase().includes(searchTerm.toLowerCase())).splice(0, resultSize.value)
}
</script>
