import axios, { AxiosInstance } from 'axios';
import keycloak from './keycloak';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.request.use(async config => {
    if (!config.headers.skipAuthHeader) {
        if(keycloak.authenticated) {
            await keycloak.updateToken(5).catch(async function() {
                await keycloak.login();
            });

            config.headers.Authorization = `Bearer ${keycloak.token}`;
        }
    } else {
        delete config.headers.skipAuthHeader;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;