<template>
  <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div class="sm:flex sm:justify-center">
            <div class="mt-3 sm:mt-0">
              <h3 class="text-base font-medium leading-6 text-dark-blue">Sure you want to delete?</h3>
              <div class="mt-3 mb-4">
                <p class="text-sm text-[#989B9E]">Are you sure you want to delete {{ userToDelete.firstName }}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:flex sm:justify-center gap-x-4">
            <Button buttonText="No, Cancel" @click="toggleDeleteModal" class="text-dark-blue border border-dark-blue py-2.5 px-4 bg-transparent rounded shadow-md"/>
            <Button buttonText="Yes, Delete" @click="removeForm" class="bg-red text-white bg-orange-red py-2.5 px-4 rounded shadow-md"/>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import Button from '../AQuarks/Button.vue'

export default {
  name: 'UsersDeleteModal',
  components: {
    Button
  },
  props: {
    userToDelete: Object
  },
  
  methods: {
    toggleDeleteModal() {
      this.$emit('toggle-delete-modal');
    },
    removeForm() {
      this.$emit('delete-user')
    }
  }
}
</script>