import { CampaignState } from "@/areas/campaigns/enums/campaignState";

export const getEntityStateColour = (entityState: CampaignState): string => {
  switch (entityState) {
    case 'Pending':
      return '#22B863';
    case 'Paused':
      return '#35A6D8';
    case 'Deleted':
    case 'Declined':
    case 'Active':
    case 'Completed':
      return '#FB4B1E';
    default:
      return '#172850';
  }
}

export const getStatusClass = (status: CampaignState): string => {
  switch (status) {
    case 'Active':
      return 'bg-green text-white border-none';
    case 'Pending':
    case 'Completed':
      return 'bg-purple text-white border-none';
    case 'Paused':
      return 'bg-light-blue text-white border-none';
    case 'Declined':
      return 'bg-orange-red text-white border-none';
    default:
      return 'bg-dark-blue text-white border border-dark-blue';
  }
}

export const compareCampaignEntityState = (a: CampaignState, b: CampaignState): number => {
  const customSortOrder = {
    [CampaignState.Pending]: 1,
    [CampaignState.Paused]: 2,
    [CampaignState.Active]: 3,
    [CampaignState.Completed]: 4,
    [CampaignState.Declined]: 5,
    [CampaignState.Deleted]: 6,
  }

  return customSortOrder[a] - customSortOrder[b]
}
