<template>
  <tippy :content="content">
    <img style="'position: relative; display: inline-block; vertical-align: middle;'" src="/img/info.svg" alt="Info">
  </tippy>
</template>

<script setup lang="ts">
import {Tippy} from "vue-tippy";
import {ref} from "vue";

const props = defineProps({
  content: {
    type: String,
    required: true,
  }
})

const content = ref(props.content)

</script>