<template>
  <Sidebar />
  <div class="flex min-h-full flex-col justify-center pt-4 pb-8 mx-3 mt-10 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-2xl sm:text-3xl font-bold leading-9 tracking-tight text-dark-blue">Your Donation Was Successful</h2>
      <p class="text-center text-dark-blue text-sm mt-2">Thank you {{ firstName }} for your generous donation!</p>
    </div>
    <div class="sm:mx-auto sm:w-full">
      <div class="px-4 py-8 sm:px-12">
        <div class="flex justify-center">
          <img src="/img/campaign_created_success.svg" alt="Success Image">
        </div>
        <div class="mt-8 flex justify-center">
          <button @click="continueToCampaigns" class="py-2.5 px-28 rounded-lg shadow-lg bg-light-blue text-white">Continue</button>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Sidebar from "@/components/Layouts/Sidebar.vue";
import Footer from "@/components/Atomic/AQuarks/Footer.vue";

export default defineComponent({
  components: {
    Sidebar,
    Footer
  },

  setup() {
    const router = useRouter();
    const firstName = ref<string>('');

    const continueToCampaigns = () => {
      const postPaymentRedirectPath = localStorage.getItem('postPaymentRedirectPath') || '/donate';
      router.push(postPaymentRedirectPath);

      localStorage.removeItem('postPaymentRedirectPath');
    };

    onMounted(() => {
      firstName.value = localStorage.getItem('firstName') || '';
    });

    return {
      continueToCampaigns,
      firstName,
    }
  }
});
</script>
