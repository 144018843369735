<template>
  <div class="min-h-screen">
    <Sidebar />
    <div class="bg-green pb-10 sm:pb-10">
      <main class="isolate font-roboto">
        <div class="max-w-5xl mx-auto text-center px-6 sm:mt-0 lg:px-8 pt-12 sm:pt-20">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-5xl text-left lg:text-center">HOW GETGO WORKS</h2>
          <div class="lg:w-full lg:flex-auto text-left lg:text-center">
            <h2 class="text-xl font-normal tracking-tight text-dark-blue sm:text-3xl mt-2"><br>{{ 'Create a campaign or donate to one' }}</h2>
            <div class="mt-8 text-sm sm:text-base leading-7 text-white font-bold">
              <span>
                {{ 'Simply create a fundraising campaign and share it with your friends, family and network via your social media' }}
              <span class="hidden lg:block -mt-5"><br></span>
                {{ 'channels, email and WhatsApp. Individuals, communities and businesses can make donations to a campaign online' }}
              <div class="hidden lg:block -mt-5"><br></div> 
                {{ 'using a credit card, debit card or by instant EFT.' }}
              <br>
              <br>
                {{ 'Donations made on the platform are disbursed directly to schools.' }}
              </span>
            </div>
          </div>
          <div v-for="(section, index) in sections" :key="index" class="mt-6 sm:mt-10">
            <div class="lg:w-full lg:flex-auto text-left lg:text-center">
              <h2 class="text-xl font-semibold tracking-tight text-dark-blue sm:text-3xl">{{ section.title }}</h2>
              <div class="my-4 text-sm sm:text-base leading-7">
                <ul>
                  <li v-for="(item, itemIndex) in section.content" :key="itemIndex" class="relative inline-flex lg:block text-white" :class="{ 'mt-1': itemIndex !== 0 }">
                    <span>• </span><span class="ml-1.5 lg:ml-0.5" v-html="highlightText(item)"></span>
                    <div v-if="itemIndex === section.content.length - 1 && index !== sections.length - 1" class="absolute left-1/2 -translate-x-1/2 bg-yellow-dots rounded-full h-3 w-3 hidden lg:block"
                      :class="{
                        'translate-y-1.5': true,
                        'sm:translate-y-[15px]': true
                    }"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Atomic/AQuarks/Footer.vue'
import Sidebar from '@/components/Layouts/Sidebar.vue'

export default {
  name: 'HowItWorks',
  components: {
    Footer,
    Sidebar,
  },
  data() {
    return {
      sections: [
        {
          title: 'Who can create a campaign?',
          content: [
            'Anyone can create a campaign for any of our GetGo registered schools.'
          ],
        },
        {
          title: 'How do I know which schools are registered?',
          content: [
            'Search for schools at “Donate Now” → “Search School”.',
            'If you are unable to find your school, it means your school is not registered yet. Please inform us here, so we can get you fundraising soon.'
          ],
        },
        {
          title: 'Creating and managing a campaign:',
          content: [
            'Click “Create Campaign” and start filling in your campaign details.',
            'Once you have finished creating your campaign, it will be sent to your school for review and approval. We will email you once your campaign is approved and your campaign will go live!',
            'Engage your community and rally donors by sharing the campaign on your social media pages, email and WhatsApp.',
            'Celebrate your wins and witness the collective impact of your community as you reach your campaign goals.',
          ],
        },
        {
          title: 'Receiving donations',
          content: [
            'GetGo registered schools receive donations for their campaigns once the campaign ends.',
            'Campaigns end when they reach their end date or have reached the fundraising goal.',
            'Administration and transaction costs are deducted from the amount fundraised before donations are disbursed to the school.<br> Please see <a class="text-dark-blue underline" href="/faq">FAQs</a> for more information on the fees and payouts.',
          ],
        },
        {
          title: 'How do I make a donation?',
          content: [
            'Donating is simple at GetGo.',
            'Click “Donate Now” to find a campaign or a school to support.',
            'Enter the amount you would like to donate and follow the prompts to finalise your donation.',
            'A receipt for your donation will be sent to your email address.',
            'We will notify you at tax year-end when your Section 18A tax certificate is ready for download. You will require a GetGo profile in <div class="hidden lg:block -mt-5"><br></div> order to download your tax certificate. Please see our <a class="text-dark-blue underline" href="/faq">FAQs</a> for more information on creating a profile and downloading your Section 18A tax certificate.',
          ],
        },
      ],
    };
  },

  methods: {
    highlightText(item) {
      let replacedText = item.replace(/here/g, (match, offset) => {
        if (offset === item.indexOf("here")) {
          return '<a href="/contact-us" class="underline text-dark-blue">here</a>';
        } else {
          return '<a href="/register/school" class="underline text-dark-blue">here</a>';
        }
      });
      
      replacedText = replacedText.replace(/Create Campaign/g, '<a href="/add-campaign" class="underline text-dark-blue">Create Campaign</a>');
      replacedText = replacedText.replace(/Thank You/g, '<span class="font-bold">Thank You</span>');
      replacedText = replacedText.replace(/My Donations/g, '<span class="italic">My Donations</span>');
      return replacedText;
    },
  },

};
</script>
