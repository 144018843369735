<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center p-4">
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <div class="relative mx-auto z-10 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-2/5 sm:p-6">
      <h1 class="text-dark-blue text-2xl font-roboto text-center mb-3">
        Please Complete Account Information
      </h1>
      <p class="text-dark-blue text-base text-center">
        Please complete your account information required for the Section 18A tax certificate.
      </p>
      <div class="flex justify-center gap-x-6 mt-6">
        <button @click="emits('close')" class="flex items-center justify-center w-28 py-2 rounded-md shadow-md bg-transparent text-dark-blue border border-dark-blue text-center">
          Cancel
        </button>
        <a @click="emits('continue')" class="flex items-center justify-center w-28 py-2 rounded-md shadow-md bg-light-blue text-white cursor-pointer text-center">
          Continue
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent } from "vue"

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  }
})

const emits = defineEmits(['close', 'continue'])
</script>
