<template>
  <Sidebar />

  <div class="font-roboto min-h-screen">
    <!-- Hero Section -->
    <div class="overflow-hidden relative bg-white">
      <div class="relative">
        <video id="bannerAnimation" playsinline muted class="w-full h-full object-cover transition-opacity duration-500 ease-in-out" @ended="onVideoEnded">
          <source src="/video/Getgo-Animated-Banner-1920.webm" type="video/webm">
          Your browser does not support the video tag.
        </video>
        <img id="bannerImage" :class="{'opacity-100': isVideoEnded, 'opacity-0': !isVideoEnded}" 
             class="w-full h-full object-cover transition-opacity duration-500 ease-in-out absolute top-0 left-0" 
             src="/img/GetGo_Banner_High_Res.jpg" 
             alt="GetGo Banner"
        >
      </div>
      <div class="flex justify-center gap-x-3 sm:gap-x-5 my-4 sm:my-8 px-3">
        <a href="/add-campaign" class="text-white text-sm sm:text-lg tracking-wide uppercase font-semibold sm:font-bold leading-6 text-center bg-pink w-full sm:w-72 py-4 rounded-md">
          Create Campaign
        </a>
        <a href="/donate" class="text-dark-blue text-sm sm:text-lg tracking-wide uppercase font-semibold sm:font-bold leading-6 text-center bg-bright-green w-full sm:w-72 py-4 rounded-md">
          Donate Now
        </a>
      </div>
    </div>

    <!-- About Section -->
    <div>
      <div class="relative isolate mt-0 sm:mt-0 bg-navy-blue py-6 sm:py-20">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <!-- Top Arrow -->
          <div class="absolute -top-3 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-0 h-0 border-x-[20px] border-x-transparent border-t-[20px] border-t-white"></div>
          
          <div class="w-full text-center mt-4 sm:mt-0">
            <p class="text-white text-xl sm:text-4xl font-bold tracking-tight">A COMMUNITY UNITED BEHIND</p>
            <p class="text-white text-xl sm:text-4xl font-bold tracking-tight mt-1">QUALITY EDUCATION</p>
          </div>
          <div class="w-full text-center">
            <p class="mt-7 sm:mt-9 font-bold text-sm sm:text-lg leading-7 text-white">
              Our mission is to empower everyone to shape the future of South Africa's children
            </p>
            <p class="mt-2.5 font-bold text-sm sm:text-lg leading-7 text-white">
              Uniting as one South African family to provide quality education for all of South Africa's children
            </p>
          </div>
          <div class="mt-7 pb-4 sm:pb-0 sm:mt-10 flex justify-center">
            <a href="/about" class="text-white uppercase bg-sky-blue font-normal py-3 px-8 rounded-full cursor-pointer">I want to know more</a>
          </div>
          <!-- Bottom Arrow -->
          <div class="absolute -bottom-2 sm:-bottom-2.5 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-0 h-0 border-x-[20px] border-x-transparent border-t-[20px] border-t-navy-blue"></div>
        </div>
      </div>

      <!-- How It Works Section -->
      <div class="relative">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 pt-0 sm:pt-4">
          <div class="flex max-w-2xl flex-col gap-16 px-6 py-9 sm:p-8 lg:mx-0 sm:max-w-none lg:flex-row lg:items-center sm:py-16 xl:gap-x-20 xl:px-20">
            <div class="w-full">
              <h2 class="text-3xl font-bold tracking-tight text-dark-blue sm:text-[45px] flex justify-center items-center gap-x-2 sm:gap-x-3 uppercase">
                How <img class="h-[27px] sm:h-[45px]" src="/img/GetGo_How_It_Works_Logo.png" alt=""> Works
              </h2>
              <div class="flex justify-center mt-8 sm:mt-12 mr-6">
                <img src="/img/Infographic.png" alt="">
              </div>
              <div class="mt-12 flex justify-center">
                <a href="/how-it-works" class="text-white uppercase bg-[#2DA5D9] font-normal py-3 px-8 rounded-full cursor-pointer">I want to know more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup lang="ts">
import Footer from '@/components/Atomic/AQuarks/Footer.vue'
import Sidebar from '@/components/Layouts/Sidebar.vue'
import { onMounted, ref } from 'vue';

const isVideoEnded = ref(false);

const onVideoEnded = () => {
  isVideoEnded.value = true;
};

onMounted(() => {
  const video = document.getElementById('bannerAnimation') as HTMLVideoElement;
  
  video.addEventListener('canplaythrough', () => {
    video.play();
  });

  video.addEventListener('ended', onVideoEnded);
});
</script>