import {defineStore} from 'pinia';
import {SchoolProxy} from '@/areas/schools/proxies/schoolProxy';
import { OperationResult } from '@/helpers/responses/models/operationResult';
import { SchoolRequestDto } from '../dtos/schoolRequestDto';
import { SchoolStateEnum } from '../enums/schoolStateEnum';
import { SchoolDto } from '../dtos/schoolDto';
import { SchoolSearchResultDto } from '../dtos/schoolSearchResultDto';

export interface SchoolStoreState {
    updated_at: Date
    proxy: SchoolProxy
    schools: SchoolDto[] | null
    school: SchoolDto | null
    result: OperationResult<any> | null
    states: SchoolStateEnum[] | null
    isLoading: boolean
}
export const useSchoolStore = defineStore({
    id: "school",
    state: (): SchoolStoreState => ({
        updated_at: new Date(),
        proxy: new SchoolProxy(),
        schools: null as SchoolDto[] | null,
        school: null as SchoolDto | null,
        result: null as OperationResult<any> | null,
        states: null as SchoolStateEnum[] | null,
        isLoading: false
    }),
    actions: {
        async init(states?: SchoolStateEnum[]){
            this.schools = []
            this.school = null
            this.result = null
            this.states = states ?? null 
            
            if (this.states?.length){
                await this.loadingWrapper(this.fetchAllSchools(this.states))
            }
        },
        async fetchAllSchools(states?: SchoolStateEnum[], searchTerm?: string): Promise<OperationResult<SchoolDto[]>> {
            this.result = await this.loadingWrapper(this.proxy.fetchAllSchools(states, searchTerm))
            
            if (this.result.isSuccessful){
                this.schools = this.result.content! as SchoolDto[]
                this.states = states ?? null
            }
            
            return this.result
        },
        
        async searchSchool(searchTerm: string): Promise<OperationResult<SchoolSearchResultDto[]>> {
            return await this.proxy.searchSchool(searchTerm)
        },

        async schoolInfoRegistration(schoolRequestDto: SchoolRequestDto): Promise<OperationResult<SchoolRequestDto>> {
            return await this.proxy.registerMySchoolRequest(schoolRequestDto)
        },

        async schoolByGuid(schoolGuid: string) : Promise<OperationResult<SchoolDto>> {
            this.result = await this.loadingWrapper(this.proxy.schoolByGuid(schoolGuid))
            
            if (this.result.isSuccessful){
                this.school = this.result.content! as SchoolDto
            }
            
            return this.result
        },
        
        async updateState(schoolGuid: string, newState: SchoolStateEnum): Promise<OperationResult<SchoolDto>> {
            return await this.proxy.updateState(schoolGuid, newState)
        },

        async schoolByRequestor(schoolGuid: string): Promise<OperationResult<SchoolRequestDto>> {
            return await this.proxy.schoolByRequestor(schoolGuid)
        },

        async updateSchoolDetails(schoolGuid: string, updateDto: SchoolDto): Promise<OperationResult<SchoolDto>> {
            return await this.proxy.updateSchoolDetails(schoolGuid, updateDto)
        },
        async searchSchoolWithStates(states?: SchoolStateEnum[], searchTerm?: string) : Promise<OperationResult<SchoolDto[]>>{
            return await this.proxy.searchSchoolWithStates(states, searchTerm)
            
        },
        
        async loadingWrapper<T>(func: Promise<OperationResult<T>>): Promise<OperationResult<T>> {
            this.isLoading = true
            this.result = await func
            this.isLoading = false
            return this.result
        },
    }
})