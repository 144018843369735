<template>
  <div class="flex justify-between">
    <ul class="my-2 gap-8 display: flex">
      <li class="text-[#27AE60]">
        &#8226; R {{ formatAmount(cumulativeDonations ?? 0) }} funded
      </li>
      <li class="text-[#3498DB]">
        &#8226; R {{ formatAmount(targetAmount - (cumulativeDonations ?? 0)) }} funding left
      </li>
    </ul>
    <p class="text-base text-green mt-2">{{ Math.round(((cumulativeDonations ?? 0) / targetAmount ?? 1 ) * 100) }}%</p>
  </div>
  <div class="w-full bg-gray-200 rounded-full h-2.5">
    <div class="bg-green h-2.5 rounded-full" :style="{ width: ((cumulativeDonations ?? 0) / targetAmount ?? 1) * 100 + '%' }"></div>
  </div>
</template>

<script setup lang="ts">

import {formatAmount} from "@/helpers/utilities/dataFormats";
import {ref} from "vue";

const props = defineProps({
  cumulativeDonations: {
    type: Number,
    required: true,
  },
  targetAmount: {
    type: Number,
    required: true,
  }
})

const cumulativeDonations = ref(props.cumulativeDonations)
const targetAmount = ref(props.targetAmount)
</script>