<template>
  <div class="flex flex-col">
    <div class="w-full">
      <img src="/img/Long_Pattern_Footer.jpg" alt="Pattern">
    </div>
    <footer class="bg-dark-blue font-roboto">
      <div class="mx-auto max-w-7xl px-6 pb-6 pt-16 sm:pt-24 lg:px-8 lg:pt-16">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <div class="space-y-6 text-center sm:text-left">
            <img class="h-16 mx-auto sm:mx-0 hidden sm:block" src="/img/logos/GetGo_Updated_Logo_Footer.png" alt="Getgo Logo">
            <div class="hidden sm:flex justify-center sm:justify-start space-x-3">
              <a href="https://www.instagram.com/getgofund/?next=%2F" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
                <img class="h-8 w-8" src="/img/Icons/Insta_Footer.svg" alt="Instagram">
              </a>
              <a href="https://www.facebook.com/people/GetGo-Fund/61556829002719/" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
                 <img class="h-8 w-8" src="/img/Icons/Facebook_Footer.svg" alt="Facebook">
              </a>
              <a href="https://twitter.com/GetGoFund" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
                <img class="h-8 w-8" src="/img/Icons/X_Footer.svg" alt="X">
              </a>
              <a href="https://www.linkedin.com/in/getgo-educational-fund-9143b2301/" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
                <img class="h-8 w-8" src="/img/Icons/LinkedIn_Footer.svg" alt="LinkedIn">
              </a>
            </div>
          </div>
          <div class="mt-0 sm:mt-16 grid grid-cols-1 sm:grid-cols-2 gap-8 xl:col-span-2 xl:mt-0 text-center sm:text-left">
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-lg font-semibold leading-6 text-white">Get Involved</h3>
                <ul role="list" class="mt-7 space-y-4 text-white">
                  <li v-for="(item, index) in getInvolvedLinks" :key="index">
                    <a :href="item.href" :target="(item.name === 'Terms and Conditions' || item.name === 'Privacy Policy') ? '_blank' : null" class="text-sm leading-6 hover:text-gray-300">{{item.name}}</a>
                  </li>
                </ul>
              </div>
              <div class="mt-10 md:mt-0">
                <h3 class="text-lg font-semibold leading-6 text-white italic sm:not-italic">Powered By</h3>
                <ul role="list" class="mt-4 space-y-4">
                  <li>
                    <a href="https://maharishiinstitute.org/" target="_blank">
                      <img class="h-24 mx-auto sm:mx-0 -mt-4 sm:-mt-0" src="/img/Maharishi_Footer.svg" alt="Maharishi">
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div class="mt-0 sm:mt-10 md:mt-0">
                <h3 class="text-lg font-semibold leading-6 text-white italic sm:not-italic">Affiliated With</h3>
                <ul role="list" class="mt-8 space-y-8">
                  <li>
                    <a href="https://feenix.org/" target="_blank">
                      <img class="h-8 mx-auto sm:mx-0 -mt-4 sm:-mt-0 mb-8 sm:mb-0" src="/img/Feenix_logo.svg" alt="Feenix">
                    </a>
                  </li>
                  <li>
                    <a href="https://wafunda.com/" target="_blank">
                      <img class="h-[18px] mx-auto sm:mx-0 -mt-4 sm:-mt-0 mb-8 sm:mb-0" src="/img/logos/wafunda.png" alt="Wafunda">
                    </a>
                  </li>
                </ul>
              </div>

              <hr class="bg-white w-80 mx-auto mb-7 sm:hidden">
              <div class="mt-0 sm:mt-10 md:mt-0">
                <h3 class="text-lg font-semibold leading-6 text-white">Contact</h3>
                <ul role="list" class="mt-6">
                  <li>
                    <a href="mailto:support@getgofund.org" class="text-sm text-white hover:text-gray-300 whitespace-nowrap sm:whitespace-normal xl:whitespace-nowrap">Email: support@getgofund.org</a>
                  </li>
                  <li class="pt-2">
                    <p class="text-sm text-white hover:text-gray-300">Phone: +27 (0)21 007 5786</p>
                  </li>
                  <li class="mt-8">
                    <p class="text-white text-lg font-semibold mb-6">About Us</p>
                    <p class="text-sm leading-6 text-white">GetGo Fund NPC: 2023/244856/08</p>
<!--                    <p class="text-sm leading-6 text-white"></p>-->
                    <p class="text-sm leading-6 text-white mt-1">Support Hours:</p>
                    <p class="text-sm leading-6 text-white whitespace-nowrap sm:whitespace-normal xl:whitespace-nowrap">Monday to Friday, 9am to 5pm</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex sm:hidden justify-center sm:justify-start space-x-4 mt-10">
            <a href="https://www.instagram.com/getgofund/?next=%2F" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
              <img class="h-6 w-6" src="/img/Icons/Insta_Footer.svg" alt="Instagram">
            </a>
            <a href="https://www.facebook.com/people/GetGo-Fund/61556829002719/" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
              <img class="h-6 w-6" src="/img/Icons/Facebook_Footer.svg" alt="Facebook">
            </a>
            <a href="https://twitter.com/GetGoFund" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
              <img class="h-6 w-6" src="/img/Icons/X_Footer.svg" alt="X">
            </a>
            <a href="https://www.linkedin.com/in/getgo-educational-fund-9143b2301/" target="_blank" class="bg-white p-2 rounded-full text-dark-blue">
              <img class="h-6 w-6" src="/img/Icons/LinkedIn_Footer.svg" alt="LinkedIn">
            </a>
          </div>
        </div>
        <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 hidden sm:block">
          <a href="https://wafunda.com/" target="_blank" class="text-xs leading-5 text-white hover:text-gray-300">{{ currentYear }} Built By WaFunda</a>
        </div>
      </div>
    </footer>
  </div> 
</template>

<script lang="ts">
export default {
  data() {
    return {
      getInvolvedLinks: [
        { name: 'Donate Now', href: '/donate' },
        { name: 'Create Campaign', href: '/add-campaign'},
        { name: 'Register My School', href: '/register/school' },
        { name: 'FAQs', href: '/faq'},
        { name: 'How It Works', href: '/how-it-works' },
        { name: 'About Us', href: '/about' },
        { name: 'Terms and Conditions', href: '/docs/T&C.pdf' },
        { name: 'Privacy Policy', href: '/docs/Privacy_Policy.pdf' }
      ],
      currentYear: new Date().getFullYear(),
    }
  }  
}
</script>