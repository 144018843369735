<template>
  <div :class="styleClass">
    <Button :class="modelValue === 'All' ? getStatusClass(selectAllOption) : 'bg-transparent text-dark-blue border border-dark-blue'" :value="toTitleCase(selectAllOption)" class="px-10 py-1.5 rounded-md shadow-md" @click="modelValue = selectAllOption">
      {{ selectAllOption }}
    </Button>
    <Button :class="modelValue === option ? getStatusClass(option) : 'border text-dark-blue border-dark-blue'" v-for="option in typeOptions" :value="toTitleCase(option)" class="px-10 py-1.5 rounded-md shadow-md" @click="modelValue = option">
      {{ toTitleCase(option) }}
    </Button>
  </div>
</template>

<script setup lang="ts">

import {onMounted, PropType, ref, watch} from "vue";
import {toTitleCase} from "@/helpers/utilities/utility";
import {getEntityStateColour, getStatusClass} from "@/components/Atomic/BAtoms/schools/SchoolStateHelpers"

const props = defineProps({
  typeOptions: Array as PropType<String[]>,
  modelValue: {
    type: String,
  },
  styleClass: String,
})

const emit = defineEmits(['update'])

const modelValue = ref(props.modelValue)
const typeOptions = ref(props.typeOptions)

const selectAllOption = ref<string>('All')

watch(() => modelValue.value, (newValue) => {
  if (newValue === ''){
    modelValue.value = selectAllOption.value
  }
  if (newValue === selectAllOption.value){
    emit('update')
    return
  }
  emit('update', newValue)
}, {immediate: true})

</script>