<template>
  <AdminSidebar :showSearchField="showSearchField" :pageTitle="pageTitle"/>

  <div class="font-roboto lg:pl-72 pb-16">
    <div v-if="!showSchoolDetails" class="px-4 sm:px-6 lg:px-8 mt-6 mx-8 border border-gray-900/5 rounded-md shadow-sm"> 
      <div class="flex justify-between gap-x-4 mt-8">
        <div class="flex justify-start">
          <form class="relative" action="#" method="GET">
            <label for="search-field" class="sr-only">Search</label>
            <img class="w-5 py-3 h-full absolute left-1" src="/img/Icons/Magnifying_glass.svg" alt="Search Icon">
            <input v-model="searchTerm" id="search-field" class="h-full w-80 border border-dark-blue rounded-md py-3 pl-8 pr-0 text-dark-blue placeholder:text-gray-400 sm:text-sm outline-none" placeholder="Search School Name...">
          </form>
        </div>
        <SchoolStateFilterButtons
          @update="currentFilter = $event"
          :style-class="'flex justify-end gap-x-4'"
          :type-options="schoolStateOptions"
          :model-value="currentFilter"/>
      </div>

      <div class="-mx-4 mt-8 sm:-mx-0">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-dark-blue sm:pl-0 flex items-center">
                School Name
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-dark-blue sm:table-cell">
                <div class="flex items-center">
                  Town
                </div>
              </th>
              <!--th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-dark-blue">Total Campaigns</th-->
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark-blue lg:table-cell">School Status</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(school, index) in paginatedSchools" :key="school.guid">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ school.name }}</td>
                <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ school.address.city }}</td>
                <!--td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">0</td-->
                <td class="hidden whitespace-nowrap px-3 py-4 text-sm lg:table-cell">
                <span class="px-2.5 py-1.5 rounded-md" :class="getStatusClass(school.entityState)">
                  {{ school.entityState }}
                </span>
                </td>
                <!-- Kebab Menu -->
                <td class="whitespace-nowrap py-4 text-sm font-medium sm:pr-0">
                  <a :href="`#/edit/${school.guid}`" class="relative inline-block">
                    <img src="/img/Icons/kebab_menu.svg" id="kebab" alt="Menu" @click="toggleKebabMenu(school)" class="cursor-pointer">
                    <span class="sr-only">, {{ school.name }}</span>
                    <div v-if="school.showKebabMenu" class="absolute right-0 top-full w-40 z-10 mt-2 bg-white border border-gray-300 rounded-md shadow-md">
                      <div @click="showSchoolDetailsPage(school)" class="cursor-pointer px-3 py-2 hover:bg-[#DBEAFE]">
                        <a href="#">School Details</a>
                      </div>
                    </div>
                  </a>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- School Details Page -->
    <div v-if="showSchoolDetails && selectedSchool" class="mt-6 mx-8">
      <!-- Requestor's Information -->
      <div class="flex justify-between mt-4">
        <p class="text-base text-dark-blue">Status: 
          <span :style="{ color: getEntityStateColour(selectedSchool.entityState ?? '') }">{{ selectedSchool ? selectedSchool.entityState : 'Loading...' }}</span> 
        </p>

        <h1 class="text-2xl text-dark-blue text-center font-bold">Requestor's Information</h1>
        <div class="flex justify-end gap-x-4" :class="{ 'invisible': !(selectedSchool && selectedSchool.entityState === 'Requested') }">
          <Button 
            buttonText="Approve" 
            @click="showApproveModal = true;"
            :class="{'opacity-50 cursor-not-allowed': !isReadyForApproval}"
            :disabled="!isReadyForApproval"
            class="px-10 py-1.5 bg-green text-white rounded-md shadow-md" 
          />
          <Button 
            buttonText="Decline" 
            @click="showDeclineModal = true" 
            class="px-10 py-1.5 bg-orange-red text-white rounded-md shadow-md" 
          />
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 w-full">
        <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-3 mx-auto w-3/5">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">First name</label>
                <div class="mt-2">
                  <input v-if="userDto" v-model="userDto.firstName" disabled type="text" placeholder="First Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"/>
                </div>
              </div>

              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Last Name</label>
                <div class="mt-2">
                  <input v-if="userDto" v-model="userDto.lastName" disabled type="text" placeholder="Last Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"/>
                </div>
              </div>

              <div class="sm:col-span-full">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Email</label>
                <div class="mt-2">
                  <input v-if="userDto" v-model="userDto.email" disabled type="email" placeholder="Email" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"/>
                </div>
              </div>

              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Phone Number</label>
                <div class="mt-2">
                  <input :value="requestorNumber" disabled type="tel" placeholder="Phone Number" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"/>
                </div>
              </div>

              <Dropdown
                  class="sm:col-span-3 pointer-events-none opacity-60"
                  label="Requestor's Role"
                  placeholder="Select Role"
                  :options="requestorTypeOptions"
                  v-model="role"/>
            </div>
          </div>
        </form>
      </div>


      <!-- Requested School's Information -->
      <div class="flex justify-center mt-16">
        <p class="text-base text-dark-blue invisible">Status: 
          <span class="text-purple">Requested</span> 
        </p>
        <h1 class="text-2xl text-dark-blue text-center font-bold">Requested School's Information</h1>
        <div class="flex justify-end gap-x-4">
          <Button buttonText="Approved" class="px-10 py-1.5 bg-green text-white rounded-md shadow-md invisible" @click="filterSchools('Approved')"/>
          <Button buttonText="Decline" class="px-10 py-1.5 bg-orange-red text-white rounded-md shadow-md invisible" @click="filterSchools('Rejected')"/>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-3 mx-auto w-3/5">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">School Name*</label>
                <div class="mt-2">
                  <input v-model="schoolDetails.name" type="text" placeholder="School Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" required/>
                </div>
              </div>

              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">School EMIS Number*</label>
                <div class="mt-2">
                  <input v-model="schoolDetails.emis" type="text" placeholder="School EMIS Number" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" required/>
                </div>
              </div>

              <div class="sm:col-span-full">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Street Address*</label>
                <div class="mt-2">
                  <input v-model="schoolDetails.address.street" type="text" placeholder="Street Address" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"/>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Town*</label>
                <div class="mt-2">
                  <input v-model="schoolDetails.address.city" type="text" placeholder="Town" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" required/>
                </div>
              </div>

              <Dropdown
                  class="sm:col-span-2"
                  label="Province*"
                  placeholder="Select Province"
                  :options="provinceOptions"
                  v-model="schoolDetails.address.province!"/>

              <div class="sm:col-span-2">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Postal Code*</label>
                <div class="mt-2">
                  <input v-model="schoolDetails.address.postalCode" type="text" placeholder="Postal Code" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none"/>
                </div>
              </div>

              <Dropdown
                  class="sm:col-span-full"
                  label="Public or Private School"
                  placeholder="Select Option"
                  :options="schoolTypeOptions"
                  v-model="schoolDetails.schoolType"/>
            </div>
          </div>
        </form>
      </div>


      <!-- Contact At School -->
      <div class="flex justify-center mt-16">
        <p class="text-base text-dark-blue invisible">Status: 
          <span class="text-purple">Requested</span> 
        </p>
        <h1 class="text-2xl text-dark-blue text-center font-bold">Contact At School</h1>
        <div class="flex justify-end gap-x-4">
          <Button buttonText="Approved" class="px-10 py-1.5 bg-green text-white rounded-md shadow-md invisible" @click="filterSchools('Approved')"/>
          <Button buttonText="Decline" class="px-10 py-1.5 bg-orange-red text-white rounded-md shadow-md invisible" @click="filterSchools('Rejected')"/>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-3 mx-auto w-3/5">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Contact Person*</label>
                <div class="mt-2">
                  <input v-model="schoolDetails.contactPersonName" type="text" placeholder="Contact Person" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" required/>
                </div>
              </div>

              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Phone Number*</label>
                <div class="mt-2">
                  <input v-model="schoolDetails.contactPersonDetails" type="text" placeholder="Phone Number" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" required/>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>


      <!-- School Bank Details -->
      <div class="flex justify-center mt-16">
        <p class="text-base text-dark-blue invisible">Status: 
          <span class="text-purple">Requested</span> 
        </p>
        <h1 class="text-2xl text-dark-blue text-center font-bold">School Bank Details</h1>
        <div class="flex justify-end gap-x-4">
          <Button buttonText="Approved" class="px-10 py-1.5 bg-green text-white rounded-md shadow-md invisible" @click="filterSchools('Approved')"/>
          <Button buttonText="Decline" class="px-10 py-1.5 bg-orange-red text-white rounded-md shadow-md invisible" @click="filterSchools('Rejected')"/>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <BankDetailsForm
            @update="schoolBankDetails = $event"
            @has-error="hasBankDetailsErrors = $event"
            :bank-details="schoolBankDetails"
            :show-errors="showFormErrors"
        />
      </div>


      <!-- Our Notes -->
      <div class="flex justify-center mt-16">
        <p class="text-base text-dark-blue invisible">Status: 
          <span class="text-purple">Requested</span> 
        </p>
        <h1 class="text-2xl text-dark-blue text-center font-bold">Our Notes</h1>
        <div class="flex justify-end gap-x-4">
          <Button buttonText="Approved" class="px-10 py-1.5 bg-green text-white rounded-md shadow-md invisible" @click="filterSchools('Approved')"/>
          <Button buttonText="Decline" class="px-10 py-1.5 bg-orange-red text-white rounded-md shadow-md invisible" @click="filterSchools('Rejected')"/>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-3 mx-auto w-3/5">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
              <div class="sm:col-span-full">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Extra Information</label>
                <div class="mt-2">
                  <textarea v-model="schoolDetails.notes" rows="4" type="text" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" required/>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="flex justify-center gap-x-16 mt-10">
        <Button 
          buttonText="Back" 
          @click="goBackFromSchoolDetails" 
          class="w-72 py-1.5 border border-dark-blue rounded-md shadow-md"
        />
        <Button 
          buttonText="Save Changes" 
          @click="updateSchoolDetails" 
          class="w-72 py-1.5 bg-green text-white rounded-md shadow-md"
        />
      </div>
    </div>

    <!-- Approve Campaign Modal -->
    <CampaignApproveModal 
      v-if="showApproveModal" 
      :name="selectedSchool ? selectedSchool.name : ''"
      @toggle-approve-modal="showApproveModal = false" 
      @approve-campaign="approveSchool"
    /> 

    <!-- Decline Campaign Modal -->
    <CampaignDeclineModal 
      v-if="showDeclineModal" 
      :name="selectedSchool ? selectedSchool.name : ''"
      @toggle-decline-modal="showDeclineModal = false" 
      @decline-campaign="declineSchool"
    />

    <!-- Not Found Image -->
    <NotFound notFoundText="School Does Not Exist" v-if="!showSchoolDetails && filteredSchools.length === 0"/>

    <!-- Pagination -->
    <PaginationV2
      v-if="!showSchoolDetails && totalPages && searchDto.totalEntities"
      @change="handlePageChange"
      :search-dto="searchDto"/>
  </div>
</template>

<script setup lang="ts">
import AdminSidebar from '@/components/Layouts/AdminSidebar.vue'
import Toast from '@/helpers/Toast'
import Button from '@/components/Atomic/AQuarks/Button.vue'
import NotFound from '@/components/Atomic/BAtoms/NotFound.vue'
import {computed, onBeforeUnmount, onMounted, PropType, ref, watch} from 'vue';
import {useSchoolStore} from '@/areas/schools/stores/schoolStore'
import {SchoolDto} from '@/areas/schools/dtos/schoolDto'
import {SchoolStateEnum} from '@/areas/schools/enums/schoolStateEnum'
import {SchoolTypeEnum} from '@/areas/schools/enums/schoolTypeEnum'
import {BankAccountTypeEnum} from '@/areas/schools/enums/bankAccountTypeEnum'
import {UserDto} from '@/areas/users/dtos/userDto'
import bus from '@/bus'
import {BankDetailDto} from "@/areas/schools/dtos/bankDetailDto";
import {AddressDto} from "@/areas/addresses/dtos/addressDto";
import {ProvincesEnum} from "@/areas/schools/enums/provincesEnum";
import {RequestorRoleEnum} from "@/areas/schools/enums/requestorRoleEnum";
import Dropdown from "@/components/Atomic/AQuarks/Dropdown.vue";
import BankDetailsForm from "@/areas/schools/containers/BankDetailsForm.vue";
import {getEntityStateColour, getStatusClass} from "@/components/Atomic/BAtoms/schools/SchoolStateHelpers"
import SchoolStateFilterButtons from "@/components/Atomic/BAtoms/schools/SchoolStateFilterButtons.vue";
import {SearchDto} from "@/areas/helpers/searchDto";
import PaginationV2 from "@/components/Atomic/AQuarks/PaginationV2.vue";
import CampaignApproveModal from "@/components/Atomic/BAtoms/campaigns/modals/CampaignApproveModal.vue";
import CampaignDeclineModal from "@/components/Atomic/BAtoms/campaigns/modals/CampaignDeclineModal.vue";
import {UserContactDto} from "@/areas/users/dtos/userContactDto";
import {ContactTypeEnum} from "@/areas/schools/enums/contactTypeEnum";

const schools = ref<SchoolDto[]>()
const schoolStore = useSchoolStore()
const states = ref<SchoolStateEnum[]>();
const userDto = ref<UserDto | null>(null);

const props = defineProps({
  schoolDetails: {
    type: Object as PropType<SchoolDto>,
    default: {
      guid: '',
      name: '',
      emis: '',
      contactPersonName: '',
      contactPersonDetails: '',
      schoolType: '',
      entityState: undefined,
      bankDetail: undefined,
      address: {
        street: '',
        suburb: '',
        city: '',
        province: '',
        postalCode: '',
      } as AddressDto,
      notes: ''
    }
  },
  schoolBankDetails: {
    type: Object as PropType<BankDetailDto>,
    default: {
      name: '',
      branchCode: '',
      accountNumber: '',
      bankAccountType: BankAccountTypeEnum.Cheque
    }
  },
  searchDto: {
    type: Object as PropType<SearchDto>,
    default: {
      pageSize: 7,
      pageNumber: 1,
    }
  },
})

const schoolDetails = ref(props.schoolDetails)
const schoolBankDetails = ref(props.schoolBankDetails)
const searchDto = ref(props.searchDto)
const requestorNumber = ref<string>("")

// Dropdown options
const provinceOptions = Object.values(ProvincesEnum)
const schoolTypeOptions = Object.values(SchoolTypeEnum)
const requestorTypeOptions = Object.values(RequestorRoleEnum)
const schoolStateOptions = ref<SchoolStateEnum[]>([SchoolStateEnum.Approved, SchoolStateEnum.Rejected, SchoolStateEnum.Requested])

const hasBankDetailsErrors = ref<boolean>(false)
const hasSchoolErrors = ref<boolean>(false)
const showFormErrors = ref<boolean>(false)
const isReadyForApproval = ref<boolean>(false)

let searchTerm = ref<string>('')
let currentFilter = ref<string>('')
let showApproveModal = ref<boolean>(false)
let showDeclineModal = ref<boolean>(false)
let showSchoolDetails = ref<boolean>(false)
let selectedSchool = ref<SchoolDto | null>(null)
let showSearchField = ref<boolean>(true)
let pageTitle = ref<string>('School Management')

let role = ref<string>('')

watch(searchTerm, (newValue) => {
  if (newValue.trim() !== ''){
    
  }
})

const fetchSchoolsByState = async (state: SchoolStateEnum): Promise<SchoolDto[]> => {
  const result = await schoolStore.fetchAllSchools([state], searchTerm.value)
  
  if (result.isSuccessful){
    return result.content as SchoolDto[]
  }
  //TODO: Add error handling
  return []
}

const fetchAllSchools = async (states: SchoolStateEnum[]) => {
  let allSchools: SchoolDto[] = []
  for (let state of states) {
    const schoolsInState = await fetchSchoolsByState(state)
    allSchools = [...allSchools, ...schoolsInState]
  }
  schools.value = allSchools
  
  searchDto.value.totalEntities = allSchools.length
  searchDto.value.totalPages = Math.ceil(allSchools.length / searchDto.value.pageSize) ?? 0
}

const fetchRequestorDetails = async () => {
  if (!selectedSchool.value?.guid){
    // TODO: Handle Error
    return
  }
  const result = await schoolStore.schoolByRequestor(selectedSchool.value!.guid) 
  
  if (result.isSuccessful) {
    schoolDetails.value = result.content!.school
    if (result.content!.school.bankDetail){
      schoolBankDetails.value = result.content!.school.bankDetail!
    } else {
      schoolBankDetails.value = {
        name: '',
        branchCode: '',
        accountNumber: '',
        bankAccountType: BankAccountTypeEnum.Cheque
      }
    }
    userDto.value = result.content!.user as UserDto
    
    if (userDto.value.userContacts && userDto.value.userContacts.length !== 0){
      requestorNumber.value = userDto.value.userContacts.find(x => x.contact.contactTypeEnum === ContactTypeEnum.Mobile || x.contact.contactTypeEnum === ContactTypeEnum.Phone)!.contact.value
    }
    if (result.content!.requestorRoleEnum){
      role.value = result.content!.requestorRoleEnum.toString()
    }
    return
  }
}

watch(() => schoolDetails.value, (newValue) => {
  // School
  const nameError = schoolDetails.value!.name?.trim() === '' ? 'School Name is required' : undefined
  const emisError = schoolDetails.value!.emis?.trim() === '' ? 'School Emis is required' : undefined

  // Contact Person
  const contactPersonError = schoolDetails.value!.contactPersonName?.trim() === '' ? 'Contact Person is required' : undefined
  const contactPersonNumberError = schoolDetails.value!.contactPersonDetails?.trim() === '' ? 'Phone Number is required' : undefined

  // Address
  const streetError = schoolDetails.value!.address.street?.trim() === '' ? 'School Street Address is required' : undefined
  const townError = schoolDetails.value!.address.city?.trim() === '' ? 'School Town Address is required' : undefined
  const provinceError = schoolDetails.value!.address.province?.trim() === '' ? 'School Province Address is required' : undefined
  const postalCodeError = schoolDetails.value!.address.postalCode?.trim() === '' ? 'School Postal Code is required' : undefined
  
  hasSchoolErrors.value = ((nameError || emisError || contactPersonNumberError || contactPersonError || streetError || townError || provinceError || postalCodeError) ?? false) as boolean
  
  isReadyForApproval.value = !hasBankDetailsErrors.value && !hasSchoolErrors.value;
}, {deep: true, immediate: true})

watch(() => hasBankDetailsErrors.value, (newValue) => {
  isReadyForApproval.value = !newValue && !hasSchoolErrors.value;
}, {deep: true, immediate: true})

const updateSchoolDetails = async () => {
  if (!selectedSchool.value?.guid){
    // TODO: Handle error
    return
  } 

  const updatedSchoolDto = schoolDetails.value
  
  if (schoolBankDetails.value.bankAccountType){
    updatedSchoolDto!.bankDetail = schoolBankDetails.value
  }

  const updateSchoolDetailsResult = await schoolStore.updateSchoolDetails(selectedSchool.value.guid!, updatedSchoolDto);
    if (updateSchoolDetailsResult.isSuccessful) 
    {
      Toast.success("School changes successfully saved")
      location.reload()
    }
}

const closeAllKebabMenus = () => {
  const openMenus = schools.value!.filter(school => school.showKebabMenu)
  openMenus.forEach(school => school.showKebabMenu = false)
}

const handleClickOutside = (event: MouseEvent) => {
  // Cast the target of the event to an Element to access DOM properties
  const targetElement = event.target as Element;

  if(targetElement.id !== 'kebab'){
    closeAllKebabMenus();
  }
};
const toggleKebabMenu = (school: SchoolDto) => {
  closeAllKebabMenus()
  school.showKebabMenu = true
}

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
})

onMounted(async () => {
  document.addEventListener('click', handleClickOutside);
  states.value = Object.values(SchoolStateEnum).filter(state => state !== "Loaded")
  if (states){
    await fetchAllSchools(states.value)
  }
  
  userDto.value = {
    firstName: '',
    lastName: '',
    email: '',
  } as UserDto

  bus.on('toggle-approve-modal', toggleApproveModal)
  bus.on('approve-campaign', approveSchool)
  bus.on('toggle-decline-modal', toggleDeclineModal)
  bus.on('decline-campaign', declineSchool)
})

const filteredSchools = computed(() => {
  let filtered : SchoolDto[] = [];
  if(schools.value){
    filtered = schools.value
    if (currentFilter.value) {
      filtered = filtered.filter(school => school.entityState === currentFilter.value);
    }
    if (searchTerm.value) {
      filtered = filtered.filter(school => school!.name!.toLowerCase().includes(searchTerm.value.toLowerCase()));
      searchDto.value.pageNumber = 1;
    }
  }
  
  if (searchDto.value){
    searchDto.value.totalPages = Math.ceil(filtered.length / searchDto.value!.pageSize)
  }
  
  return filtered;
});

const approveSchool = async () => {
  // Save changes
  await updateSchoolDetails()
  
  if (!selectedSchool.value?.guid){
    // TODO: Handle error
    return
  }
  
  let approveSchoolResult = await schoolStore.updateState(selectedSchool.value!.guid, SchoolStateEnum.Approved)
  
  if (approveSchoolResult.isSuccessful) 
  {
    Toast.success("School successfully approved")
  }
  
  toggleApproveModal()
  location.reload()
}

const declineSchool = async () => {
  if (!selectedSchool.value?.guid){
    // TODO: Handle error
    return
  }
  
  let declineSchoolResult = await schoolStore.updateState(selectedSchool.value!.guid!, SchoolStateEnum.Rejected)
  if (declineSchoolResult.isSuccessful) 
  {
    Toast.success("School successfully declined")
  }
  toggleDeclineModal()
  location.reload()
}

// Pagination
watch(() => currentFilter.value, (newValue) => {
  if (searchDto.value){
    searchDto.value!.pageNumber = 1
  }
})

const totalPages = computed(() => {
  if (schools.value && schools.value?.length > 0){
    return filteredSchools.value ? Math.ceil(filteredSchools.value!.length / searchDto.value!.pageSize) : 0
  }
})

const paginatedSchools = computed(() => {
  let start = (searchDto.value.pageNumber - 1) * searchDto.value.pageSize
  let end = start + searchDto.value.pageSize
  return filteredSchools.value.slice(start, end)
})

const handlePageChange = async (newPage: number) => {
  if (searchDto.value?.totalPages && newPage <= searchDto.value.totalPages && newPage > 0){
    searchDto.value!.pageNumber = newPage
  }
}

const filterSchools = (status: string) => {
  currentFilter.value = status
  searchDto.value.pageNumber = 1
};

const toggleApproveModal = () => {
  showApproveModal.value = !showApproveModal.value
}

const toggleDeclineModal = () => {
  showDeclineModal.value = !showDeclineModal.value
}

const showSchoolDetailsPage = (school: SchoolDto) => {
  pageTitle.value = 'School Details'
  showSchoolDetails.value = true;
  showSearchField.value = false;
  selectedSchool.value = school;

  fetchRequestorDetails()
}

const goBackFromSchoolDetails = () => {
  pageTitle.value = 'School Management'
  showSchoolDetails.value = false;
  showSearchField.value = true;

  closeAllKebabMenus()
}
</script>