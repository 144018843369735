<template>
  <div>
    <!-- Sidebar -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col border-r border-gray-900/10 font-roboto">
      <div class="flex grow flex-col gap-y-4 overflow-y-auto px-6">
        <div class="flex h-16 shrink-0 items-center">
          <a href="/landing">
            <img class="h-8 w-auto" src="/img/GetGo_Logo_Small.svg" alt="GetGo Logo">
          </a>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul>
            <li v-for="(item, index) in filteredNavigation" :key="index" class="mb-3 group relative">
              <hr v-if="item.name === 'My Profile'" class="bg-gray-500 w-full my-3">
              <a :href="item.href"
                 class="flex items-center py-1.5 rounded hover:bg-[#DBEAFE]"
                 :class="{'bg-hover-blue': isActive(item.href)}"
                 @click="item.name === 'Log Out' ? logout() : null"
              >
                <img :src="`/img/Icons/${item.icon}.svg`" class="h-5 mr-2" alt="Icon" />
                <span class="text-dark-blue font-bold">{{ item.name }}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-72">
      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <div class="flex flex-1 gap-x-4 justify-end lg:gap-x-6">
          <div class="relative flex flex-1 justify-center">
            <h1 class="text-2xl font-bold text-dark-blue">{{ pageTitle }}</h1>
          </div>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"></div>
            <div class="relative">
              <button type="button" class="-m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="hidden lg:flex lg:items-center">
                  <div class="mt-1 ml-1">
                  <p class="text-dark-blue font-bold font-roboto text-sm">
                    {{ userInfo ? userInfo.given_name : ''}}
                    {{ userInfo ? userInfo.family_name: ''}}
                  </p>
                  <p v-if="userRoles.user" class="text-gray-500 font-roboto text-xs text-left mt-1">User</p>
                </div>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import keycloak from '@/keycloak'

export default {
  props: {
    pageTitle: String,
  },

  setup() {
    const userInfo = ref<any>();
    const router = useRouter();

    const userRoles = computed(() => {
      const tokenParsed = keycloak.tokenParsed;
      if (tokenParsed && tokenParsed.realm_access && tokenParsed.realm_access.roles) {
        return {
          user: tokenParsed.realm_access.roles.includes("User")
        };
      } else {
        return {
          user: false,
        };
      }
    });

    const navigation = ref([
      {name: 'Donate Now', href: '/donate', current: false, icon: 'Donate_Now'},
      {name: 'Register My School', href: '/register/school', current: false, icon: 'Register_My_School'},
      {name: 'Create Campaign', href: '/add-campaign', current: false, icon: 'Add_Campaign'},
      {name: 'About Us', href: '/about', current: false, icon: 'About_Us'},
      {name: 'How It Works', href: '/how-it-works', current: false, icon: 'How_It_Works'},
      {name: 'Contact Us', href: '/contact-us', current: false, icon: 'Contact_Us'},
      {name: "FAQs", href: '/faq', current: false, icon: 'FAQ'},
      {name: 'Home', href: '/landing', current: false, icon: 'Home'},
      {name: 'My Profile', href: '/profile', current: false, icon: 'My_Profile' },
      {name: 'My Donations', href: '/my-donations', current: false, icon: 'My_Donations' },
      {name: 'Log Out', href: '#', current: false, icon: 'Log_Out' }
    ]);

    const filteredNavigation = computed(() => {
      return navigation.value.map(item => {
        return item;
      });
    });

    const isActive = (itemHref: string) => {
      return router.currentRoute.value.path === itemHref;
    };

    onMounted(async () => {
      if (keycloak.authenticated) {
        userInfo.value = await keycloak.loadUserInfo();
      }
    })

    return { 
      userRoles,
      userInfo,
      filteredNavigation,
      isActive
    }
  },

  methods: {
    logout(this: { $keycloak: any }) {
      this.$keycloak.logout({ redirectUri: window.location.origin + '/landing' });
    }
  }
}
</script>