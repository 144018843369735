<template>
  <div v-if="isDateModalOpen" class="fixed inset-0 z-50 flex items-center justify-center p-4">
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <div class="relative mx-auto z-10 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-2/5 sm:p-6">
      <h1 class="text-dark-blue text-2xl font-roboto text-center mb-3">
        Please Select Date
      </h1>
      <p class="text-dark-blue text-base text-center">
        Please select a date range to extract Balance
      </p>

      <div class="flex justify-between mt-4">
        <div v-show="reportRequestDto.reportType !== ReportType.Balances">
          <label class="text-dark-blue">From Date*</label>
          <input v-model="reportRequestDto.from" type="date" class="border border-gray-300 rounded-lg py-2 pl-2 pr-2 outline-none shadow-sm w-full mt-1.5">
        </div>
        <div>
          <label class="text-dark-blue">To Date*</label>
          <input v-model="reportRequestDto.to" type="date" class="border border-gray-300 rounded-lg py-2 pl-2 pr-2 outline-none shadow-sm w-full mt-1.5">
        </div>
      </div>

      <div class="flex justify-between mt-6">
        <button @click="$emit('close')" class="flex items-center px-5 py-2 rounded-md shadow-md bg-transparent text-dark-blue border border-dark-blue">
          Cancel
        </button>
        <button @click="$emit('download')" :class="reportStore.isLoading ? 'disabled bg-gray-500' : 'bg-light-blue'" class="flex items-center px-5 py-2 rounded-md shadow-md text-white">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineProps, PropType, ref} from 'vue';
import {ReportRequestDto} from "@/areas/reports/dtos/reportRequestDto";
import {useReportStore} from "@/areas/reports/stores/reportStore";
import {ReportType} from "@/areas/reports/enums/reportType";

const props = defineProps({
  isDateModalOpen: Boolean,
  reportRequestDto: {
    type: Object as PropType<ReportRequestDto>,
    required: true,
  },
})

const emits = defineEmits(['close', 'download'])

const reportRequestDto = ref(props.reportRequestDto)

const reportStore = useReportStore();

</script>
