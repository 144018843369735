<template>
  <AdminSidebar :showSearchField="showSearchField" :pageTitle="pageTitle"/>

  <div class="font-roboto lg:pl-72">
    <!-- Users Table -->
    <div v-if="!selectedUser" class="px-4 sm:px-6 lg:px-8 mt-6 mx-8 border border-gray-900/5 rounded-md shadow-sm"> 
      <div class="flex justify-between gap-x-4 mt-8">
        <TableSearch
          :style-class="'flex justify-start'"
          :label="'Search'"
          :placeholder="'Search User...'"
          v-model="searchTerm"
        />
        <UserRoleFilterButtons
            @update="currentFilter = $event"
            :style-class="'flex justify-end gap-x-4'"
            :type-options="userRoleOptions"
            :model-value="currentFilter"/>
      </div>
      <div class="-mx-4 mt-8 sm:-mx-0">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-dark-blue sm:pl-0 flex items-center">
                Name
              </th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark-blue lg:table-cell">Email</th>
              <th scope="col" class="pr-6 py-3.5 text-left text-sm font-semibold text-dark-blue lg:table-cell">Role</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(user, index) in paginatedUsers" :key="index">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-dark-blue sm:pl-0">
                {{ user.firstName }} {{ user.lastName }}
              </td>
              <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {{ user.email }}
              </td>
              <td class="whitespace-nowrap py-4 text-sm text-gray-500 relative">
                {{ user.roles[0] }}
              </td>
              <td class="whitespace-nowrap py-4 text-sm font-medium text-right">
                <div class="relative inline-block">
                  <img src="/img/Icons/kebab_menu.svg" id='kebab' alt="Menu" class="cursor-pointer" @click="toggleDropdown(index)">
                  <div v-if="dropdownVisible === index" class="absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white">
                    <a @click="viewDetailsPage(user)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left">View Details</a>
                    <!--a href="#" @click.prevent="toggleDeleteModal(user)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left">Delete</a-->
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Add/Edit User Page -->
    <UserDetailsForm v-if="selectedUser"
        @back="handleBack()"
        @role-updated="(user) => handleRoleUpdate(user)"
        :user="selectedUser"
        :schools="schools"/>

    <!-- Delete Modal -->
    <div v-if="isDeleteModalVisible" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div class="sm:flex sm:justify-center">
              <div class="mt-3 sm:mt-0">
                <h3 class="text-base font-medium leading-6 text-dark-blue">Sure you want to delete?</h3>
                <div class="mt-3 mb-4">
                  <p class="text-sm text-[#989B9E]">Are you sure you want to delete {{ userToDelete?.firstName }} {{ userToDelete?.lastName }}?</p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:justify-center gap-x-4">
              <Button buttonText="No, Cancel" @click="toggleDeleteModal" class="text-dark-blue border border-dark-blue py-2.5 px-4 bg-transparent rounded shadow-md"/>
              <Button buttonText="Yes, Delete" @click="handleDeleteUser" class="bg-red text-white bg-orange-red py-2.5 px-4 rounded shadow-md"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Not Found Image -->
    <NotFound notFoundText="User Does Not Exist" v-if="filteredUsers && filteredUsers.length === 0"/>

    <!-- Pagination -->
    <PaginationV2
      v-if="!selectedUser && totalPages && userSearchDto.totalUsers"
      @change="handlePageChange"
      :search-dto="userSearchDto"/>
  </div>
</template>

<script lang="ts">
import AdminSidebar from '@/components/Layouts/AdminSidebar.vue'
import Pagination from '@/components/Atomic/AQuarks/Pagination.vue'
import Button from '@/components/Atomic/AQuarks/Button.vue'
import UsersDeleteModal from '@/components/Atomic/BAtoms/UsersDeleteModal.vue'
import NotFound from '@/components/Atomic/BAtoms/NotFound.vue'
import {computed, onBeforeUnmount, onMounted, ref, Ref, watch} from 'vue'
import {useUserStore} from '@/areas/users/stores/userStore'
import {UserDto} from '@/areas/users/dtos/userDto'
import Toast from '@/helpers/Toast'
import {UserSearchDto} from '@/areas/users/dtos/userSearchDto'
import UserDetailsForm from '@/components/Atomic/BAtoms/UserDetailsForm.vue'
import {useSchoolStore} from '@/areas/schools/stores/schoolStore'
import {SchoolStateEnum} from "@/areas/schools/enums/schoolStateEnum";
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";
import PaginationV2 from "@/components/Atomic/AQuarks/PaginationV2.vue";
import TableSearch from "@/components/Atomic/AQuarks/TableSearch.vue";
import {RoleEnum} from "@/areas/users/enums/roleEnum";
import UserRoleFilterButtons from "@/components/Atomic/BAtoms/users/UserRoleFilterButtons.vue";

export default {
    name: 'ManageUsers',
    components: {
      UserRoleFilterButtons,
      TableSearch,
      PaginationV2,
      UserDetailsForm,
      AdminSidebar,
      Pagination,
      Button,
      UsersDeleteModal,
      NotFound
    },   

    setup() {
      const userStore = useUserStore()
      const schoolStore = useSchoolStore()
      
      const userSearchDto = ref<UserSearchDto>()
      
      const users = ref<UserDto[]>()
      const selectedUser = ref<UserDto>()
      const userToDelete: Ref<UserDto | null> = ref(null);
      const dropdownVisible = ref<boolean>(false)
      const userRoleDropdown = ref<boolean>(false)
      const isDeleteModalVisible = ref<boolean>(false);
      const schools = ref<SchoolDto[]>()
      const searchTerm = ref<string>('');
      
      const userRoleOptions = Object.values(RoleEnum)

      let isAddingUser = ref<boolean>(false);
      let currentFilter = ref<string>('')
      let showSearchField = ref<boolean>(true)
      let pageTitle = ref<string>('User Management')

      watch(() => currentFilter.value, (newValue) => {
        if (userSearchDto.value){
          userSearchDto.value!.pageNumber = 1
        }
      })
      const handlePageChange = async (newPage: number) => {
        if (newPage <= totalPages.value! && newPage > 0){
          userSearchDto.value!.pageNumber = newPage
        }
      }
      
      const handleBack = () => {
        selectedUser.value = undefined
      }
      
      const handleRoleUpdate = (userDto: UserDto) => {
        let user = users.value!.find(user => user.email === userDto.email)
        
        user!.roles![0] = userDto.roles![0]
        user!.school = userDto.school
        
        selectedUser.value = undefined
      }
      
      const toggleDropdown = (index: any) => {
        dropdownVisible.value = dropdownVisible.value === index ? null : index;
      }
      
      const toggleUserRoleDropdown = () => {
        userRoleDropdown.value = !userRoleDropdown.value;
      }

      const toggleDeleteModal = (user: UserDto) => {
        userToDelete.value = user;
        isDeleteModalVisible.value = !isDeleteModalVisible.value;
        dropdownVisible.value = false
      }
      
      const handleDeleteUser = () => {
        isDeleteModalVisible.value = false;

        Toast.success('User Successfully Removed')
      }
      
      const fetchApprovedSchools = async () => {
        const response = await schoolStore.searchSchoolWithStates([SchoolStateEnum.Approved], '')
        
        if (response.isSuccessful){
          return response.content! as SchoolDto[]
        }
      }

      const viewDetailsPage = (user: UserDto) => {
        selectedUser.value = user
        pageTitle.value = 'View User Details'
        dropdownVisible.value = false
        showSearchField.value = false
        isAddingUser.value = false
      }

      const goBackFromUserDetails = () => {
        pageTitle.value = 'User Management'
        showSearchField.value = true
        dropdownVisible.value = false
      }

      const fetchUsers = async () => {
        if (!userSearchDto.value){
          return;
        }

        let usersFetchResult = await userStore.fetchUsers(userSearchDto.value!);
        
        if (!usersFetchResult.isSuccessful) {
          Toast.error("Something went wrong, please try again")
          return;
        }

        userSearchDto.value = usersFetchResult.content!;
        users.value = usersFetchResult.content!.users as UserDto[];
      };
      
      const paginatedUsers = computed(() => {
        if (userSearchDto.value && userSearchDto.value?.pageNumber && userSearchDto.value?.pageSize) {
          let start = (userSearchDto.value!.pageNumber - 1) * userSearchDto.value!.pageSize
          let end = start + userSearchDto.value!.pageSize

          return filteredUsers.value.slice(start, end)
        }
      })
      
      const totalPages = computed(() => {
        if (users.value && users.value?.length > 0){
          return filteredUsers.value ? Math.ceil(filteredUsers.value!.length / userSearchDto.value!.pageSize) : 0
        }
      })
      
      const filteredUsers = computed(() => {
        let filtered : UserDto[] = [];

        if (users.value){
          filtered = users.value

          if (currentFilter.value){
            filtered = filtered.filter(user => user.roles![0] === currentFilter.value)
          }
          if (searchTerm.value){
            let lowerSearch = searchTerm.value.toLowerCase()
            filtered = filtered.filter(user => user.firstName.toLowerCase().includes(lowerSearch) ||
                                                user.lastName.toLowerCase().includes(lowerSearch) ||
                                                user.email.toLowerCase().includes(lowerSearch))
            userSearchDto.value!.pageNumber = 1;
          }
        }
        
        if(userSearchDto.value){
          userSearchDto.value.totalPages = Math.ceil(filtered.length / userSearchDto.value!.pageSize)
        }
        
        return filtered;
      })
      
      const handleClickOutside = (event: MouseEvent) => {
        // Cast the target of the event to an Element to access DOM properties
        const targetElement = event.target as Element;

        if(targetElement.id !== 'kebab'){
          toggleDropdown(null)
        }
      };
      
      onBeforeUnmount(() => {
        document.removeEventListener('click', handleClickOutside);
      })

      onMounted(async () => {
        document.addEventListener('click', handleClickOutside)
        userSearchDto.value = {
          pageNumber: 1,
          pageSize: 10,
          sortBy: "Name",
          sortAscending: true
        } as UserSearchDto
        
        if (!users.value || users.value!.length === 0){
          await fetchUsers()
        }
        if (!schools.value){
          schools.value = await fetchApprovedSchools()
        }
      })

      return {
        users,
        searchTerm,
        currentFilter,
        showSearchField,
        pageTitle,
        filteredUsers,
        dropdownVisible,
        userRoleDropdown,
        isDeleteModalVisible,
        isAddingUser,
        paginatedUsers,
        selectedUser,
        schools,
        userSearchDto,
        userToDelete,
        userRoleOptions,
        totalPages,

        fetchUsers,
        fetchApprovedSchools,
        toggleDropdown,
        viewDetailsPage,
        goBackFromUserDetails,
        toggleUserRoleDropdown,
        toggleDeleteModal,
        handleDeleteUser,
        handleBack,
        handleRoleUpdate,
        handlePageChange,
      }
    },
  }
</script>
