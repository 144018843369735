import Toast from "@/helpers/Toast";

export interface OperationResult<TContent> {
    content?: TContent,

    isSuccessful: boolean,

    error?: string
}

export class Operations {
    
    static ToSuccessResponse <TContent> (content: TContent): OperationResult<TContent> {
        return {
            content: content,
            isSuccessful: true
        }
    }

    static ToErrorResponse <TContent> (error: string): OperationResult<TContent> {
        return {
            isSuccessful: false,
            error: error
        }
    }
    
    static ToastNotification(operationResult: OperationResult<any>){
        if (operationResult.isSuccessful){
            Toast.success("Success!")
            return
        }
        
        if (!operationResult.isSuccessful){
            const error = operationResult.error as ErrorType
            
            this.HandleError(error)
        }
        
    }
    
    private static HandleError(error: ErrorType){
        Toast.error(getErrorMessage(error))
    }
}

export enum ErrorType {
    UserNotFound = "UserNotFound",
    DuplicateTaxGenerationRequest = "DuplicateTaxGenerationRequest"
}

function getErrorMessage(errorType: ErrorType): string {
    switch(errorType){
        case ErrorType.UserNotFound:
            return "This profile doesn't exist or could not be found.";
        case ErrorType.DuplicateTaxGenerationRequest:
            return "Notification already sent for specified financial year"
        default:
            return "An unknown error occurred."
    }
}