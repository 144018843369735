<template>
  <Sidebar />
  <div class="font-roboto flex flex-col min-h-screen">
    <div class="max-w-7xl w-full mx-auto flex-1">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 pb-10 md:grid-cols-3">
        <div class="px-4">
          <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Contact Us</h2>
          <div class="flex items-start mt-6">
            <img src="/img/Icons/home_contact_us.svg" alt="">
            <p class="ml-3 text-dark-blue">
              GetGo Fund NPC
              <br>
              9 Ntemi Piliso street, 
              <br>
              Ferreiras Dorp, 
              <br>
              Johannesburg, 
              <br>
              2107
            </p>
          </div>
          <div class="flex items-center mt-3">
            <img src="/img/Icons/phone_contact_us.svg" alt="">
            <p class="ml-3 text-dark-blue">+27 (0)21 007 5786</p>
          </div>
          <div class="flex items-center mt-3">
            <img src="/img/Icons/email_contact_us.svg" alt="">
            <a class="ml-3 text-dark-blue" href="mailto:support@getgofund.org">support@getgofund.org</a>
          </div>
        </div>
        <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2 mx-3">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">First Name*</label>
                <div class="mt-2">
                  <input required v-model="firstName" type="text" placeholder="First Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" />
                  <span v-if="firstNameError" class="text-red-500 text-xs mt-1.5">Please enter your first name</span>
                </div>
              </div>

              <div class="sm:col-span-3">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Last Name*</label>
                <div class="mt-2">
                  <input required v-model="lastName" type="text" placeholder="Last Name" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" />
                  <span v-if="lastNameError" class="text-red-500 text-xs mt-1.5">Please enter your last name</span>
                </div>
              </div>

              <div class="sm:col-span-full sm:col-start-1">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Email*</label>
                <div class="mt-2">
                  <input required v-model="email" type="text" placeholder="Email" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" />
                  <span v-if="emailError" class="text-red-500 text-xs mt-1.5">Please enter email</span>
                  <span v-if="invalidEmailError" class="text-red-500 text-xs mt-1.5">Please fill in a valid Email</span>
                </div>
              </div>

              <div class="sm:col-span-full sm:col-start-1">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Phone Number*</label>
                <div class="mt-2">
                  <input required v-model="phoneNumber" type="text" placeholder="Phone Number" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none" />
                  <span v-if="phoneNumberError" class="text-red-500 text-xs mt-1.5">Please enter a phone number</span>
                  <span v-if="invalidPhoneNumberError" class="text-red-500 text-xs mt-1.5">Please fill in a valid South African phone number</span>
                </div>
              </div>

              <div class="col-span-full">
                <label class="block text-sm font-medium leading-6 text-dark-blue">Message*</label>
                <div class="mt-2">
                  <textarea required v-model="message" rows="4" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 outline-none" />
                  <span v-if="messageError" class="text-red-500 text-xs mt-1.5">Please enter a message</span>
                  <span v-if="messageLengthError" class="text-red-500 text-xs mt-1.5">Message must be between 10 and 250 characters</span>
                </div>
              </div>

                <div class="col-span-full sm:w-96 sm:mx-auto mt-0 sm:mt-4">
                  <Button 
                    @click="handleSendMessage"
                    buttonText="Send Message" 
                    class="bg-dark-blue text-white w-full py-2.5 shadow-lg" 
                  />
                </div>
              </div>
            </div>
          </form>
        </div> 
      </div>  
    <Footer />
  </div>
</template>

<script lang="ts">
import Footer from '@/components/Atomic/AQuarks/Footer.vue'
import Sidebar from '@/components/Layouts/Sidebar.vue'
import Button from '@/components/Atomic/AQuarks/Button.vue'
import { useUserStore } from '@/areas/users/stores/userStore'
import { ref } from 'vue'
import Toast from '@/helpers/Toast'

export default {
  name: 'ContactUs',
  components: {
    Footer,
    Sidebar,
    Button,
  },

  setup() {
    const userStore = useUserStore()

    const firstName = ref<string>('')
    const lastName = ref<string>('')
    const email = ref<string>('')
    const phoneNumber = ref<string>('')
    const message = ref<string>('')

    const firstNameError = ref(false)
    const lastNameError = ref(false)
    const emailError = ref(false)
    const invalidEmailError = ref(false)
    const phoneNumberError = ref(false)
    const invalidPhoneNumberError = ref(false)
    const messageError = ref(false)
    const messageLengthError = ref(false)

    const phoneRegex = /^(\+27|0)[0-9]{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    const clearForm = () => {
      firstName.value = ''
      lastName.value = ''
      email.value = ''
      phoneNumber.value = ''
      message.value = ''
    }

    const handleSendMessage = async () => {
      firstNameError.value = false
      lastNameError.value = false
      emailError.value = false
      invalidEmailError.value = false
      phoneNumberError.value = false
      invalidPhoneNumberError.value = false
      messageError.value = false
      messageLengthError.value = false

      if (!firstName.value) firstNameError.value = true
      if (!lastName.value) lastNameError.value = true

      if (!phoneNumber.value) {
        phoneNumberError.value = true
      } else if (!phoneRegex.test(phoneNumber.value)) {
        invalidPhoneNumberError.value = true
      }

      if (!email.value) {
        emailError.value = true
      } else if (!emailRegex.test(email.value)) {
        invalidEmailError.value = true
      }

      if (!message.value) {
        messageError.value = true
      } else if (message.value.length < 10 || message.value.length > 250) {
        messageLengthError.value = true
      }

      if (firstNameError.value || lastNameError.value || emailError.value || phoneNumberError.value || invalidPhoneNumberError.value || invalidEmailError.value || messageError.value || messageLengthError.value) {
        return; 
      }

      let sendMessageResult = await userStore.customerContact(
        firstName.value,
        lastName.value,
        email.value,
        phoneNumber.value,
        message.value
      );

      if (sendMessageResult.isSuccessful) {
        Toast.success("Message Successfully Sent")
        clearForm();
        window.scrollTo(0, 0);
      } else {
        Toast.error("Message Failed To Send")
        clearForm();
        window.scrollTo(0, 0);
      }
    }

    return {
      firstName,
      lastName,
      email,
      phoneNumber,
      message,

      firstNameError,
      lastNameError,
      emailError,
      invalidEmailError,
      phoneNumberError,
      invalidPhoneNumberError,
      messageError,
      messageLengthError,

      handleSendMessage,
    }
  },
}
</script>
