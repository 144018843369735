import {defineStore} from 'pinia';
import {OperationResult, ErrorType, Operations} from '@/helpers/responses/models/operationResult';
import { UserTaxDetailDto } from '../dtos/userTaxDetailDto';
import {TaxCertificateProxy} from "@/areas/taxCertificates/proxies/taxCertificateProxy";
import {TaxYearLogDto} from "@/areas/taxCertificates/dtos/taxYearLogDto";
import {UserTaxCertificateDto} from "@/areas/taxCertificates/dtos/userTaxCertificateDto";

export interface TaxCertificateStoreState{
    updated_at: Date;
    proxy: TaxCertificateProxy;
    taxYear: number;
    userTaxDetail: UserTaxDetailDto | null;
    taxNotifications: TaxYearLogDto[] | null;
    myCertificates: UserTaxCertificateDto[] | null;
    errorStack: ErrorType[] | null;
    isLoading: boolean;
}

export const useTaxCertificateStore = defineStore({
    id: "taxCertificateStore",
    state: (): TaxCertificateStoreState => ({
        updated_at: new Date(),
        proxy: new TaxCertificateProxy(),
        taxYear: new Date().getFullYear()+1 as number,
        userTaxDetail: null as UserTaxDetailDto | null,
        taxNotifications: null as TaxYearLogDto[] | null,
        myCertificates: null as UserTaxCertificateDto[] | null,
        errorStack: null as ErrorType[] | null,
        isLoading: false,
    }),
    actions: {
        async init(taxYear?: number){
            this.errorStack = []

            // Get User Tax Details
            const result = await this.fetchTaxInformation()
            
            if (!result.isSuccessful){
                this.errorStack.push(result.error as ErrorType)
            }
            
            this.userTaxDetail = result.content!
            
            // Get All Certificate Notifications
            const notificationResult = await this.getTaxNotificationHistory()
            
            if (!notificationResult.isSuccessful){
                this.errorStack.push(notificationResult.error as ErrorType)
            }
            
            this.taxNotifications = notificationResult.content!
            
            // Get User Tax Certificates
            const certificateResult = await this.listMyCertificates()
            
            if (!certificateResult.isSuccessful){
                this.errorStack.push(certificateResult.error as ErrorType)
            }
            
            this.myCertificates = certificateResult.content!
        },
        async setTaxYear(year: string){
            this.taxYear = Number(year)
        },
        async getCertificate(year?: number){
            if (!this.myCertificates || this.myCertificates.length === 0){
                return
            }
            
            if (!year && this.taxYear){
                // get for year of store
                return this.myCertificates!.find(certificate => certificate.financialYear === this.taxYear)
            }
            
            return this.myCertificates!.find(certificate => certificate.financialYear === year)
        },
        async getNotification(year: number){
            if (!this.taxNotifications || this.taxNotifications!.length === 0){
                return
            }
            
            return this.taxNotifications!.find(notification => notification.year === year)
        },
        async upsertTaxDetail(userTaxDetailDto: UserTaxDetailDto): Promise<OperationResult<any>> {
            return await this.loadingWrapper(this.proxy.upsertTaxDetail(userTaxDetailDto));
        },

        async fetchTaxInformation(): Promise<OperationResult<UserTaxDetailDto>> {
            return await this.loadingWrapper(this.proxy.fetchTaxDetails());
        },
        
        async createTaxLog(taxYearLog: TaxYearLogDto): Promise<OperationResult<any>> {
            const result = await this.loadingWrapper(this.proxy.createTaxLog(taxYearLog))
            
            Operations.ToastNotification(result)
            
            return result;
        },
        
        async listMyCertificates(): Promise<OperationResult<UserTaxCertificateDto[]>> {
            return await this.loadingWrapper(this.proxy.getMyTaxCertificates())
        },
        
        async getTaxNotificationHistory(): Promise<OperationResult<TaxYearLogDto[]>> {
            return await this.loadingWrapper(this.proxy.getTaxNotificationHistory())
        },
        
        async getTaxCertificate(year: number): Promise<OperationResult<UserTaxCertificateDto[]>> {
            return await this.loadingWrapper(this.proxy.getTaxCertificate(year))
        },
            
        async loadingWrapper<T>(func: Promise<OperationResult<T>>): Promise<OperationResult<T>> {
            this.isLoading = true
            const result = await func
            this.isLoading = false
            return result
        },
    }
})
