export enum ProvincesEnum{
    FreeState = "Free State",
    EasternCape = "Eastern Cape",
    Gauteng = "Gauteng",
    Limpopo = "Limpopo",
    Mpumalanga = "Mpumalanga",
    NorthernCape = "Northern Cape",
    WesternCape = "Western Cape",
    NorthWest = "North West",
    KwaZuluNatal = "KwaZulu-Natal"
}