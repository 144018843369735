<template>
  <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-3 mx-auto w-3/5">
    <div class="px-4 py-6 sm:p-8">
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
        <InputField
            class="sm:col-span-3"
            label="Bank Name*"
            placeholder="Bank Name"
            :error-message="showErrors ? bankDetailsFormFields.errors.value['name'] : undefined"
            v-model="bankDetailsForm.name"
            required
        />
        <InputField
            class="sm:col-span-3"
            label="Branch Code*"
            placeholder="Branch Code"
            :error-message="showErrors ? bankDetailsFormFields.errors.value['branchCode'] : undefined"
            v-model="bankDetailsForm.branchCode"
            required
        />
        <Dropdown
            class="sm:col-span-3"
            label="Account Type*"
            placeholder="Select Account Type"
            :error-message="showErrors ? bankDetailsFormFields.errors.value['bankAccountType'] : undefined"
            :options="bankAccountTypeOptions"
            v-model="bankDetailsForm.bankAccountType"/>
        <InputField
            class="sm:col-span-3"
            label="Account Number*"
            placeholder="Account Number"
            :error-message="showErrors ? bankDetailsFormFields.errors.value['accountNumber'] : undefined"
            v-model="bankDetailsForm.accountNumber"
            required
        />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import {useForm} from 'vee-validate';
import {PropType, reactive, watch} from 'vue';
import * as yup from 'yup';

import InputField from "@/components/Atomic/AQuarks/InputField.vue";
import Dropdown from "@/components/Atomic/AQuarks/Dropdown.vue";
import {BankDetailDto} from "@/areas/schools/dtos/bankDetailDto";
import {BankAccountTypeEnum} from "@/areas/schools/enums/bankAccountTypeEnum";

const props = defineProps({
  bankDetails: {
    type: Object as PropType<BankDetailDto>,
    required: true,
  },
  showErrors: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['update', 'has-error'])

const bankAccountTypeOptions = Object.values(BankAccountTypeEnum)

const bankDetailsForm = reactive(props.bankDetails)

watch(() => props.bankDetails, (newValue) => {
  bankDetailsForm.name = newValue?.name  ?? ''
  bankDetailsForm.branchCode = newValue?.branchCode  ?? ''
  bankDetailsForm.bankAccountType = newValue?.bankAccountType ?? BankAccountTypeEnum.Cheque
  bankDetailsForm.accountNumber = newValue?.accountNumber  ?? ''
}, { deep: true, immediate: true })

// Form validation
const validationSchema = yup.object({
  name: yup.string().required('Bank Name is required'),
  branchCode: yup.string().required('Branch Code is required'),
  bankAccountType: yup.mixed().oneOf(bankAccountTypeOptions).required('Bank Account Type is required'),
  accountNumber: yup.string().required('Bank Account Number is required')
})

const bankDetailsFormFields = useForm({
  validationSchema: validationSchema,
  initialValues:bankDetailsForm 
})

watch(() => bankDetailsForm, async (newVal) => {
  // Reset the form with new values
  bankDetailsFormFields.resetForm({
    values: newVal,
    errors: {}, // Optionally reset errors
    touched: {} // Optionally reset touched fields
  });

  emit('update', bankDetailsForm)
  const validationResult = await bankDetailsFormFields.validate();
  emit('has-error', !validationResult.valid)
  
}, { immediate: true, deep: true });
</script>