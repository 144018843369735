import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import keycloak from './keycloak'
import {createPinia} from 'pinia'
import '@/assets/styles/index.css'
import {useToast} from "vue-toast-notification"
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

keycloak.init({
    onLoad: 'check-sso',
    checkLoginIframe: false,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
}).then(authenticated => {
    // If the user is authenticated, proceed to load the app
    const app = createApp(App);
    app.config.globalProperties.$keycloak = keycloak;
    app.use(router);
    app.use(createPinia());
    app.use(VueTippy, {
        directive: 'tippy',
        component: 'tippy',
        defaultProps: {
            placement: 'top-end',
            allowHTML: true,
            theme: 'material',
        },
    });
    app.provide("$toast", useToast);
    app.mount('#app');
}).catch((err) => {
    console.error('Keycloak initialization failed', err);
});