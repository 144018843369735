import {defineStore} from 'pinia';
import { OperationResult } from '@/helpers/responses/models/operationResult';
import {SessionProxy} from "@/areas/sessions/proxies/sessionProxy";
import {CreateSessionDto} from "@/areas/sessions/dtos/createSessionDto";
import {SessionDto} from "@/areas/sessions/dtos/sessionDto";

export const useSessionStore = defineStore({
    id: "session",
    state: () => ({
        updated_at: new Date(),
        proxy: new SessionProxy(),
    }),

    actions: {
        async createSession(createSessionDto: CreateSessionDto): Promise<OperationResult<SessionDto>> {
            return await this.proxy.createSession(createSessionDto);
        },
    }
})