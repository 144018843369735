<template>
  <div>
    <div class="flex justify-center items-center mt-14">
      <img  src="/img/not_found.svg" alt="Not Found">
    </div>
    <div class="text-center">
      <p class="text-dark-blue text-2xl mt-10">{{ notFoundText }}</p>
    </div>
  </div>
    
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    notFoundText: {
      type: String
    }
  }
}
</script>