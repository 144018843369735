
// Date & DateTime
export function formatDate(dateString?: Date): string {
  const date = dateString ? new Date(dateString) : new Date()
    return date.toLocaleDateString('en-ZA', { day: '2-digit', year: 'numeric', month: 'long' })
}

export function formatTime(timestamp? : string): string {
  const date = timestamp ? new Date(timestamp) : new Date()
  return date.toLocaleTimeString(['en-ZA'], { hour: '2-digit', minute: '2-digit' });
};

// Currency Amounts
export function formatAmount(amount: number): string {
  // Format the number with at least two decimal places
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  // Replace commas with spaces and ensure two decimal places
  return formattedNumber.replace(/,/g, ' ');
}

// Phone Number
export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.startsWith("0") && phoneNumber.length === 10) {
    return phoneNumber.replace("0", "+27");
  } else if (phoneNumber.startsWith("+27") && phoneNumber.length === 12) {
    return phoneNumber
  }
  return phoneNumber
}