<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center p-4">
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <div class="relative mx-auto z-10 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-min:2/5 sm:p-6">
      <h1 class="text-dark-blue text-2xl font-roboto text-center mb-3">
        Please select file
      </h1>

      <div class="flex content-center justify-between mt-4">
        <div>
          <label class="text-dark-blue">Choose Balances file for upload</label>
          <div>
            <input type="file" accept="application/csv" @change="handleChange" class="border border-gray-300 rounded-lg py-2 pl-2 pr-2 outline-none shadow-sm w-auto mt-1.5">
          </div>
          <!--button @click="$emit('select')" class="flex items-center px-5 py-2 rounded-md shadow-md bg-transparent text-dark-blue border border-dark-blue">
            {{ 'Select File' }}
          </button-->
        </div>
      </div>

      <div class="flex justify-between mt-6">
        <button @click="$emit('close')" class="flex items-center px-5 py-2 rounded-md shadow-md bg-transparent text-dark-blue border border-dark-blue">
          Back
        </button>
        <button @click="handleUpload" :class=" !reportStore.file || reportStore.isLoading ? 'disabled bg-gray-500': 'bg-light-blue'" class="flex items-center px-5 py-2 rounded-md shadow-md text-white">
          Upload
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useReportStore} from "@/areas/reports/stores/reportStore";
import Toast from "@/helpers/Toast";

const emit = defineEmits(['upload', 'close']);

const reportStore = useReportStore();

const handleChange = (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0];
  if (file) {
    reportStore.setFile(file)
  }
};

const handleUpload = async () => {
  const result = await reportStore.uploadBalanceReport();
  
  if (result.isSuccessful){
    Toast.success("File upload successful")
  } else {
    Toast.error("File upload failed - try again later")
  }
  
  emit('close')
}

</script>
