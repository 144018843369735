<template>
  <div v-if="showPopup" class="flex min-h-full flex-col justify-center pt-4 pb-8 mx-3 mt-10 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-2xl sm:text-3xl font-bold leading-9 tracking-tight text-dark-blue">{{popupTitle}}</h2>
      <p class="text-center text-dark-blue text-sm mt-2">{{ popupShortMessage }}</p>
    </div>
    <div class="sm:mx-auto sm:w-full">
      <div class="px-4 py-8 sm:px-12">
        <div v-if="showSuccessImage" class="flex justify-center">
          <img src="/img/campaign_created_success.svg" alt="Success Image">
        </div>
        <div class="mt-6 sm:max-w-2xl mx-auto">
          <p class="text-sm sm:text-base text-dark-blue text-center" v-html="popupLongMessage"></p>
        </div>
        <div class="mt-8 flex justify-center">
          <a href="/landing" class="py-2.5 px-28 rounded-lg shadow-lg bg-light-blue text-white">Continue</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    showPopup: Boolean,
    showSuccessImage: Boolean,
    popupTitle: String,
    popupShortMessage: String,
    popupLongMessage: String,
  }
})
</script>
