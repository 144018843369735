<template>
  <div v-if="isTaxCertificateModalOpen" class="fixed inset-0 z-50 flex items-center justify-center p-4">
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <div class="relative mx-auto z-10 rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-2/5 sm:p-6">
      <h1 class="text-dark-blue text-2xl font-roboto text-center mb-3">
        Please Select Financial / Tax Year End
      </h1>
      <p class="text-dark-blue text-base text-center">
        If you continue, the Section 18A tax certificate, for the selected year will be available and donors will be notified accordingly.
      </p>

      <div class="mt-2 w-full relative">
        <Dropdown
            @update:model-value="taxYear = $event"
            :placeholder="'Select Option'"
            :model-value="taxYear"
            :options="years"/>
      </div>

      <div class="flex justify-between mt-6">
        <button @click="$emit('close')" class="flex items-center px-5 py-2 rounded-md shadow-md bg-transparent text-dark-blue border border-dark-blue">
          Cancel
        </button>
        <button @click="$emit('continue', taxYear)" :class="!taxYear ? 'disabled bg-gray-500' : 'bg-light-blue'" class="flex items-center px-5 py-2 rounded-md shadow-md text-white">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {taxYears} from "@/helpers/utilities/utility";
import Dropdown from "@/components/Atomic/AQuarks/Dropdown.vue";

const props = defineProps({
  isTaxCertificateModalOpen: Boolean,
  taxYear: String,
})

const emits = defineEmits(['close', 'continue'])

const years = computed(() => {
  return taxYears(2022).map<string>(taxYear => taxYear.toString())
})

</script>
