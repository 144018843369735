<template>
  <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2">
    <div class="px-4 py-6 sm:p-8">
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
        <Dropdown
            class="sm:col-span-3 h-full"
            label="Legal Nature*"
            placeholder="Select Option"
            :error-message="showErrors ? userTaxDetailFields.errors.value['legalNature'] : undefined"
            :options="legalNatureOptions"
            v-model="userTaxDetailForm.legalNature"
            required
        />
        <InputField
            class="sm:col-span-3"
            label="RSA ID Number*"
            placeholder="RSA ID Number"
            :error-message="showErrors ? userTaxDetailFields.errors.value['idNumber'] : undefined"
            v-model="userTaxDetailForm.idNumber"
            required
        />
        <InputField
            class="sm:col-span-3"
            label="Country of Birth*"
            placeholder="Country of Birth"
            :error-message="showErrors ? userTaxDetailFields.errors.value['countryOfBirth'] : undefined"
            v-model="userTaxDetailForm.countryOfBirth"
            required
        />
        <InputField
            v-if="userTaxDetailForm.user.userContacts && userTaxDetailForm.user.userContacts.length > 0"
            class="sm:col-span-3"
            label="Contact Number*"
            placeholder="Contact Number"
            :error-message="showErrors ? userTaxDetailFields.errors.value['user.userContacts[0].contact.value'] : undefined"
            v-model="userTaxDetailForm.user.userContacts[0].contact.value"
            required
        />
        <InputField
            class="sm:col-span-3"
            label="SARS Tax Number*"
            placeholder="SARS Tax Number"
            :error-message="showErrors ? userTaxDetailFields.errors.value['taxNumber'] : undefined"
            v-model="userTaxDetailForm.taxNumber"
            required
        />
        <InputField
            v-if="userTaxDetailForm.user.userAddress"
            class="sm:col-span-full"
            label="Street Address*"
            placeholder="Street Address"
            :error-message="showErrors? userTaxDetailFields.errors.value['user.userAddress.address.street'] : undefined"
            v-model="userTaxDetailForm.user.userAddress.address.street"
            required
        />
        <InputField
            class="sm:col-span-2"
            label="Town*"
            placeholder="Town"
            :error-message="showErrors ? userTaxDetailFields.errors.value['user.userAddress.address.suburb'] : undefined"
            v-model="userTaxDetailForm.user.userAddress.address.suburb"
            required
        />
        <Dropdown
            class="sm:col-span-2 dropdown"
            label="Province*"
            placeholder="Select Option"
            :error-message="showErrors ? userTaxDetailFields.errors.value['user.userAddress.address.province'] : undefined"
            :options="provinceOptions"
            v-model="userTaxDetailForm.user.userAddress.address.province"
            required
        />
        <InputField
            class="sm:col-span-2"
            label="Postal Code*"
            placeholder="Postal Code"
            :error-message="showErrors ? userTaxDetailFields.errors.value['user.userAddress.address.postalCode'] : undefined"
            v-model="userTaxDetailForm.user.userAddress.address.postalCode"
            required
        />
        <slot name="actions"></slot>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import {useForm} from 'vee-validate';
import {onMounted, PropType, reactive, ref, watch} from 'vue';
import * as yup from 'yup';
import {idRegex, phoneRegex, postalCodeRegex} from "@/helpers/utilities/regex";

import InputField from "@/components/Atomic/AQuarks/InputField.vue";
import Dropdown from "@/components/Atomic/AQuarks/Dropdown.vue";
import {UserTaxDetailDto} from "@/areas/taxCertificates/dtos/userTaxDetailDto";
import {LegalNature} from "@/areas/taxCertificates/enums/legalNature";
import {ProvincesEnum} from "@/areas/schools/enums/provincesEnum";
import {UserDto} from "@/areas/users/dtos/userDto";
import {UserAddressDto} from "@/areas/users/dtos/userAddressDto";
import {AddressDto} from "@/areas/addresses/dtos/addressDto";

const props = defineProps({
  userTaxDetails: {
    type: Object as PropType<UserTaxDetailDto>,
    required: true,
    default: {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        userContacts: [{
          contact: {
            value: '',
          }
        }],
        userAddress: {
          address: {
            street: '',
            suburb: '',
            province: '',
            postalCode: '',
          } as AddressDto
        } as UserAddressDto
      } as UserDto,
      taxNumber: '',
      idNumber: '',
      countryOfBirth: '',
      legalNature: LegalNature.Person
    }
  },
  showErrors: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update', 'has-error'])

const provinceOptions = Object.values(ProvincesEnum)
const legalNatureOptions = Object.values(LegalNature)

const userTaxDetailForm = reactive(props.userTaxDetails)

watch(() => props.userTaxDetails, (newValue) => {
  userTaxDetailForm.taxNumber = newValue?.taxNumber ?? ''
  userTaxDetailForm.idNumber = newValue?.idNumber ?? ''
  userTaxDetailForm.countryOfBirth = newValue?.countryOfBirth ?? ''
  userTaxDetailForm.legalNature = newValue?.legalNature ?? LegalNature.Person
  userTaxDetailForm.user = newValue?.user as UserDto
}, { immediate: true , deep: true})

// Form validation
const validationSchema = yup.object({
  legalNature: yup.mixed().oneOf(legalNatureOptions, "Legal Nature is required").required('Legal Nature is required'),
  idNumber: yup.string().required('ID Number is required').matches(idRegex, {message: 'Invalid South African ID number'}),
  countryOfBirth: yup.string().required('Country of Birth is required'),
  taxNumber: yup.string().required('Tax Number is required'),
  user: yup.object({
    userAddress: yup.object({
      address: yup.object({
        street: yup.string().required('Street is required'),
        suburb: yup.string().required('Suburb is required'),
        //city: yup.string().required('City is required'),
        province: yup.mixed().oneOf(provinceOptions, 'Province is required').required('Province is required'),
        postalCode: yup.string().required('Postal Code is required').matches(postalCodeRegex, {message: 'Postal code must be 4 digits'}),
      }),
    }),
    userContacts: yup.array().of(yup.object({
      contact: yup.object({
        value: yup.string().required('Mobile Number is required').matches(phoneRegex, {message: 'Contact Number must be a valid South African phone number'}),
      }),
    })).required('Contact Number is required'),
  }),
});

const userTaxDetailFields = useForm({
  validationSchema: validationSchema,
  initialValues: userTaxDetailForm
})

watch(() => userTaxDetailForm, async (newVal) => {
  // Reset the form with new values
  userTaxDetailFields.resetForm({
    values: newVal,
    errors: {}, // Optionally reset errors
    touched: {} // Optionally reset touched fields
  });

  emit('update', userTaxDetailForm as UserTaxDetailDto)
    // Optionally re-validate the form if immediate feedback is required
  const validationResult = await userTaxDetailFields.validate();
  emit('has-error', !validationResult.valid)
}, { deep: true });
</script>