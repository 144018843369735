<template>
  <nav v-if="searchDto"  class="flex mb-2 mx-8 sm:px-0">
    <div class="-mt-px flex w-0 flex-1">
      <a href="#" @click="goToPage(props.searchDto.pageNumber-1)" class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700">
        Previous
      </a>
    </div>
    <a v-show="startPage > 1" class="inline-flex items-center px-4 pt-4 text-sm font-medium text-dark-blue " aria-current="page">
      {{ '...' }}
    </a>
    <div class="hidden md:-mt-px md:flex">
      <a v-for="pageNumber in displayedPages" :key="pageNumber" @click="goToPage(pageNumber)" :class="{ 'border-b-2 border-dark-blue': props.searchDto.pageNumber === pageNumber }" class="inline-flex items-center px-4 pt-4 text-sm font-medium text-dark-blue cursor-pointer" aria-current="page">
        {{ pageNumber }}
      </a>
    </div>
    <a v-show="searchDto.totalPages && endPage < searchDto.totalPages" class="inline-flex items-center px-4 pt-4 text-sm font-medium text-dark-blue" aria-current="page">
      {{ '...' }}
    </a>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <a href="#" @click="goToPage(props.searchDto.pageNumber+1)" class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-800">
        Next
      </a>
    </div>
  </nav>
</template>

<script setup lang="ts">
import {computed, PropType, ref} from 'vue';
import {UserSearchDto} from "@/areas/users/dtos/userSearchDto";
import {DonationSearchDto} from "@/areas/users/dtos/donationSearchDto";
import {SearchDto} from "@/areas/helpers/searchDto";

const props = defineProps({
  searchDto: {
    type: Object as PropType<SearchDto | UserSearchDto | DonationSearchDto>,
    required: true,
  },
})

const emits = defineEmits(['change', 'update:selected'])

const searchDto = ref(props.searchDto)

const startPage = ref<number>(1)
const endPage = ref<number>(searchDto.value.totalPages ?? 1)

const goToPage = (page: number) => {
  if (!props.searchDto){
    return;
  }
  if (page > 0 && page <= searchDto.value!.totalPages!){
    emits('change', page)
  }
}

const displayedPages = computed(() => {
  if (searchDto.value.totalPages){
    if (searchDto.value.totalPages < 6){
      endPage.value = searchDto.value.totalPages
    } else {
      endPage.value = 5
    }
    
    if (searchDto.value.pageNumber < 3){
      startPage.value = 1
    }
    
    if (searchDto.value.totalPages - searchDto.value.pageNumber < 2){
      endPage.value = searchDto.value.totalPages
    }
    //
    if (searchDto.value.pageNumber >= 3 && searchDto.value.pageNumber+2 <= searchDto.value.totalPages){
      startPage.value = searchDto.value.pageNumber - 2
      endPage.value = searchDto.value.pageNumber + 2
    }
    
    return Array.from({length: endPage.value - startPage.value + 1}, (_, i) => startPage.value + i);
  }
});
</script>
