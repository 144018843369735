import {defineStore} from 'pinia';
import {OperationResult, ErrorType, Operations} from '@/helpers/responses/models/operationResult';
import {UserProxy} from '../proxies/userProxy';
import {UserSearchDto} from '../dtos/userSearchDto';
import {UserDto} from "@/areas/users/dtos/userDto";
import { DonationSearchDto } from '../dtos/donationSearchDto';
import {emailRegex} from "@/helpers/utilities/regex";

export interface UserStoreState{
    updated_at: Date;
    proxy: UserProxy;
    user: UserDto | null;
    isAuthenticated: boolean;
    isLoading: boolean;
}

export const useUserStore = defineStore({
    id: "user",
    state: (): UserStoreState => ({
        updated_at: new Date(),
        user: null as UserDto | null,
        isAuthenticated: false,
        isLoading: false,
        proxy: new UserProxy(),
    }),
    actions: {
        saveState(){
            localStorage.setItem('userStore', JSON.stringify(this.$state.isAuthenticated));
        },
        
        restoreState(){
            const isAuthenticatedState = localStorage.getItem('userStore');
            if (isAuthenticatedState){
                this.$state.isAuthenticated = JSON.parse(isAuthenticatedState)
            }
        },
        
        async customerContact(firstName: string, lastName: string, email: string, phoneNumber: string, message: string): Promise<OperationResult<any>> {
            return await this.proxy.customerContact(firstName, lastName, email, phoneNumber, message);
        },

        async fetchUsers(userSearchDto: UserSearchDto): Promise<OperationResult<UserSearchDto>> {
            return await this.proxy.fetchUsers(userSearchDto);
        },
        
        async getOrCreate(): Promise<OperationResult<UserDto>> {
            const result = await this.loadingWrapper(this.proxy.getOrCreate())
            
            if (result.isSuccessful){
                this.user = result.content!
                this.isAuthenticated = true
            }

            return result
        },

        async getUser(userGuid: string): Promise<OperationResult<UserDto>> {
            const result = await this.loadingWrapper(this.proxy.getUser(userGuid))
            
            if (result.isSuccessful){
                this.user = result.content!
                this.isAuthenticated = true
            }
            
            return result
        },

        async updateUserDetails(userAuthGuid: string, userDto: UserDto): Promise<OperationResult<UserDto>> {
            return await this.proxy.updateUserDetails(userAuthGuid, userDto);
        },
        
        async updateRole(user: UserDto, schoolGuid?: string): Promise<OperationResult<UserDto>> {
            return await this.proxy.updateRole(user, schoolGuid)
        },
        
        async getKeycloakAttribute(email: string): Promise<OperationResult<any>> {
            return await this.proxy.getKeycloakAttribute(email);
        },

        async getMyDonations(donationSearchDto?: DonationSearchDto): Promise<OperationResult<DonationSearchDto>> {
            return await this.loadingWrapper(this.proxy.getMyDonations(donationSearchDto))
        },
        
        async setAuthentication(auth: boolean): Promise<void> {
            this.isAuthenticated = auth
        },
        
        async validateUserForm(user: UserDto): Promise<OperationResult<string>[]> {
            // Email Check
            let results = []
            
            results.push(await this.validateEmail(user.email.trim()))
            results.push(await this.validateName(user.firstName.trim()))
            results.push(await this.validateName(user.lastName.trim()))
            
            return results as OperationResult<string>[]
        },
        
        async validateName(firstName: string): Promise<OperationResult<string>>{
            if (firstName.length < 0){
                return {
                    isSuccessful: false,
                    error: "First Name is required"
                }
            }
            
            return {
                isSuccessful: true,
                content: firstName
            }
        },
        
        async validateEmail(email: string): Promise<OperationResult<string>>{
            let result = emailRegex.test(email)
            
            if (!result){
                return {
                    isSuccessful: false,
                    error: "Not a valid email"
                }
            }
            
            return {
                isSuccessful: true,
                content: email,
            }
        },

        async loadingWrapper<T>(func: Promise<OperationResult<T>>): Promise<OperationResult<T>> {
            this.isLoading = true
            const result = await func
            this.isLoading = false
            return result
        },
    }
})
