<template>
  <Sidebar />
  <div class="min-h-screen flex flex-col">
    <h1 class="text-dark-blue text-3xl font-bold text-center mt-8 mb-5">My Profile</h1>
      <div class="max-w-7xl mx-auto px-4 space-y-10 divide-y divide-gray-900/10 pb-10 font-roboto flex-1 w-full">
        <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div class="px-4 sm:px-0">
            <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Personal Information</h2>
          </div>
          <UserForm
            @update="user = $event"
            @has-error="hasUserError = $event"
            :user="user"
            :show-errors="showFormErrors"
          />
        </div>
        <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div class="px-4 sm:px-0">
            <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Section 18A Tax Certification Information</h2>
            <p class="text-sm text-dark-blue mt-2">
              Please complete the following information required for the Section 18A tax certificate.
            </p>
          </div>
          <UserTaxDetailForm 
              @update="userTaxDetailDto = $event" 
              @has-error="hasTaxError = $event"
              :user-tax-details="userTaxDetailDto"
              :show-errors="showFormErrors">
            <template v-slot:actions>
              <div class="col-span-full sm:w-96 sm:mx-auto mt-0 sm:mt-4">
                <Button
                    type="submit"
                    @click.prevent="handleSubmit"
                    buttonText="Save Changes"
                    class="bg-green text-white py-2.5 w-full rounded-md"
                />
              </div>
            </template>
          </UserTaxDetailForm>
        </div>
      </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, ref, watch} from 'vue';

// Components 
import Sidebar from '@/components/Layouts/Sidebar.vue'
import Footer from '@/components/Atomic/AQuarks/Footer.vue'
import Button from '@/components/Atomic/AQuarks/Button.vue'
import keycloak from '@/keycloak'
// Forms
import UserForm from "@/areas/users/containers/UserForm.vue";
import UserTaxDetailForm from "@/areas/taxCertificates/containers/UserTaxDetailForm.vue";

// Data Types
import {UserDto} from "@/areas/users/dtos/userDto";
import {UserTaxDetailDto} from "@/areas/taxCertificates/dtos/userTaxDetailDto";
import {LegalNature} from "@/areas/taxCertificates/enums/legalNature";
import {ProvincesEnum} from '@/areas/schools/enums/provincesEnum'
import {ContactTypeEnum} from "@/areas/schools/enums/contactTypeEnum";
import {UserContactDto} from "@/areas/users/dtos/userContactDto";
import {UserAddressDto} from "@/areas/users/dtos/userAddressDto";

// Router
import {useRoute, useRouter} from 'vue-router';
const router = useRouter()
const route = useRoute() as unknown as { params: RouteParams }

// Stores
import {useUserStore} from '@/areas/users/stores/userStore'
import {useTaxCertificateStore} from "@/areas/taxCertificates/stores/taxCertificateStore"
import Toast from "@/helpers/Toast";

const userStore = useUserStore()
const taxCertificateStore = useTaxCertificateStore()

// Local variables
const user = ref<UserDto>()
const userTaxDetailDto = ref<UserTaxDetailDto>()

const isLoading = ref<boolean>(true)
const showFormErrors = ref<boolean>(false)
const showDropdownProvince = ref<boolean>(false)
const showDropdownLegalNature = ref<boolean>(false)
const hasTaxError = ref<boolean>(true)
const hasUserError = ref<boolean>(true)

const legalNatureOptions = Object.values(LegalNature)
const provinceOptions = Object.values(ProvincesEnum)

const userAuthGuid = keycloak.tokenParsed?.sub
const idNumberInputRef = ref<HTMLInputElement | null>(null);

interface RouteParams {
  year?: string | null | undefined
}

const closeAllDropdowns = (event: MouseEvent) => {
  const target = event.target as HTMLElement;

  if (!target.closest('.dropdown')) {
    showDropdownProvince.value = false;
    showDropdownLegalNature.value = false;
  }
};

onMounted(async () => {
  user.value = await fetchUser()
  userTaxDetailDto.value = await fetchTaxInformation(user.value)
  
  window.addEventListener('click', closeAllDropdowns);
})

onUnmounted(() => {
  window.removeEventListener('click', closeAllDropdowns);
});

const fetchUser = async () => {
  if (!userAuthGuid){
    return;
  }
  
  const fetchUserResult = await userStore.getUser(userAuthGuid);

  if (fetchUserResult.isSuccessful) {
    // Removing additional contact numbers
    const user = fetchUserResult.content! as UserDto
    
    if (user.userContacts?.length === 0){
      user.userContacts.push({
        contact: {
          contactTypeEnum: ContactTypeEnum.Mobile,
          value: '',
        },
      } as UserContactDto)
    } else {
      user.userContacts = [user.userContacts!.pop()!]
    }
    if (!user.userAddress){
      user.userAddress = { 
        address: {
          street: '',
          suburb: '',
          city: '',
          province: '',
          postalCode: '',
        }
      } as UserAddressDto
    }
    
    return fetchUserResult.content! as UserDto;
  }
}

// Fetch Tax Details
const fetchTaxInformation = async (user?: UserDto) => {
  const fetchTaxInfoResult = await taxCertificateStore.fetchTaxInformation()

  if (fetchTaxInfoResult.isSuccessful && fetchTaxInfoResult.content) {
    if (user){
      fetchTaxInfoResult.content!.user = user
    }
    return fetchTaxInfoResult.content! as UserTaxDetailDto
  }
}

const handleSubmit = async () => {
  if (!showFormErrors.value){
    showFormErrors.value = true
  }
  
  if (hasUserError.value || hasTaxError.value){
    return
  }

  userTaxDetailDto.value!.user!.firstName = user.value!.firstName
  userTaxDetailDto.value!.user!.lastName = user.value!.lastName
  userTaxDetailDto.value!.user!.email = user.value!.email
  // handle result
  const createUpdateTaxDetails = await taxCertificateStore.upsertTaxDetail(userTaxDetailDto.value!)

  if (createUpdateTaxDetails.isSuccessful) {
    Toast.success("Tax information updated successfully.");
    setTimeout(() => {
      if (route.params.year !== null){
        router.push({
          name: "MyDonations",
          params: {
            "year": route.params.year
          }
        });
      } else {
        router.push({
          name: "MyDonations",
        });
      }
    }, 3000);

  } else {
    Toast.error("Failed to update tax information.");
  }
}
</script>